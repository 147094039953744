import React, {useEffect} from "react";
import { Frame } from "./screens/Frame";
import { ChipsoftCaseStudy } from "./screens/ChipsoftCaseStudy";

export default Main= () => {
    useEffect(() => {
        document.body.style.transform = `scale(${window.innerWidth / 1920})`;
        document.body.style.transformOrigin = `top left`;
      });
    return (
        <>
            <Frame />
            <ChipsoftCaseStudy />
        </>
    )
}