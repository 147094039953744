/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsOutlineCheckmark = ({ className }) => {
  return (
    <svg
      className={`icons-outline-checkmark ${className}`}
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M10.83 3.71442C11.0235 3.91767 11.0156 4.23929 10.8123 4.43277L4.9409 10.0219C4.84063 10.1174 4.70576 10.1678 4.56747 10.1615C4.42918 10.1552 4.29945 10.0927 4.20826 9.98858L1.95002 7.40897C1.76518 7.19783 1.78651 6.87682 1.99765 6.69198C2.20879 6.50714 2.5298 6.52847 2.71464 6.73961L4.62394 8.92062L10.1117 3.69672C10.3149 3.50324 10.6365 3.51116 10.83 3.71442Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
