import React from "react";
import { AppleIphonePro } from "../../components/AppleIphonePro";
import { Button } from "../../components/Button";
import { Icons } from "../../components/Icons";
import { Menu } from "../../components/Menu";
import { NavLink } from "../../components/NavLink";
import { Star } from "../../components/Star";
import { VideoProgress } from "../../components/VideoProgress";
import { Chair1 } from "../../icons/Chair1";
import { Chair13 } from "../../icons/Chair13";
import { Chair21 } from "../../icons/Chair21";
import { Chair23 } from "../../icons/Chair23";
import { Chair24 } from "../../icons/Chair24";
import { Chair28 } from "../../icons/Chair28";
import { Chair4 } from "../../icons/Chair4";
import { Icon12 } from "../../icons/Icon12";
import { Icons18 } from "../../icons/Icons18";
import { Icons21 } from "../../icons/Icons21";
import { Icons23 } from "../../icons/Icons23";
import { Icons25 } from "../../icons/Icons25";
import { Icons27 } from "../../icons/Icons27";
import { Icons29 } from "../../icons/Icons29";
import { Icons37 } from "../../icons/Icons37";
import { Icons39 } from "../../icons/Icons39";
import { Icons41 } from "../../icons/Icons41";
import { Icons42 } from "../../icons/Icons42";
import { Icons43 } from "../../icons/Icons43";
import { Icons44 } from "../../icons/Icons44";
import { Icons45 } from "../../icons/Icons45";
import { Star18 } from "../../icons/Star18";
import "./style.css";

export const ChipsoftCaseStudy = () => {
  return (
    <div className="chipsoft-case-study">
      <div className="div-5">
        <div className="overlap">
          <div className="overlap-2">
            <div className="overlap-wrapper">
              <div className="overlap-3">
                <div className="rectangle" />
                <p className="meetings-company">Meetings, Company events — now up and live in&nbsp;&nbsp;minutes.</p>
                <div className="auditorium-sessions">
                  <div className="overlap-4">
                    <img className="base" alt="Base" src="/img1/base-1.png" />
                    <div className="header">
                      <div className="text-wrapper-17">Events</div>
                      <div className="div-6">
                        <div className="text-input-2">
                          <div className="frame-34">
                            <img className="search-2" alt="Search" src="/img1/search-4.svg" />
                            <div className="value-2">Search</div>
                          </div>
                          <img className="tune" alt="Tune" src="/img1/tune-1.png" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-35">
                      <div className="nav-bar">
                        <div className="nav-bar-2">
                          <div className="div-7">
                            <div className="nav-link-3">
                              <div className="text-wrapper-18">Ongoing</div>
                            </div>
                            <div className="nav-link-4">
                              <div className="text-wrapper-19">Upcoming</div>
                            </div>
                            <div className="nav-link-5">
                              <div className="text-wrapper-19">Completed</div>
                            </div>
                            <div className="nav-link-5">
                              <div className="text-wrapper-19">Drafts</div>
                            </div>
                            <div className="nav-link-6">
                              <div className="text-wrapper-19">Assigned to me</div>
                            </div>
                            <div className="nav-link-5">
                              <div className="text-wrapper-19">Favourites</div>
                            </div>
                            <div className="nav-link-7">
                              <div className="text-wrapper-20">All</div>
                            </div>
                          </div>
                          <div className="frame-36">
                            <img className="icons-11" alt="Download" src="/img1/download-3.png" />
                            <div className="text-wrapper-21">Download</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-37">
                        <div className="dropdown-wrapper">
                          <div className="dropdown">
                            <div className="frame-38">
                              <div className="text-wrapper-22">Sort by:</div>
                              <div className="frame-39">
                                <div className="text-wrapper-23">Recently Added</div>
                                <img className="chevron-right" alt="Chevron right" src="/img1/arrow-forward.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container-events">
                          <div className="event">
                            <div className="div-8">
                              <div className="element">
                                <div className="frame-40">
                                  <div className="live">
                                    <div className="text-wrapper-24">LIVE</div>
                                  </div>
                                </div>
                                <div className="holder">
                                  <div className="frame-41">
                                    <div className="frame-34">
                                      <p className="text-wrapper-25">
                                        Vmware CEO reveals big changes amid multi-cloud shift
                                      </p>
                                      <img className="icons-11" alt="Star" src="/img1/star-25.svg" />
                                    </div>
                                    <div className="div-9">
                                      <div className="div-9">
                                        <p className="text-wrapper-26">10.30 PM - 11.30 AM IST</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="speakers-2">
                                    <div className="text-wrapper-27">Speakers:</div>
                                    <div className="frame-42">
                                      <div className="chip-speaker-2">
                                        <div className="frame-43" />
                                        <div className="name-3">Cameron</div>
                                      </div>
                                      <div className="chip-speaker-2">
                                        <div className="frame-44" />
                                        <div className="name-3">Sam</div>
                                      </div>
                                      <div className="chip-speaker-2">
                                        <div className="frame-45" />
                                        <div className="name-3">Cory</div>
                                      </div>
                                      <div className="chip-speaker-3">
                                        <div className="name-3">+3</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="frame-46">
                                <div className="frame-47">
                                  <div className="div-6">
                                    <button className="CTA">
                                      <div className="text-wrapper-28">View Details</div>
                                    </button>
                                    <button className="CTA-2">
                                      <div className="text-wrapper-29">Join Now</div>
                                    </button>
                                  </div>
                                </div>
                                <div className="speakers-3">
                                  <div className="text-wrapper-27">Attending:</div>
                                  <div className="attending">
                                    <div className="avatar-w-photo">
                                      <div className="outside-stroke" />
                                    </div>
                                    <div className="outside-stroke-wrapper">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-2">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-3">
                                      <div className="text-wrapper-30">120</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="event">
                            <div className="div-8">
                              <div className="element">
                                <div className="frame-40">
                                  <div className="frame-40">
                                    <div className="live-2">
                                      <div className="text-wrapper-24">SCHEDULED</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="holder">
                                  <div className="frame-41">
                                    <div className="frame-34">
                                      <p className="text-wrapper-25">
                                        Vmware CEO reveals big changes amid multi-cloud shift
                                      </p>
                                      <img className="icons-11" alt="Star" src="/img1/star-24.svg" />
                                    </div>
                                    <div className="div-9">
                                      <div className="div-9">
                                        <div className="text-wrapper-31">10 Dec 2020,</div>
                                        <p className="text-wrapper-26">10.30 PM - 11.30 AM IST</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="speakers-4">
                                    <div className="text-wrapper-27">Speakers:</div>
                                    <div className="div-10">
                                      <div className="chip-speaker-4">
                                        <div className="frame-48" />
                                        <div className="name-4">Cameron</div>
                                      </div>
                                      <div className="chip-speaker-5">
                                        <div className="frame-49" />
                                        <div className="name-3">Sam</div>
                                      </div>
                                      <div className="chip-speaker-5">
                                        <div className="frame-50" />
                                        <div className="name-3">Cory</div>
                                      </div>
                                      <div className="chip-speaker-6">
                                        <div className="name-3">+3</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="frame-46">
                                <div className="frame-51">
                                  <div className="frame-52">
                                    <div className="calendar-add-on-wrapper">
                                      <img
                                        className="calendar-add-on"
                                        alt="Calendar add on"
                                        src="/img1/calendar-add-on-5.png"
                                      />
                                    </div>
                                    <button className="CTA">
                                      <div className="text-wrapper-28">View Details</div>
                                    </button>
                                    <button className="CTA-2">
                                      <div className="text-wrapper-29">Register</div>
                                    </button>
                                  </div>
                                  <img
                                    className="dots-horizontal"
                                    alt="Dots horizontal"
                                    src="/img1/dots-horizontal-9.svg"
                                  />
                                </div>
                                <div className="speakers-3">
                                  <div className="text-wrapper-27">Attending:</div>
                                  <div className="attending">
                                    <div className="avatar-w-photo-4">
                                      <div className="outside-stroke" />
                                    </div>
                                    <div className="avatar-w-photo-5">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-6">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-3">
                                      <div className="text-wrapper-30">120</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="event">
                            <div className="div-8">
                              <div className="element">
                                <div className="frame-40">
                                  <div className="live">
                                    <div className="text-wrapper-24">LIVE</div>
                                  </div>
                                </div>
                                <div className="holder">
                                  <div className="frame-41">
                                    <div className="div-11">
                                      <div className="frame-38">
                                        <p className="text-wrapper-32">10 Dec 20 - 12 Dec 22</p>
                                        <div className="ellipse-7" />
                                        <p className="text-wrapper-33">10.30 PM - 11.30 AM IST</p>
                                      </div>
                                    </div>
                                    <div className="frame-34">
                                      <p className="text-wrapper-25">
                                        Vmware CEO reveals big changes amid multi-cloud shift
                                      </p>
                                      <img className="icons-11" alt="Star" src="/img1/star-23.svg" />
                                    </div>
                                  </div>
                                  <div className="speakers-2">
                                    <div className="text-wrapper-27">Speakers:</div>
                                    <div className="frame-42">
                                      <div className="chip-speaker-2">
                                        <div className="frame-53" />
                                        <div className="name-3">Cameron</div>
                                      </div>
                                      <div className="chip-speaker-2">
                                        <div className="frame-54" />
                                        <div className="name-3">Sam</div>
                                      </div>
                                      <div className="chip-speaker-2">
                                        <div className="frame-55" />
                                        <div className="name-3">Cory</div>
                                      </div>
                                      <div className="chip-speaker-3">
                                        <div className="name-3">+3</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="frame-56">
                                <div className="frame-47">
                                  <div className="div-6">
                                    <button className="CTA">
                                      <div className="text-wrapper-28">View Details</div>
                                    </button>
                                    <button className="CTA-2">
                                      <div className="text-wrapper-29">Join Now</div>
                                    </button>
                                  </div>
                                  <img
                                    className="dots-horizontal"
                                    alt="Dots horizontal"
                                    src="/img1/dots-horizontal-8.svg"
                                  />
                                </div>
                                <div className="speakers-3">
                                  <div className="text-wrapper-27">Attending:</div>
                                  <div className="attending">
                                    <div className="avatar-w-photo-7">
                                      <div className="outside-stroke" />
                                    </div>
                                    <div className="avatar-w-photo-8">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-8">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-3">
                                      <div className="text-wrapper-30">120</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="event">
                            <div className="div-8">
                              <div className="element">
                                <div className="frame-40">
                                  <div className="live-2">
                                    <div className="text-wrapper-24">SCHEDULED</div>
                                  </div>
                                </div>
                                <div className="holder">
                                  <div className="frame-41">
                                    <div className="frame-34">
                                      <p className="text-wrapper-25">
                                        Vmware CEO reveals big changes amid multi-cloud shift
                                      </p>
                                      <img className="star-2" alt="Star" src="/img1/arrow-forward.png" />
                                    </div>
                                    <div className="div-11">
                                      <div className="frame-38">
                                        <p className="text-wrapper-32">10 Dec 20 - 12 Dec 22</p>
                                        <div className="ellipse-7" />
                                        <p className="text-wrapper-33">10.30 PM - 11.30 AM IST</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="speakers-4">
                                    <div className="text-wrapper-27">Speakers:</div>
                                    <div className="div-10">
                                      <div className="chip-speaker-4">
                                        <div className="frame-57" />
                                        <div className="name-4">Cameron</div>
                                      </div>
                                      <div className="chip-speaker-5">
                                        <div className="frame-58" />
                                        <div className="name-3">Sam</div>
                                      </div>
                                      <div className="chip-speaker-5">
                                        <div className="frame-59" />
                                        <div className="name-3">Cory</div>
                                      </div>
                                      <div className="chip-speaker-6">
                                        <div className="name-3">+3</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="frame-60">
                                <div className="frame-61">
                                  <div className="frame-62">
                                    <div className="calendar-add-on-wrapper">
                                      <img
                                        className="calendar-add-on-2"
                                        alt="Calendar add on"
                                        src="/img1/arrow-forward.png"
                                      />
                                    </div>
                                    <button className="CTA-3">
                                      <div className="text-wrapper-34">Register</div>
                                    </button>
                                  </div>
                                </div>
                                <div className="speakers-3">
                                  <div className="text-wrapper-27">Attending:</div>
                                  <div className="attending">
                                    <div className="avatar-w-photo-7">
                                      <div className="outside-stroke" />
                                    </div>
                                    <div className="avatar-w-photo-8">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-8">
                                      <div className="outside-stroke-2" />
                                    </div>
                                    <div className="avatar-w-photo-3">
                                      <div className="text-wrapper-30">120</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-2">
                    <div className="frame-41">
                      <div className="frame-63">
                        <NavLink
                          className="design-component-instance-node"
                          divClassName="nav-link-8"
                          icon={<Icons45 className="icons-11" />}
                          stateProp="default"
                          type="default"
                        />
                        <div className="nav-link-9">
                          <Icons
                            icon="RC"
                            iconRcClassName="icons-13"
                            union="/img/union-6.svg"
                            unionClassName="icons-14"
                          />
                          <div className="dashboard-2">Resource Center</div>
                        </div>
                        <NavLink
                          className="design-component-instance-node"
                          divClassName="nav-link-8"
                          icon={<Icons44 className="icons-11" />}
                          stateProp="default"
                          text="Learning Center"
                          type="default"
                        />
                        <NavLink
                          className="design-component-instance-node"
                          divClassName="nav-link-8"
                          icon={<Icons43 className="icons-11" />}
                          stateProp="selected"
                          text="Auditorium"
                          type="default"
                        />
                        <div className="nav-link-9">
                          <Icons
                            divClassName="icons-17"
                            divClassNameOverride="icons-18"
                            ellipseClassName="icons-15"
                            ellipseClassNameOverride="icons-16"
                            icon="MT"
                            iconRcClassName="icons-13"
                            overlapGroupClassName="design-component-instance-node-2"
                            unionClassName="icons-19"
                          />
                          <div className="dashboard-2">Meetings</div>
                        </div>
                        <NavLink
                          className="design-component-instance-node"
                          divClassName="nav-link-8"
                          icon={<Icons42 className="icons-11" />}
                          stateProp="default"
                          text="Quizzes"
                          type="default"
                        />
                        <NavLink
                          className="design-component-instance-node"
                          divClassName="nav-link-8"
                          icon={<Icons41 className="icons-11" />}
                          stateProp="default"
                          text="Manage"
                          type="default"
                        />
                      </div>
                    </div>
                    <div className="frame-41">
                      <NavLink
                        className="nav-link-10"
                        divClassName="nav-link-8"
                        icon={<Icons18 className="icons-11" />}
                        stateProp="default"
                        text="Logout"
                        type="default"
                      />
                      <div className="extra-2">
                        <div className="text-wrapper-35">Policies</div>
                        <div className="interpunct-2">
                          <div className="ellipse-8" />
                        </div>
                        <div className="text-wrapper-35">Terms &amp; Conditions</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-64">
                    <div className="frame-65" />
                    <div className="frame-66">
                      <div className="frame-34">
                        <img className="notifications" alt="Notifications" src="/img1/notifications-2.svg" />
                      </div>
                      <div className="div-11">
                        <div className="frame-67">
                          <div className="ellipse-9" />
                        </div>
                        <div className="text-wrapper-36">Santosh</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="speaker">
                  <div className="overlap-5">
                    <div className="overlap-6">
                      <div className="frame-68">
                        <div className="frame-69">
                          <div className="text-wrapper-37">Speaker</div>
                        </div>
                        <div className="speaker-name">
                          <div className="frame-70">
                            <div className="frame-71">
                              <div className="img-wrapper">
                                <img className="ellipse-10" alt="Ellipse" src="/img1/ellipse-123-2.png" />
                              </div>
                              <div className="text-wrapper-38">Adam Joseph</div>
                            </div>
                            <div className="frame-72">
                              <div className="text-wrapper-39">Senior Manager - Sales</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="frame-74">
                          <div className="text-wrapper-40">Speaker</div>
                        </div>
                        <div className="speaker-name-2">
                          <div className="frame-75">
                            <div className="frame-71">
                              <div className="frame-76">
                                <img className="ellipse-11" alt="Ellipse" src="/img1/ellipse-123.png" />
                              </div>
                              <div className="text-wrapper-41">Adam Joseph</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolbar">
                        <div className="keyboard-voice-wrapper">
                          <img className="keyboard-voice" alt="Keyboard voice" src="/img1/keyboard-voice.png" />
                        </div>
                        <div className="frame-77">
                          <div className="frame-78">
                            <img className="img-4" alt="Videocam off" src="/img1/videocam-off.png" />
                          </div>
                        </div>
                        <div className="frame-77">
                          <div className="frame-78">
                            <img className="img-4" alt="Add to queue" src="/img1/add-to-queue.png" />
                          </div>
                        </div>
                        <div className="frame-77">
                          <div className="frame-78">
                            <img className="img-4" alt="Group" src="/img1/group.png" />
                          </div>
                        </div>
                        <div className="frame-77">
                          <div className="frame-78">
                            <div className="overlap-group-4">
                              <img className="chat-bubble" alt="Chat bubble" src="/img1/chat-bubble.png" />
                              <div className="ellipse-12" />
                              <div className="text-wrapper-42">1</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-77">
                          <div className="frame-78">
                            <img className="img-4" alt="Add reaction" src="/img1/add-reaction.png" />
                          </div>
                        </div>
                        <img className="frame-79" alt="Frame" src="/img1/frame-40130.svg" />
                        <img className="frame-79" alt="Frame" src="/img1/frame-40129.svg" />
                        <div className="frame-80">
                          <div className="frame-78">
                            <img className="img-4" alt="Call end" src="/img1/call-end.png" />
                          </div>
                        </div>
                      </div>
                      <div className="arrow-back-ios-new-wrapper">
                        <img
                          className="arrow-back-ios-new"
                          alt="Arrow back ios new"
                          src="/img1/arrow-back-ios-new.png"
                        />
                      </div>
                      <div className="polls">
                        <div className="frame-81">
                          <div className="frame-82">
                            <div className="frame-81">
                              <div className="frame-83">
                                <div className="text-wrapper-43">Polls</div>
                                <div className="frame-78">
                                  <img className="img-4" alt="Close" src="/img1/close-3.png" />
                                </div>
                              </div>
                              <div className="frame-84">
                                <div className="frame-85">
                                  <div className="frame-86">
                                    <div className="text-wrapper-44">Chat</div>
                                  </div>
                                  <div className="frame-87">
                                    <div className="text-wrapper-45">Polls</div>
                                  </div>
                                  <div className="frame-88">
                                    <div className="text-wrapper-46">Questions</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-89">
                            <div className="frame-90">
                              <div className="frame-91">
                                <div className="text-wrapper-47">What’s a poll?</div>
                                <div className="text-wrapper-48">by Adam Joesph</div>
                              </div>
                              <div className="frame-92">
                                <div className="frame-93">
                                  <div className="checkbox-wrapper">
                                    <div className="checkbox">
                                      <div className="fill" />
                                    </div>
                                  </div>
                                  <div className="text-wrapper-49">Option 1</div>
                                </div>
                                <div className="frame-93">
                                  <div className="checkbox-wrapper">
                                    <div className="checkbox">
                                      <div className="fill" />
                                    </div>
                                  </div>
                                  <div className="text-wrapper-49">Option 2</div>
                                </div>
                              </div>
                              <div className="frame-94">
                                <div className="search-bar">
                                  <div className="text-wrapper-50">Skip</div>
                                </div>
                                <div className="search-bar-2">
                                  <div className="text-wrapper-51">Submit</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-95">
                      <div className="frame-74">
                        <div className="text-wrapper-40">Speaker</div>
                      </div>
                      <div className="speaker-name-2">
                        <div className="frame-75">
                          <div className="frame-71">
                            <div className="frame-76">
                              <img className="ellipse-13" alt="Ellipse" src="/img1/ellipse-123-2.png" />
                            </div>
                            <div className="text-wrapper-41">Adam Joseph</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overlap-7">
                      <div className="frame-96">
                        <div className="frame-74">
                          <div className="text-wrapper-40">Speaker</div>
                        </div>
                        <div className="speaker-name-2">
                          <div className="frame-75">
                            <div className="frame-71">
                              <div className="frame-76">
                                <img className="ellipse-11" alt="Ellipse" src="/img1/ellipse-123-1.png" />
                              </div>
                              <div className="text-wrapper-41">Adam Joseph</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="recording">
                        <div className="frame-97">
                          <div className="overlap-group-wrapper">
                            <div className="overlap-group-5">
                              <div className="ellipse-14" />
                            </div>
                          </div>
                          <div className="text-wrapper-52">Recording..</div>
                        </div>
                        <div className="frame-98">
                          <div className="text-wrapper-52">40:00</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-99">
                      <img className="arrow-back-ios-new-2" alt="Arrow back ios new" src="/img1/arrow-forward.png" />
                    </div>
                  </div>
                </div>
                <div className="meeting-tables">
                  <div className="frame-100">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Quick Networking</div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-6">
                        <div className="table">
                          <div className="logo-container-wrapper">
                            <div className="logo-container">
                              <img className="accenture-png" alt="Accenture png" src="/img1/accenture-png-1.png" />
                            </div>
                          </div>
                        </div>
                        <div className="avatar-9" />
                        <div className="avatar-10" />
                        <div className="avatar-11">
                          <div className="text-10">TS</div>
                        </div>
                        <div className="avatar-12" />
                        <div className="avatar-13" />
                        <div className="avatar-14" />
                        <div className="avatar-15" />
                        <div className="avatar-16" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-103">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Weekly Meet</div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-7">
                        <Chair28 className="chair" />
                        <div className="table">
                          <div className="logo-container-wrapper">
                            <div className="logo-container">
                              <img className="accenture-png" alt="Accenture png" src="/img1/accenture-png.png" />
                            </div>
                          </div>
                        </div>
                        <div className="avatar-17" />
                        <div className="avatar-18">
                          <div className="text-10">TS</div>
                        </div>
                        <div className="avatar-19" />
                        <div className="avatar-20" />
                        <div className="avatar-21" />
                        <div className="avatar-22" />
                        <div className="avatar-23" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-104">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Table 3</div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-8">
                        <Chair21 className="chair-21" />
                        <Chair4 className="chair-4" />
                        <Chair23 className="chair-23" />
                        <Chair24 className="chair-24" />
                        <Chair23 className="chair-2" />
                        <Chair24 className="chair-22" />
                        <Chair21 className="chair-27" />
                        <Chair4 className="chair-18" />
                        <img className="table-2" alt="Table" src="/img1/table-3.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-105">
                    <div className="text-wrapper-54">Meeting Tables</div>
                    <div className="text-input-wrapper">
                      <div className="text-input-3">
                        <div className="div-11">
                          <img className="search-3" alt="Search" src="/img1/search-3.svg" />
                          <div className="value-3">Search</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-wrapper-55">
                    Network with your people, 1:1 or join a group discussion. Grab a seat now!
                  </p>
                  <div className="frame-106">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Table 4</div>
                    </div>
                    <div className="frame-107">
                      <div className="overlap-group-9">
                        <Chair13 className="chair-13" />
                        <Chair4 className="chair-26" />
                        <div className="frame-108">
                          <Chair23 className="chair-3" />
                          <Chair23 className="chair-3" />
                        </div>
                        <div className="frame-109">
                          <Chair1 className="chair-3" />
                          <Chair1 className="chair-3" />
                        </div>
                        <img className="table-3" alt="Table" src="/img1/table-2.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-110">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Table 1</div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-10">
                        <Chair13 className="chair-5" />
                        <Chair4 className="chair-20" />
                        <Chair23 className="chair-17" />
                        <Chair1 className="chair-1" />
                        <Chair23 className="chair-7" />
                        <Chair1 className="chair-10" />
                        <Chair23 className="chair-9" />
                        <Chair1 className="chair-6" />
                        <Chair13 className="chair-19" />
                        <Chair4 className="chair-12" />
                        <img className="table-4" alt="Table" src="/img1/table-1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-111">
                    <div className="frame-101">
                      <div className="text-wrapper-53">Table 5</div>
                    </div>
                    <div className="frame-112">
                      <div className="overlap-group-11">
                        <Chair13 className="chair-13-instance" />
                        <Chair23 className="chair-16" />
                        <Chair1 className="chair-8" />
                        <Chair4 className="chair-4-instance" />
                        <img className="table-5" alt="Table" src="/img1/table.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-113">
                    <div className="frame-114" />
                    <div className="frame-115">
                      <div className="frame-116">
                        <img className="img-5" alt="Icon" src="/img1/icon-18.svg" />
                        <img className="img-5" alt="Notifications" src="/img1/notifications-1.svg" />
                      </div>
                      <div className="div-11">
                        <div className="frame-117">
                          <div className="ellipse-9" />
                        </div>
                        <div className="text-wrapper-56">Santosh</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-session">
                  <div className="container-content">
                    <div className="header-create">
                      <div className="text-wrapper-57">Create Event</div>
                    </div>
                    <div className="container">
                      <div className="container-input">
                        <div className="container-session">
                          <div className="text-wrapper-58">Session Type:</div>
                          <div className="container-types">
                            <div className="option-live">
                              <div className="checkbox-base-wrapper">
                                <div className="checkbox-base" />
                              </div>
                              <div className="text-11">Live</div>
                            </div>
                            <div className="option-recorded">
                              <div className="checkbox-base-wrapper">
                                <div className="check-wrapper">
                                  <div className="check" />
                                </div>
                              </div>
                              <div className="text-11">Recorded</div>
                            </div>
                          </div>
                        </div>
                        <div className="input-field-title">
                          <div className="text-wrapper-59">Add Title</div>
                        </div>
                        <div className="input-field-desc">
                          <div className="input-field-text">
                            <p className="text-12">
                              <span className="span">
                                Details about event
                                <br />
                              </span>
                              <span className="text-wrapper-60">
                                <br />
                              </span>
                            </p>
                          </div>
                          <div className="toolbar-text">
                            <div className="div-12">
                              <img className="img-6" alt="Icon" src="/img1/icon-17.svg" />
                              <img className="img-6" alt="Icon" src="/img1/icon-16.svg" />
                              <img className="img-6" alt="Icon" src="/img1/icon-15.svg" />
                              <img className="img-7" alt="Format list bulleted" src="/img1/format-list-bulleted-1.png" />
                              <img className="img-7" alt="Format list numbered" src="/img1/format-list-numbered-1.png" />
                            </div>
                            <div className="label-char-limit">
                              <div className="text-wrapper-61">0/200</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-13">
                          <div className="div-14">
                            <div className="text-wrapper-62">Start</div>
                            <div className="input-field-date">
                              <div className="div-15">
                                <div className="text-wrapper-63">14 April</div>
                                <img className="chevron-down-2" alt="Chevron down" src="/img1/chevron-down-15.svg" />
                              </div>
                              <div className="input-field-time">
                                <div className="text-wrapper-63">09:00 AM</div>
                              </div>
                            </div>
                          </div>
                          <img className="chevron-down-3" alt="Chevron down" src="/img1/chevron-down-14.svg" />
                          <div className="div-14">
                            <div className="text-wrapper-62">End</div>
                            <div className="container-field">
                              <div className="div-15">
                                <div className="text-wrapper-63">14 April</div>
                                <img className="chevron-down-2" alt="Chevron down" src="/img1/chevron-down-13.svg" />
                              </div>
                              <div className="input-field-time">
                                <div className="text-wrapper-63">09:00 AM</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-118">
                          <div className="container-timezone">
                            <div className="text-wrapper-62">Timezone</div>
                            <div className="input-field-timezone">
                              <div className="text-wrapper-64">(UTC+5:30) IST</div>
                              <img className="chevron-down-4" alt="Chevron down" src="/img1/chevron-down-12.svg" />
                            </div>
                          </div>
                          <div className="div-14">
                            <div className="text-wrapper-62">Availaible to:</div>
                            <div className="input-field-timezone">
                              <div className="text-wrapper-64">Select Team or People</div>
                              <img className="chevron-down-4" alt="Chevron down" src="/img1/chevron-down-11.svg" />
                            </div>
                          </div>
                        </div>
                        <div className="div-13">
                          <div className="container-2">
                            <div className="text-wrapper-62">Speakers</div>
                            <div className="container-wrapper">
                              <div className="container-3">
                                <div className="div-12">
                                  <div className="chip-speaker-7">
                                    <div className="frame-119">
                                      <div className="frame-120" />
                                      <div className="name-5">Cameron</div>
                                    </div>
                                    <img className="close" alt="Close" src="/img1/close-2.png" />
                                  </div>
                                  <div className="chip-speaker-7">
                                    <div className="frame-119">
                                      <div className="frame-121" />
                                      <div className="name-5">Silviya</div>
                                    </div>
                                    <img className="close" alt="Close" src="/img1/close-1.png" />
                                  </div>
                                  <div className="chip-speaker-7">
                                    <div className="frame-119">
                                      <div className="frame-122" />
                                      <div className="name-5">Sam</div>
                                    </div>
                                    <img className="close" alt="Close" src="/img1/close.png" />
                                  </div>
                                </div>
                                <div className="text-wrapper-65">+Add Speakers</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container-embed-link">
                          <div className="text-wrapper-62">Embed Video Link (Youtube/Vimeo)</div>
                          <div className="input-field-link">
                            <div className="container-text">
                              <div className="text-wrapper-66">Add Link</div>
                            </div>
                          </div>
                        </div>
                        <div className="container-auditorium">
                          <div className="top">
                            <div className="title">Auditorium Image</div>
                          </div>
                          <div className="content-wrapper">
                            <div className="content">
                              <img className="icon" alt="Icon" src="/img1/icon-14.svg" />
                              <p className="paragraph">
                                Browse and chose the files you want to upload from your computer
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="container-video">
                          <div className="record">
                            <div className="switch">
                              <div className="overlap-group-12">
                                <div className="color-l-track" />
                                <img className="states" alt="States" src="/img1/states.png" />
                                <img className="color-l-thumb" alt="Color l thumb" src="/img1/color-l-thumb.png" />
                              </div>
                            </div>
                            <div className="text-and-supporting">
                              <div className="text-wrapper-67">Record Meeting</div>
                            </div>
                          </div>
                          <div className="frame-123">
                            <div className="checkbox-icon">
                              <div className="icons-outline">
                                <div className="shape-wrapper">
                                  <img className="shape" alt="Shape" src="/img1/shape-1.png" />
                                </div>
                              </div>
                            </div>
                            <div className="label">
                              <div className="text-wrapper-67">Enable Chat</div>
                            </div>
                          </div>
                          <div className="frame-123">
                            <div className="checkbox-icon">
                              <div className="icons-outline-2">
                                <img className="shape-2" alt="Shape" src="/img1/shape.png" />
                              </div>
                            </div>
                            <div className="label">
                              <div className="text-wrapper-67">Enable Poll</div>
                            </div>
                          </div>
                          <div className="frame-123">
                            <div className="checkbox-icon">
                              <div className="overlap-group-13" />
                            </div>
                            <div className="label">
                              <div className="text-wrapper-67">Enable Feedback</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-124">
                      <button className="CTA-4">
                        <div className="text-13">Cancel</div>
                      </button>
                      <button className="CTA-5">
                        <div className="text-14">Save</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="add-table">
                  <div className="frame-125">
                    <div className="frame-126">
                      <div className="div-16">
                        <div className="title-2">
                          <div className="text-wrapper-68">Add Table</div>
                        </div>
                        <img className="div-17" alt="Div" src="/img1/div.svg" />
                      </div>
                      <div className="container-fields">
                        <div className="frame-127">
                          <div className="text-wrapper-69">Table Name</div>
                        </div>
                        <div className="frame-128">
                          <div className="frame-129">
                            <div className="text-wrapper-70">Seats:</div>
                            <div className="frame-130">
                              <div className="text-wrapper-71">2</div>
                              <img className="unfold-more" alt="Unfold more" src="/img1/unfold-more.png" />
                            </div>
                          </div>
                          <div className="frame-131">
                            <div className="frame-132">
                              <div className="text-wrapper-72">Availaible to:</div>
                              <img className="icon-12" alt="Chevron down" src="/img1/chevron-down-10.svg" />
                            </div>
                          </div>
                        </div>
                        <div className="container-time">
                          <div className="div-18">
                            <div className="text-wrapper-73">Start</div>
                            <div className="input-field-date-2">
                              <div className="div-19">
                                <div className="text-wrapper-74">14 April</div>
                                <img className="chevron-down-5" alt="Chevron down" src="/img1/chevron-down-9.svg" />
                              </div>
                              <div className="input-field-time-2">
                                <div className="text-wrapper-74">09:00 AM</div>
                              </div>
                            </div>
                          </div>
                          <img className="chevron-down-6" alt="Chevron down" src="/img1/chevron-down-8.svg" />
                          <div className="div-18">
                            <div className="text-wrapper-73">End</div>
                            <div className="container-field-2">
                              <div className="div-19">
                                <div className="text-wrapper-74">14 April</div>
                                <img className="chevron-down-5" alt="Chevron down" src="/img1/chevron-down-7.svg" />
                              </div>
                              <div className="input-field-time-3">
                                <div className="text-wrapper-75">09:00 AM</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div-16">
                          <div className="title-wrapper">
                            <div className="title-3">Upload logo</div>
                          </div>
                          <div className="container-4">
                            <div className="content-2">
                              <img className="icon-2" alt="Icon" src="/img1/icon-13.svg" />
                              <p className="paragraph-2">
                                Browse and chose the files you want to upload from your computer
                              </p>
                              <div className="icon-wrapper">
                                <Icon12 className="icon-12" color="white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-133">
                    <button className="CTA-6">
                      <div className="text-15">Cancel</div>
                    </button>
                    <button className="CTA-7">
                      <div className="text-16">Save</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img className="pexels-mart" alt="Pexels mart" src="/img1/pexels-mart-production-7605980-1.png" />
            <div className="course-details">
              <div className="row">
                <div className="blog-post-card">
                  <div className="image" />
                  <div className="content-3">
                    <div className="div-20">
                      <div className="subheading-2">Design</div>
                      <div className="div-20">
                        <div className="heading-and-icon">
                          <p className="heading">How collaboration makes us better designers</p>
                          <img className="img-6" alt="Icon wrap" src="/img1/icon-wrap-2.png" />
                        </div>
                        <p className="supporting-text-3">
                          Collaboration can make our teams stronger, and our individual designs better.
                        </p>
                      </div>
                    </div>
                    <div className="avatar-label-group">
                      <div className="avatar-24" />
                      <div className="text-and-supporting">
                        <div className="text-17">Natali Craig</div>
                        <div className="supporting-text-4">14 Jan 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post-card">
                  <div className="image-2" />
                  <div className="content-3">
                    <div className="div-20">
                      <div className="subheading-2">Product</div>
                      <div className="div-20">
                        <div className="heading-and-icon">
                          <p className="heading">Our top 10 Javascript frameworks to use</p>
                          <img className="img-6" alt="Icon wrap" src="/img1/icon-wrap-1.png" />
                        </div>
                        <p className="supporting-text-3">
                          JavaScript frameworks make development easy with extensive features and functionalities.
                        </p>
                      </div>
                    </div>
                    <div className="avatar-label-group">
                      <div className="avatar-25" />
                      <div className="text-and-supporting">
                        <div className="text-17">Drew Cano</div>
                        <div className="supporting-text-4">13 Jan 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post-card">
                  <div className="image-3" />
                  <div className="content-3">
                    <div className="div-20">
                      <div className="subheading-2">Customer Success</div>
                      <div className="div-20">
                        <div className="heading-and-icon">
                          <p className="heading">Podcast: Creating a better CX Community</p>
                          <img className="img-6" alt="Icon wrap" src="/img1/icon-wrap.png" />
                        </div>
                        <p className="supporting-text-3">
                          Starting a community doesn’t need to be complicated, but how do you get started?
                        </p>
                      </div>
                    </div>
                    <div className="avatar-label-group">
                      <div className="avatar-26" />
                      <div className="text-and-supporting">
                        <div className="text-17">Orlando Diggs</div>
                        <div className="supporting-text-4">12 Jan 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-8">
                <div className="frame-134">
                  <p className="text-wrapper-76">Introduction to Responsive Web Development with HTML and CSS</p>
                  <div className="div-21">
                    <div className="avatar-27" />
                    <div className="name-6">Cameron Williamson</div>
                  </div>
                </div>
                <div className="video-player">
                  <div className="video-player-2">
                    <div className="video-progress-wrapper">
                      <VideoProgress
                        backgroundClassName="video-progress-3"
                        bufferingClassName="video-progress-4"
                        bufferingProgressClassName="video-progress-5"
                        className="video-progress-instance"
                        overlapGroupClassName="video-progress-2"
                        pause="/img/pause.svg"
                        playClassName="design-component-instance-node-2"
                        playing
                        progressLineClassName="video-progress-6"
                      />
                    </div>
                    <div className="frame-135">
                      <div className="frame-136">
                        <div className="text-wrapper-77">Take Notes</div>
                      </div>
                      <div className="open-in-full-wrapper">
                        <img className="open-in-full" alt="Open in full" src="/img1/open-in-full.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-137">
                <div className="text-wrapper-78">Recommended for you</div>
                <div className="row-2">
                  <div className="blog-post-card-2">
                    <div className="image-4">
                      <img className="icon-button" alt="Icon button" src="/img1/icon-button-2.png" />
                      <div className="dots-horizontal-wrapper">
                        <div className="star-wrapper">
                          <Star state="default" />
                        </div>
                      </div>
                      <div className="line-wrapper">
                        <img className="line" alt="Line" src="/img1/line-11.png" />
                      </div>
                    </div>
                    <div className="content-4">
                      <div className="div-20">
                        <div className="frame-138">
                          <div className="subheading-3">Design</div>
                          <div className="ellipse-15" />
                          <div className="frame-139">
                            <div className="subheading-4">4.3</div>
                          </div>
                        </div>
                        <div className="div-22">
                          <div className="heading-wrapper">
                            <div className="heading-2">UX review presentations</div>
                          </div>
                          <p className="supporting-text-5">
                            How do you create compelling presentations that wow your colleagues and impress your
                            managers?
                          </p>
                        </div>
                      </div>
                      <div className="avatar-label-group-2">
                        <div className="avatar-28" />
                        <div className="text-and-supporting">
                          <div className="text-wrapper-79">Olivia Rhye</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-3">
                    <div className="frame-140">
                      <div className="overlap-group-14">
                        <img className="icon-button" alt="Icon button" src="/img1/icon-button-1.png" />
                        <div className="icon-button-2">
                          <div className="star-wrapper">
                            <Star state="default" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-4">
                      <div className="div-20">
                        <div className="frame-138">
                          <div className="subheading-3">Product</div>
                          <div className="ellipse-15" />
                          <div className="frame-139">
                            <div className="subheading-4">4.3</div>
                          </div>
                        </div>
                        <div className="div-20">
                          <div className="heading-and-icon">
                            <div className="heading-2">Migrating to Linear 101</div>
                          </div>
                          <p className="text-wrapper-80">
                            Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to
                            get started.
                          </p>
                        </div>
                      </div>
                      <div className="avatar-label-group-2">
                        <div className="avatar-29" />
                        <div className="text-and-supporting">
                          <div className="text-wrapper-79">Phoenix Baker</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-4">
                    <div className="image-5">
                      <img className="icon-button" alt="Icon button" src="/img1/icon-button.png" />
                      <div className="dots-horizontal-wrapper">
                        <div className="star-wrapper">
                          <Star state="default" />
                        </div>
                      </div>
                    </div>
                    <div className="content-4">
                      <div className="div-20">
                        <div className="frame-138">
                          <div className="subheading-3">Software Engineering</div>
                          <div className="ellipse-15" />
                          <div className="frame-139">
                            <div className="subheading-4">4.3</div>
                          </div>
                        </div>
                        <div className="div-20">
                          <div className="heading-and-icon">
                            <div className="heading-2">Building your API Stack</div>
                          </div>
                          <p className="text-wrapper-80">
                            The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing
                            them.
                          </p>
                        </div>
                      </div>
                      <div className="avatar-label-group-2">
                        <div className="avatar-30" />
                        <div className="text-and-supporting">
                          <div className="text-wrapper-79">Lana Steiner</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-9">
                <div className="frame-141">
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="text-wrapper-81">Your Feedback Matters</div>
                      <p className="text-wrapper-80">
                        Rate the course and get the discount to use on your next purchase.
                      </p>
                    </div>
                  </div>
                  <div className="frame-144">
                    <div className="div-21">
                      <div className="text-wrapper-82">Rate this course</div>
                    </div>
                  </div>
                </div>
                <div className="frame-145">
                  <div className="frame-146">
                    <div className="text-wrapper-83">12 Lessons</div>
                    <div className="frame-147">
                      <div className="text-wrapper-84">1h 44m</div>
                    </div>
                  </div>
                  <div className="frame-148">
                    <div className="text-wrapper-85">UNITS</div>
                    <div className="frame-149">
                      <div className="frame-150">
                        <div className="frame-151">
                          <div className="frame-152">
                            <div className="text-wrapper-86">U1</div>
                          </div>
                          <div className="frame-153">
                            <div className="text-wrapper-79">Introduction</div>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-6.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-6.png" />
                      </div>
                      <div className="frame-154">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-88">U2</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-5.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-5.png" />
                      </div>
                      <div className="frame-154">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-88">U3</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-4.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-4.png" />
                      </div>
                      <div className="frame-154">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-88">U4</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-3.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-3.png" />
                      </div>
                      <div className="frame-154">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-88">U5</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-2.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-2.png" />
                      </div>
                      <div className="frame-154">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-88">U6</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle-1.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle-1.png" />
                      </div>
                      <div className="frame-157">
                        <div className="frame-155">
                          <div className="frame-152">
                            <div className="text-wrapper-89">U7</div>
                          </div>
                          <div className="frame-156">
                            <p className="become-happier-by">
                              Become Happier by Learning &amp; Applying Psychological Science
                            </p>
                            <div className="div-21">
                              <img className="play-circle" alt="Play circle" src="/img1/play-circle.png" />
                              <div className="text-wrapper-87">12 min</div>
                            </div>
                          </div>
                        </div>
                        <img className="check-circle" alt="Check circle" src="/img1/check-circle.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-10">
                <div className="frame-158">
                  <div className="div-8">
                    <div className="text-wrapper-90">Course Forum</div>
                    <div className="frame-159">
                      <img className="edit" alt="Edit" src="/img1/edit.png" />
                      <div className="text-wrapper-91">Ask a question</div>
                    </div>
                  </div>
                  <div className="container-5">
                    <img className="thread-line" alt="Thread line" src="/img1/thread-line.png" />
                    <div className="frame-160">
                      <div className="comment">
                        <img className="avatar-31" alt="Avatar" src="/img1/avatar-19.png" />
                        <div className="content-5">
                          <p className="paragraph-3">
                            Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus Lorem
                            ipsum dolor sit amet aliquam, purus sit amet luctus
                          </p>
                          <div className="div-8">
                            <div className="text-wrapper-92">2 mins</div>
                            <div className="settings">
                              <div className="group-2">
                                <div className="ellipse-16" />
                                <div className="ellipse-17" />
                                <div className="ellipse-18" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-2">
                        <div className="label-wrapper">
                          <div className="label-2">View all 124 comments</div>
                        </div>
                        <img className="icons-20" alt="Icons" src="/img1/icons-31.svg" />
                      </div>
                    </div>
                    <div className="div-22">
                      <div className="frame-161">
                        <div className="comment-2">
                          <img className="avatar-31" alt="Avatar" src="/img1/avatar-18.png" />
                          <div className="content-5">
                            <p className="paragraph-3">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                            </p>
                            <div className="div-8">
                              <div className="text-wrapper-92">15 mins</div>
                              <div className="settings">
                                <div className="group-2">
                                  <div className="ellipse-16" />
                                  <div className="ellipse-17" />
                                  <div className="ellipse-18" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-3">
                          <div className="div-10">
                            <div className="label-wrapper">
                              <div className="label-2">View all 124 comments</div>
                            </div>
                            <img className="icons-20" alt="Icons" src="/img1/icons-30.png" />
                          </div>
                        </div>
                      </div>
                      <div className="comment-3">
                        <div className="frame-162">
                          <img className="line-2" alt="Line" src="/img1/line-16.png" />
                        </div>
                        <div className="frame-163">
                          <img className="avatar-31" alt="Avatar" src="/img1/avatar-17.png" />
                          <div className="content-5">
                            <p className="paragraph-3">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                            </p>
                            <div className="div-8">
                              <div className="text-wrapper-92">15 mins</div>
                              <div className="settings">
                                <div className="group-2">
                                  <div className="ellipse-16" />
                                  <div className="ellipse-17" />
                                  <div className="ellipse-18" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="comment-3">
                        <div className="frame-162">
                          <img className="line-2" alt="Line" src="/img1/line-16.png" />
                        </div>
                        <div className="frame-163">
                          <img className="avatar-31" alt="Avatar" src="/img1/avatar-16.png" />
                          <div className="content-5">
                            <p className="paragraph-3">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                            </p>
                            <div className="div-8">
                              <div className="text-wrapper-92">12 mins</div>
                              <div className="settings">
                                <div className="group-2">
                                  <div className="ellipse-16" />
                                  <div className="ellipse-17" />
                                  <div className="ellipse-18" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="comment-3">
                        <div className="frame-162">
                          <img className="line-2" alt="Line" src="/img1/line-16.png" />
                        </div>
                        <div className="frame-163">
                          <img className="avatar-31" alt="Avatar" src="/img1/avatar-15.png" />
                          <div className="content-5">
                            <p className="paragraph-3">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                            </p>
                            <div className="div-8">
                              <div className="text-wrapper-92">12 mins</div>
                              <div className="settings">
                                <div className="group-2">
                                  <div className="ellipse-16" />
                                  <div className="ellipse-17" />
                                  <div className="ellipse-18" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-160">
                      <div className="comment">
                        <img className="avatar-31" alt="Avatar" src="/img1/avatar-14.png" />
                        <div className="content-5">
                          <p className="paragraph-3">
                            Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus Lorem
                            ipsum dolor sit amet aliquam, purus sit amet luctus
                          </p>
                          <div className="div-8">
                            <div className="text-wrapper-92">2 mins</div>
                            <div className="settings">
                              <div className="group-2">
                                <div className="ellipse-16" />
                                <div className="ellipse-17" />
                                <div className="ellipse-18" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="search-bar-3">
                      <div className="text-wrapper-93">Type a Message</div>
                    </div>
                    <button className="CTA-8">
                      <div className="text-wrapper-94">Send</div>
                    </button>
                  </div>
                </div>
                <div className="frame-164">
                  <div className="links-wrapper">
                    <div className="links">
                      <div className="link">
                        <div className="text-wrapper-95">Overview</div>
                      </div>
                      <div className="link">
                        <div className="text-wrapper-96">Content</div>
                      </div>
                      <div className="q-a-wrapper">
                        <div className="q-a">Q&amp;A</div>
                      </div>
                      <div className="link-2">
                        <div className="text-wrapper-95">Notes</div>
                      </div>
                      <div className="link-3">
                        <div className="text-wrapper-97">Certification</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Menu
                chevronDown="/img/chevron-down-5.svg"
                chevronDownClassName="menu-19"
                className="menu-instance"
                dashboardClassName="menu-3"
                dashboardClassNameOverride="menu-3"
                divClassName="menu-16"
                ellipseClassName="menu-18"
                extraClassName="menu-15"
                frameClassName="menu-14"
                iconsDivClassName="menu-11"
                iconsDivClassNameOverride="menu-12"
                iconsEllipseClassName="menu-8"
                iconsEllipseClassNameOverride="menu-9"
                iconsIconRcClassName="menu-6"
                iconsIconRcClassNameOverride="menu-6"
                iconsOverlapGroupClassName="menu-13"
                iconsUnion="/img/union-4.svg"
                iconsUnionClassName="menu-7"
                iconsUnionClassNameOverride="menu-10"
                interpunctClassName="menu-17"
                navLinkClassName="menu-5"
                navLinkClassNameOverride="menu-5"
                navLinkDivClassName="menu-3"
                navLinkDivClassName1="menu-3"
                navLinkDivClassName2="menu-3"
                navLinkDivClassName3="menu-3"
                navLinkDivClassName4="menu-3"
                navLinkDivClassNameOverride="menu-3"
                navLinkIcon={<Icons29 className="check-circle" />}
                navLinkIcon1={<Icons27 className="check-circle" />}
                navLinkIcon2={<Icons42 className="check-circle" />}
                navLinkIcon3={<Icons25 className="check-circle" />}
                navLinkIcon4={<Icons18 className="check-circle" />}
                navLinkStateDefaultTypeClassName="menu-4"
                navLinkStateDefaultTypeClassNameOverride="menu-4"
                navLinkStateHoverTypeClassName="menu-4"
                navLinkStateHoverTypeClassNameOverride="menu-4"
                navLinkStateSelectedTypeClassName="menu-4"
                navLinkStateSelectedTypeClassNameOverride="menu-4"
                override={<Icons44 className="check-circle" />}
                property1="default"
                termsConditionsClassName="menu-16"
              />
              <div className="frame-165">
                <div className="frame-166" />
                <div className="frame-167">
                  <div className="div-21">
                    <div className="frame-168">
                      <div className="ellipse-19" />
                    </div>
                    <div className="text-wrapper-98">Santosh</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-course">
              <div className="frame-169">
                <div className="frame-170">
                  <div className="frame-171">
                    <div className="text-wrapper-99">Create a new Course</div>
                    <img className="line-3" alt="Line" src="/img1/line-3.svg" />
                  </div>
                  <div className="frame-172">
                    <div className="frame-173">
                      <div className="text-wrapper-100">Add title</div>
                    </div>
                    <div className="input-field-desc-2">
                      <div className="input-field-text-2">
                        <p className="text-18">
                          <span className="text-wrapper-101">
                            Add Description
                            <br />
                          </span>
                          <span className="text-wrapper-102">
                            <br />
                          </span>
                        </p>
                      </div>
                      <div className="toolbar-text-2">
                        <div className="container-controls">
                          <img className="img-6" alt="Icon" src="/img1/icon-9.svg" />
                          <img className="img-6" alt="Icon" src="/img1/icon-8.svg" />
                          <img className="img-6" alt="Icon" src="/img1/icon-7.svg" />
                          <img className="img-8" alt="Format list bulleted" src="/img1/format-list-bulleted.png" />
                          <img className="img-8" alt="Format list numbered" src="/img1/format-list-numbered.png" />
                        </div>
                        <div className="label-char-limit-2">
                          <div className="text-wrapper-103">0/600</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-171">
                      <div className="top-2">
                        <div className="title-4">Upload Cover</div>
                      </div>
                      <div className="container-6">
                        <div className="content-6">
                          <img className="icon-3" alt="Icon" src="/img1/icon-6.svg" />
                          <p className="paragraph-4">
                            Browse and chose the files you want to upload from your computer
                          </p>
                          <div className="icon-12-wrapper">
                            <Icon12 className="chevron-down-7" color="white" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-174">
                    <div className="frame-175">
                      <div className="frame-176">
                        <div className="text-wrapper-104">Add Instrutor</div>
                        <img className="chevron-down-7" alt="Chevron down" src="/img1/chevron-down-4.svg" />
                      </div>
                      <div className="frame-177">
                        <div className="text-wrapper-105">Level</div>
                        <img className="chevron-down-8" alt="Chevron down" src="/img1/chevron-down-3.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-178">
                    <div className="frame-179">
                      <div className="text-wrapper-106">Add labels</div>
                    </div>
                    <img className="chevron-down-7" alt="Chevron down" src="/img1/chevron-down-2.svg" />
                  </div>
                </div>
                <div className="frame-180">
                  <div className="add-button">
                    <div className="text-19">Save</div>
                  </div>
                  <button className="add-button-2">
                    <div className="text-20">Cancel</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p className="no-more-switiching">
            No more switiching learning, upskilling, managing — it&nbsp;&nbsp;all happens here
          </p>
        </div>
        <div className="overlap-11">
          <div className="overlap-12">
            <img className="pexels-mart-2" alt="Pexels mart" src="/img1/pexels-mart-production-7222309-1.png" />
            <div className="resources-all">
              <div className="overlap-13">
                <div className="rectangle-2" />
                <img className="line-4" alt="Line" src="/img1/line-4.svg" />
                <div className="frame-181">
                  <div className="frame-182">
                    <div className="frame-183">
                      <div className="text-wrapper-107">File Name</div>
                    </div>
                    <img className="icon-button-3" alt="Icon button" src="/img1/icon-button-3.svg" />
                  </div>
                  <div className="frame-184">
                    <div className="frame-185">
                      <div className="text-wrapper-108">Share</div>
                    </div>
                  </div>
                </div>
                <img className="line-5" alt="Line" src="/img1/line-5.svg" />
                <img className="rectangle-3" alt="Rectangle" src="/img1/rectangle-9.png" />
                <img className="line-6" alt="Line" src="/img1/line-3-2.svg" />
                <div className="frame-186">
                  <div className="frame-187">
                    <div className="div-23">
                      <div className="title-5">About</div>
                      <p className="paragraph-5">
                        Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus Lorem ipsum
                        dolor sit amet aliquam, purus sit amet luctus
                      </p>
                    </div>
                    <div className="frame-188">
                      <div className="frame-189">
                        <div className="text-wrapper-109">4.4/5</div>
                      </div>
                      <div className="frame-190">
                        <div className="frame-191">
                          <div className="text-wrapper-110">Rate this File:</div>
                        </div>
                        <div className="star-3">
                          <div className="star-18-wrapper">
                            <Star18 className="star-18" />
                          </div>
                          <Star18 className="icon-instance-node-2" />
                          <Star18 className="icon-instance-node-2" />
                          <Star18 className="icon-instance-node-2" />
                          <Star18 className="icon-instance-node-2" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-182">
                      <div className="text-wrapper-111">File Type:</div>
                      <div className="div-7">
                        <div className="div-10">
                          <div className="base-status-badge">
                            <div className="text-wrapper-112">PDF</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-192">
                    <div className="frame-193">
                      <div className="div-8">
                        <div className="text-wrapper-113">Assigned to</div>
                        <div className="subtitle-wrapper">
                          <div className="subtitle">39 members</div>
                        </div>
                      </div>
                      <div className="div-23">
                        <div className="frame-194">
                          <div className="frame-195">
                            <div className="div-7">
                              <div className="avatar-w-photo-9">
                                <div className="outside-stroke-3" />
                              </div>
                              <div className="avatar-w-photo-10">
                                <div className="outside-stroke-3" />
                              </div>
                              <div className="avatar-w-photo-11">
                                <div className="outside-stroke-3" />
                              </div>
                              <div className="avatar-w-photo-12">
                                <div className="outside-stroke-3" />
                              </div>
                              <div className="avatar-w-photo-wrapper">
                                <div className="avatar-w-photo-13">
                                  <div className="outside-stroke-3" />
                                </div>
                              </div>
                              <div className="overflow">
                                <div className="number">34</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="comments">
                      <div className="frame-196">
                        <div className="top-3">
                          <div className="title-6">Comments</div>
                        </div>
                        <div className="container-7">
                          <img className="thread-line-2" alt="Thread line" src="/img1/thread-line-1.svg" />
                          <div className="frame-197">
                            <div className="comment-4">
                              <img className="avatar-32" alt="Avatar" src="/img1/avatar-28.png" />
                              <div className="content-7">
                                <p className="paragraph-6">
                                  Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus
                                  Lorem ipsum dolor sit amet aliquam, purus sit amet luctus
                                </p>
                                <div className="div-8">
                                  <div className="text-wrapper-114">2 mins</div>
                                  <div className="group-wrapper">
                                    <div className="group-3">
                                      <div className="ellipse-20" />
                                      <div className="ellipse-21" />
                                      <div className="ellipse-22" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-4">
                              <div className="frame-198">
                                <div className="label-3">View all 124 comments</div>
                              </div>
                              <img className="icons-21" alt="Icons" src="/img1/icons-33.svg" />
                            </div>
                          </div>
                          <div className="frame-197">
                            <div className="comment-4">
                              <img className="avatar-32" alt="Avatar" src="/img1/avatar-27.png" />
                              <div className="content-7">
                                <p className="paragraph-6">
                                  Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                                </p>
                                <div className="div-8">
                                  <div className="text-wrapper-114">15 mins</div>
                                  <div className="group-wrapper">
                                    <div className="group-3">
                                      <div className="ellipse-20" />
                                      <div className="ellipse-21" />
                                      <div className="ellipse-22" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-4">
                              <div className="frame-198">
                                <div className="label-4">View all 124 comments</div>
                              </div>
                              <img className="icons-21" alt="Icons" src="/img1/icons-32.svg" />
                            </div>
                          </div>
                          <div className="comment-5">
                            <img className="avatar-32" alt="Avatar" src="/img1/avatar-26.png" />
                            <div className="content-7">
                              <p className="paragraph-6">
                                Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                              </p>
                              <div className="div-8">
                                <div className="text-wrapper-114">15 mins</div>
                                <div className="group-wrapper">
                                  <div className="group-3">
                                    <div className="ellipse-20" />
                                    <div className="ellipse-21" />
                                    <div className="ellipse-22" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-field-2">
                        <div className="search-bar-4">
                          <div className="text-wrapper-115">Start typing...</div>
                        </div>
                        <Button buttonClassName="button-2" className="button-instance" property1="variant-7" />
                      </div>
                      <div className="slider">
                        <div className="rectangle-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-14">
                <div className="mini-side-bar">
                  <div className="nav">
                    <img className="img-6" alt="Dashboard" src="/img1/dashboard-1.svg" />
                    <div className="dashboard-3">
                      <div className="icon-instance-node-2">
                        <div className="book-wrapper">
                          <img className="book" alt="Book" src="/img1/book.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-3">
                      <div className="icon-instance-node-2">
                        <div className="polygon-wrapper">
                          <img className="polygon" alt="Polygon" src="/img1/polygon-1.svg" />
                        </div>
                      </div>
                    </div>
                    <img className="img-6" alt="Dashboard" src="/img1/dashboard.svg" />
                  </div>
                </div>
                <div className="menu-20">
                  <div className="frame-199">
                    <div className="frame-200">
                      <NavLink
                        className="nav-link-11"
                        divClassName="nav-link-12"
                        icon={<Icons39 className="icon-instance-node-2" />}
                        stateProp="default"
                        text="Dashboard"
                        type="default"
                      />
                      <div className="nav-link-13">
                        <Icons
                          icon="RC"
                          iconRcClassName="icons-22"
                          union="/img/union-5.svg"
                          unionClassName="icons-14"
                        />
                        <div className="dashboard-4">Resource Center</div>
                      </div>
                      <NavLink
                        className="nav-link-11"
                        divClassName="nav-link-12"
                        icon={<Icons44 className="icon-instance-node-2" />}
                        stateProp="default"
                        text="Learning Center"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-11"
                        divClassName="nav-link-12"
                        icon={<Icons37 className="icon-instance-node-2" />}
                        stateProp="default"
                        text="Auditorium"
                        type="default"
                      />
                      <div className="nav-link-14">
                        <Icons
                          divClassName="icons-26"
                          divClassNameOverride="icons-27"
                          ellipseClassName="icons-24"
                          ellipseClassNameOverride="icons-25"
                          icon="MT"
                          iconRcClassName="icons-22"
                          overlapGroupClassName="design-component-instance-node-2"
                          unionClassName="icons-23"
                        />
                        <div className="text-wrapper-113">Meetings</div>
                      </div>
                      <NavLink
                        className="nav-link-11"
                        divClassName="nav-link-12"
                        icon={<Icons42 className="icon-instance-node-2" />}
                        stateProp="default"
                        text="Quizzes"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-11"
                        divClassName="nav-link-12"
                        icon={<Icons25 className="icon-instance-node-2" />}
                        stateProp="default"
                        text="Manage"
                        type="default"
                      />
                    </div>
                  </div>
                  <div className="frame-199">
                    <NavLink
                      className="nav-link-15"
                      divClassName="nav-link-12"
                      icon={<Icons18 className="icon-instance-node-2" />}
                      stateProp="default"
                      text="Logout"
                      type="default"
                    />
                    <div className="extra-3">
                      <div className="text-wrapper-116">Policies</div>
                      <div className="interpunct-3">
                        <div className="ellipse-23" />
                      </div>
                      <div className="text-wrapper-116">Terms &amp; Conditions</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-resource">
              <div className="frame-201">
                <div className="frame-202">
                  <div className="text-wrapper-117">Add Resources</div>
                  <img className="line-7" alt="Line" src="/img1/line-3-1.svg" />
                </div>
                <div className="frame-203">
                  <div className="frame-204">
                    <div className="text-wrapper-118">Title</div>
                  </div>
                  <div className="frame-205">
                    <div className="text-wrapper-118">File Type</div>
                    <img className="chevron-down-9" alt="Chevron down" src="/img1/chevron-down-6.svg" />
                  </div>
                  <div className="frame-202">
                    <div className="top-4">
                      <div className="title-7">Upload File</div>
                    </div>
                    <div className="container-8">
                      <div className="content-8">
                        <img className="icon-4" alt="Icon" src="/img1/icon-11.svg" />
                        <p className="paragraph-7">Browse and chose the files you want to upload from your computer</p>
                        <Button
                          className="button-3"
                          icon12StyleOverrideClassName="chevron-down-9"
                          property1="variant-9"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="frame-206">
                    <div className="text-wrapper-119">Comment</div>
                  </div>
                </div>
              </div>
              <Button buttonClassName="button-5" className="button-4" property1="variant-7" text="Send for approval" />
            </div>
          </div>
          <p className="text-wrapper-120">Company-wide resource management made simpler</p>
        </div>
        <div className="customization">
          <div className="overlap-15">
            <div className="rectangle-5" />
            <img className="rectangle-6" alt="Rectangle" src="/img1/rectangle-16.svg" />
            <img className="rectangle-7" alt="Rectangle" src="/img1/rectangle-19.svg" />
            <img className="rectangle-8" alt="Rectangle" src="/img1/rectangle-18.png" />
            <img className="rectangle-9" alt="Rectangle" src="/img1/rectangle-20.svg" />
            <div className="rectangle-10" />
            <div className="auditorium-sessions-2">
              <div className="overlap-16">
                <img className="base-2" alt="Base" src="/img1/base.png" />
                <div className="header-2">
                  <div className="text-wrapper-121">Events</div>
                  <div className="div-24">
                    <div className="text-input-4">
                      <div className="frame-207">
                        <img className="search-4" alt="Search" src="/img1/search-2.svg" />
                        <div className="value-4">Search</div>
                      </div>
                      <img className="img-9" alt="Tune" src="/img1/tune.png" />
                    </div>
                    <div className="holder-wrapper">
                      <div className="holder-2">
                        <img className="img-9" alt="Add" src="/img1/add-4.png" />
                        <div className="text-wrapper-122">Create Event</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-208">
                  <div className="nav-bar-wrapper">
                    <div className="div-8">
                      <div className="div-7">
                        <div className="nav-link-16">
                          <div className="text-wrapper-123">Scheduled</div>
                        </div>
                        <div className="nav-link-17">
                          <div className="text-wrapper-123">Completed</div>
                        </div>
                        <div className="nav-link-17">
                          <div className="text-wrapper-123">Drafts</div>
                        </div>
                        <div className="nav-link-18">
                          <div className="text-wrapper-123">Assigned to me</div>
                        </div>
                        <div className="nav-link-17">
                          <div className="text-wrapper-123">Favourites</div>
                        </div>
                        <div className="nav-link-19">
                          <div className="text-wrapper-124">All</div>
                        </div>
                      </div>
                      <div className="frame-209">
                        <img className="img-9" alt="Download" src="/img1/download-2.png" />
                        <div className="text-wrapper-125">Download</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-210">
                    <div className="frame-211">
                      <div className="dropdown-5">
                        <div className="frame-212">
                          <div className="text-wrapper-126">Sort by:</div>
                          <div className="frame-213">
                            <div className="text-wrapper-127">Recently Added</div>
                            <img className="chevron-right-2" alt="Chevron right" src="/img1/chevron-right.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-events-2">
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-3">
                                <div className="text-wrapper-128">LIVE</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-13.svg" />
                                </div>
                                <div className="div-25">
                                  <div className="div-25">
                                    <p className="text-wrapper-130">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="frame-42">
                                  <div className="chip-speaker-8">
                                    <div className="frame-216" />
                                    <div className="name-7">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-217" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-218" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-9">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-46">
                            <div className="frame-219">
                              <div className="div-24">
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Join Now</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-7.svg" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-14" />
                                <div className="avatar-w-photo-15" />
                                <div className="avatar-w-photo-16" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="frame-214">
                                <div className="live-4">
                                  <div className="text-wrapper-128">SCHEDULED</div>
                                </div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-12.svg" />
                                </div>
                                <div className="div-25">
                                  <div className="div-25">
                                    <div className="text-wrapper-135">10 Dec 2020,</div>
                                    <p className="text-wrapper-130">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-7">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="div-10">
                                  <div className="chip-speaker-10">
                                    <div className="frame-220" />
                                    <div className="name-8">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-221" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-222" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-12">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-46">
                            <div className="frame-223">
                              <div className="frame-224">
                                <div className="frame-225">
                                  <img
                                    className="calendar-add-on-3"
                                    alt="Calendar add on"
                                    src="/img1/calendar-add-on-3.png"
                                  />
                                </div>
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Register</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-6.svg" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-18" />
                                <div className="avatar-w-photo-19" />
                                <div className="avatar-w-photo-20" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-3">
                                <div className="text-wrapper-128">LIVE</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="div-26">
                                  <div className="frame-212">
                                    <p className="text-wrapper-136">10 Dec 20 - 12 Dec 22</p>
                                    <div className="ellipse-24" />
                                    <p className="text-wrapper-137">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-11.svg" />
                                </div>
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="frame-42">
                                  <div className="chip-speaker-8">
                                    <div className="frame-226" />
                                    <div className="name-7">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-227" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-228" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-9">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-229">
                            <div className="frame-219">
                              <div className="div-24">
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Join Now</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-5.svg" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-21" />
                                <div className="avatar-w-photo-22" />
                                <div className="avatar-w-photo-23" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-4">
                                <div className="text-wrapper-128">SCHEDULED</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-10.png" />
                                </div>
                                <div className="div-26">
                                  <div className="frame-212">
                                    <p className="text-wrapper-136">10 Dec 20 - 12 Dec 22</p>
                                    <div className="ellipse-24" />
                                    <p className="text-wrapper-137">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-7">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="div-10">
                                  <div className="chip-speaker-10">
                                    <div className="frame-230" />
                                    <div className="name-8">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-231" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-232" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-12">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-233">
                            <div className="frame-234">
                              <div className="frame-235">
                                <div className="frame-225">
                                  <img
                                    className="calendar-add-on-3"
                                    alt="Calendar add on"
                                    src="/img1/calendar-add-on-2.png"
                                  />
                                </div>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Register</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-4.png" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-24" />
                                <div className="avatar-w-photo-25" />
                                <div className="avatar-w-photo-26" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-events-2">
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-3">
                                <div className="text-wrapper-128">LIVE</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-9.png" />
                                </div>
                                <div className="div-25">
                                  <div className="div-25">
                                    <p className="text-wrapper-130">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="frame-42">
                                  <div className="chip-speaker-8">
                                    <div className="frame-236" />
                                    <div className="name-7">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-237" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-238" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-9">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-46">
                            <div className="frame-219">
                              <div className="div-24">
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Join Now</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-3.png" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-27" />
                                <div className="avatar-w-photo-28" />
                                <div className="avatar-w-photo-29" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="frame-214">
                                <div className="live-4">
                                  <div className="text-wrapper-128">SCHEDULED</div>
                                </div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-8.png" />
                                </div>
                                <div className="div-25">
                                  <div className="div-25">
                                    <div className="text-wrapper-135">10 Dec 2020,</div>
                                    <p className="text-wrapper-130">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-7">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="div-10">
                                  <div className="chip-speaker-10">
                                    <div className="frame-239" />
                                    <div className="name-8">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-240" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-241" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-12">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-46">
                            <div className="frame-223">
                              <div className="frame-224">
                                <div className="frame-225">
                                  <img
                                    className="calendar-add-on-3"
                                    alt="Calendar add on"
                                    src="/img1/calendar-add-on-1.png"
                                  />
                                </div>
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Register</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-2.png" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-30" />
                                <div className="avatar-w-photo-31" />
                                <div className="avatar-w-photo-32" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-3">
                                <div className="text-wrapper-128">LIVE</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="div-26">
                                  <div className="frame-212">
                                    <p className="text-wrapper-136">10 Dec 20 - 12 Dec 22</p>
                                    <div className="ellipse-24" />
                                    <p className="text-wrapper-137">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-7.png" />
                                </div>
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="frame-42">
                                  <div className="chip-speaker-8">
                                    <div className="frame-242" />
                                    <div className="name-7">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-243" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="frame-244" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-9">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-229">
                            <div className="frame-219">
                              <div className="div-24">
                                <button className="CTA-9">
                                  <div className="text-wrapper-132">View Details</div>
                                </button>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Join Now</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal-1.png" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-33" />
                                <div className="avatar-w-photo-34" />
                                <div className="avatar-w-photo-35" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-2">
                        <div className="div-8">
                          <div className="element-2">
                            <div className="frame-214">
                              <div className="live-4">
                                <div className="text-wrapper-128">SCHEDULED</div>
                              </div>
                            </div>
                            <div className="holder-3">
                              <div className="frame-215">
                                <div className="frame-207">
                                  <p className="text-wrapper-129">
                                    Vmware CEO reveals big changes amid multi-cloud shift
                                  </p>
                                  <img className="img-9" alt="Star" src="/img1/star-6.png" />
                                </div>
                                <div className="div-26">
                                  <div className="frame-212">
                                    <p className="text-wrapper-136">10 Dec 20 - 12 Dec 22</p>
                                    <div className="ellipse-24" />
                                    <p className="text-wrapper-137">10.30 PM - 11.30 AM IST</p>
                                  </div>
                                </div>
                              </div>
                              <div className="speakers-7">
                                <div className="text-wrapper-131">Speakers:</div>
                                <div className="div-10">
                                  <div className="chip-speaker-10">
                                    <div className="frame-245" />
                                    <div className="name-8">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-246" />
                                    <div className="name-7">Sam</div>
                                  </div>
                                  <div className="chip-speaker-11">
                                    <div className="frame-247" />
                                    <div className="name-7">Cory</div>
                                  </div>
                                  <div className="chip-speaker-12">
                                    <div className="name-7">+3</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-233">
                            <div className="frame-234">
                              <div className="frame-235">
                                <div className="frame-225">
                                  <img
                                    className="calendar-add-on-3"
                                    alt="Calendar add on"
                                    src="/img1/calendar-add-on.png"
                                  />
                                </div>
                                <button className="CTA-10">
                                  <div className="text-wrapper-133">Register</div>
                                </button>
                              </div>
                              <img className="img-10" alt="Dots horizontal" src="/img1/dots-horizontal.png" />
                            </div>
                            <div className="speakers-6">
                              <div className="text-wrapper-131">Attending:</div>
                              <div className="attending-2">
                                <div className="avatar-w-photo-36" />
                                <div className="avatar-w-photo-37" />
                                <div className="avatar-w-photo-38" />
                                <div className="avatar-w-photo-17">
                                  <div className="text-wrapper-134">120</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-21">
                  <div className="frame-215">
                    <div className="frame-248">
                      <NavLink
                        className="nav-link-20"
                        divClassName="nav-link-21"
                        icon={<Icons23 className="img-9" />}
                        stateProp="default"
                        text="Dashboard"
                        type="default"
                      />
                      <div className="nav-link-22">
                        <Icons
                          icon="RC"
                          iconRcClassName="icons-28"
                          union="/img/union-3.svg"
                          unionClassName="icons-29"
                        />
                        <div className="dashboard-5">Resource Center</div>
                      </div>
                      <NavLink
                        className="nav-link-20"
                        divClassName="nav-link-21"
                        icon={<Icons44 className="img-9" />}
                        stateProp="default"
                        text="Learning Center"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-20"
                        divClassName="nav-link-21"
                        icon={<Icons21 className="img-9" />}
                        stateProp="selected"
                        text="Auditorium"
                        type="default"
                      />
                      <div className="nav-link-22">
                        <Icons
                          divClassName="icons-34"
                          divClassNameOverride="icons-35"
                          ellipseClassName="icons-32"
                          ellipseClassNameOverride="icons-33"
                          icon="MT"
                          iconRcClassName="icons-28"
                          overlapGroupClassName="icons-31"
                          unionClassName="icons-30"
                        />
                        <div className="dashboard-5">Meetings</div>
                      </div>
                      <NavLink
                        className="nav-link-20"
                        divClassName="nav-link-21"
                        icon={<Icons42 className="img-9" />}
                        stateProp="default"
                        text="Quizzes"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-20"
                        divClassName="nav-link-21"
                        icon={<Icons25 className="img-9" />}
                        stateProp="default"
                        text="Manage"
                        type="default"
                      />
                    </div>
                  </div>
                  <div className="frame-215">
                    <NavLink
                      className="nav-link-23"
                      divClassName="nav-link-21"
                      icon={<Icons18 className="img-9" />}
                      stateProp="default"
                      text="Logout"
                      type="default"
                    />
                    <div className="extra-4">
                      <div className="text-wrapper-138">Policies</div>
                      <div className="interpunct-4">
                        <div className="ellipse-25" />
                      </div>
                      <div className="text-wrapper-138">Terms &amp; Conditions</div>
                    </div>
                  </div>
                </div>
                <div className="frame-249">
                  <div className="frame-250" />
                  <div className="frame-251">
                    <div className="frame-207">
                      <img className="img-10" alt="Icon" src="/img1/icon-4.svg" />
                      <img className="img-10" alt="Notifications" src="/img1/notifications.svg" />
                    </div>
                    <div className="div-26">
                      <div className="frame-252">
                        <div className="ellipse-26" />
                      </div>
                      <div className="text-wrapper-139">Santosh</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-140">Personalized experience throughout</div>
          </div>
        </div>
        <div className="overlap-17">
          <p className="works-on-all">Works on all, wherever you are,&nbsp;&nbsp;whenever, you need it.</p>
          <AppleIphonePro
            className="apple-iphone-11-pro-gold"
            tableHeaderCellClassName="apple-iphone-pro-instance"
            tableHeaderCellClassNameOverride="apple-iphone-pro-instance"
          />
        </div>
        <div className="overlap-18">
          <div className="text-wrapper-141">Have a project?</div>
          <p className="text-wrapper-142">We’re just an e-mail away!</p>
          <img className="frame-253" alt="Frame" src="/img1/frame-2007.png" />
        </div>
      </div>
    </div>
  );
};
