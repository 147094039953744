/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ThirtyTwoGeneralAlert = ({ color = "#F69E86", className }) => {
  return (
    <svg
      className={`thirty-two-general-alert ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.11576 7.6737L6.11575 5.45624C6.11575 5.15008 6.36395 4.90188 6.67012 4.90188C6.97629 4.90188 7.22448 5.15007 7.22448 5.45624L7.22449 7.6737C7.22449 7.97987 6.97629 8.22807 6.67012 8.22807C6.36395 8.22807 6.11576 7.97987 6.11576 7.6737Z"
        fill={color}
      />
      <path
        className="path"
        d="M6.67012 10.4455C6.97629 10.4455 7.22449 10.1973 7.22449 9.89117C7.22449 9.585 6.97629 9.3368 6.67012 9.3368C6.36395 9.3368 6.11576 9.585 6.11576 9.89117C6.11576 10.1973 6.36395 10.4455 6.67012 10.4455Z"
        fill={color}
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M6.67012 1.57568C7.09008 1.57568 7.47399 1.81295 7.6618 2.18858L12.0967 11.0584C12.2686 11.4021 12.2502 11.8103 12.0482 12.1372C11.8462 12.464 11.4893 12.663 11.105 12.663H2.2352C1.85094 12.663 1.49407 12.464 1.29205 12.1372C1.09004 11.8103 1.07167 11.4021 1.24352 11.0584L5.67844 2.18858C5.86625 1.81295 6.25017 1.57568 6.67012 1.57568ZM2.2352 11.5543H11.105L6.67012 2.68441L2.2352 11.5543Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

ThirtyTwoGeneralAlert.propTypes = {
  color: PropTypes.string,
};
