/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Star } from "../Star";
import "./style.css";

export const AppleIphonePro = ({ className, tableHeaderCellClassName, tableHeaderCellClassNameOverride }) => {
  return (
    <div className={`apple-iphone-pro ${className}`}>
      <div className="overlap-group-3">
        <div className="manage-users">
          <div className="frame-5">
            <div className="text-wrapper-6">Manage</div>
            <div className="frame-6">
              <div className="text-input">
                <div className="frame-7">
                  <img className="search" alt="Search" src="/img/search.svg" />
                  <div className="value">Search</div>
                </div>
              </div>
              <div className="sort-wrapper">
                <img className="img" alt="Sort" src="/img/sort.png" />
              </div>
            </div>
          </div>
          <div className="frame-wrapper">
            <div className="frame-8">
              <div className="frame-9">
                <img className="img" alt="Icon" src="/img/icon-2.png" />
              </div>
              <div className="frame-10">
                <div className="ellipse-wrapper">
                  <div className="ellipse-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-11">
            <div className="container-menu">
              <div className="navlink">
                <div className="text-wrapper-7">Members</div>
                <div className="badge-base">
                  <div className="text">1456</div>
                </div>
              </div>
              <div className="navlink-wrapper">
                <div className="navlink-2">
                  <div className="text-wrapper-8">Team</div>
                  <div className="div-wrapper">
                    <div className="text-wrapper-9">12</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-12">
              <img className="img-2" alt="Download" src="/img/download.png" />
              <div className="text-wrapper-10">Download</div>
            </div>
          </div>
          <div className="frame-13">
            <div className="column">
              <div className="table-header-cell">
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-2">Members</div>
                  </div>
                  <img className="img-2" alt="Arrow down" src="/img/arrow-down-6.svg" />
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar" />
                <div className="div-4">
                  <div className="text-3">Olivia Rhye</div>
                  <div className="supporting-text">olivia@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-2" />
                <div className="div-4">
                  <div className="text-3">Phoenix Baker</div>
                  <div className="supporting-text">phoenix@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-3">
                  <div className="text-4">LS</div>
                </div>
                <div className="div-4">
                  <div className="text-3">Lana Steiner</div>
                  <div className="supporting-text">lana@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-3">
                  <div className="text-4">DW</div>
                </div>
                <div className="div-4">
                  <div className="text-3">Demi Wilkinson</div>
                  <div className="supporting-text">demi@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-4" />
                <div className="div-4">
                  <div className="text-3">Candice Wu</div>
                  <div className="supporting-text">candice@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-3">
                  <div className="text-4">NC</div>
                </div>
                <div className="div-4">
                  <div className="text-3">Natali Craig</div>
                  <div className="supporting-text">natali@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-5" />
                <div className="div-4">
                  <div className="text-3">Drew Cano</div>
                  <div className="supporting-text">drew@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-6" />
                <div className="div-4">
                  <div className="text-3">Orlando Diggs</div>
                  <div className="supporting-text">orlando@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-7" />
                <div className="div-4">
                  <div className="text-3">Andi Lane</div>
                  <div className="supporting-text">andi@untitledui.com</div>
                </div>
              </div>
              <div className="table-cell">
                <div className="avatar-8" />
                <div className="div-4">
                  <div className="text-3">Kate Morrison</div>
                  <div className="supporting-text">kate@untitledui.com</div>
                </div>
              </div>
            </div>
            <div className="div-4">
              <div className="table-header-wrapper">
                <div className="div-3">
                  <div className="text-5">Team</div>
                </div>
              </div>
              <div className="table-cell-2">
                <div className="frame-7">
                  <div className="badge-base-2">
                    <div className="text-6">Marketing</div>
                  </div>
                  <div className="badge-base-3">
                    <div className="text-6">Human Resource</div>
                  </div>
                </div>
              </div>
              <div className="badge-wrapper">
                <div className="badge">
                  <div className="badge-base-4">
                    <div className="text-6">Operations</div>
                  </div>
                  <div className="badge-base-5">
                    <div className="text-7">+3 More</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-3">
                    <div className="text-6">Human Resource</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-3">
                    <div className="text-6">Human Resource</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-2">
                  <div className="badge-base-4">
                    <div className="text-6">Operations</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-6">
                    <div className="text-6">Finance</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-6">
                    <div className="text-6">Finance</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-6">
                    <div className="text-6">Finance</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-3">
                    <div className="text-6">Human Resource</div>
                  </div>
                </div>
              </div>
              <div className="table-cell-3">
                <div className="badge-base-wrapper">
                  <div className="badge-base-7">
                    <div className="text-8">Design</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-2">
              <div className={`table-header-cell-2 ${tableHeaderCellClassName}`}>
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-2">Last Active</div>
                  </div>
                  <img className="img-2" alt="Arrow down" src="/img/arrow-down-4.png" />
                </div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
            </div>
            <div className="column-2">
              <div className={`table-header-cell-2 ${tableHeaderCellClassNameOverride}`}>
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-2">Joined On</div>
                  </div>
                  <img className="img-2" alt="Arrow down" src="/img/arrow-down-2.png" />
                </div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
              <div className="table-cell-4">
                <div className="text-9">Jan 6, 2022</div>
              </div>
            </div>
            <div className="column-3">
              <div className="table-header-cell-3">
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-2">Role</div>
                  </div>
                  <img className="img-2" alt="Arrow down" src="/img/arrow-down.png" />
                </div>
              </div>
              <div className="table-cell-5">
                <div className="frame-14">
                  <div className="element-hours-ago">Admin</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-18.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Admin</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-16.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Admin</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-14.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Admin</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-12.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago-2">Instructor</div>
                  <img className="vector-2" alt="Vector" src="/img/vector-3-10.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Speaker</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-8.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Member</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-6.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Member</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-4.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Member</div>
                  <img className="vector" alt="Vector" src="/img/vector-3-2.png" />
                </div>
              </div>
              <div className="table-cell-6">
                <div className="frame-15">
                  <div className="element-hours-ago">Member</div>
                  <img className="vector" alt="Vector" src="/img/vector-3.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-16">
            <div className="frame-17">
              <div className="text-wrapper-11">Resources Assgined to you</div>
              <div className="frame-18">
                <div className="text-wrapper-12">See All</div>
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward.png" />
              </div>
            </div>
            <div className="frame-19">
              <div className="card-design">
                <img className="folder-card" alt="Folder card" src="/img/folder-card-6.png" />
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-22">
                      <div className="frame-21">
                        <div className="frame-23">
                          <div className="frame-10">
                            <div className="text-wrapper-13">Marketing</div>
                            <div className="frame-24">
                              <div className="text-wrapper-14">UNREAD ITEMS</div>
                            </div>
                          </div>
                        </div>
                        <Star state="default" />
                      </div>
                      <div className="frame-25">
                        <div className="subheading-wrapper">
                          <div className="subheading">4.3</div>
                        </div>
                        <div className="ellipse-6" />
                        <div className="supporting-text-2">a week ago</div>
                      </div>
                    </div>
                  </div>
                  <img className="more-horiz" alt="More horiz" src="/img/more-horiz-4.png" />
                </div>
              </div>
              <div className="card-design">
                <img className="folder-card" alt="Folder card" src="/img/folder-card-4.png" />
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-22">
                      <div className="frame-21">
                        <div className="frame-23">
                          <div className="frame-10">
                            <div className="text-wrapper-13">Sales</div>
                          </div>
                        </div>
                        <Star state="default" />
                      </div>
                      <div className="frame-25">
                        <div className="subheading-wrapper">
                          <div className="subheading">4.3</div>
                        </div>
                        <div className="ellipse-6" />
                        <div className="supporting-text-2">a week ago</div>
                      </div>
                    </div>
                  </div>
                  <img className="more-horiz" alt="More horiz" src="/img/more-horiz-2.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-26">
            <div className="frame-17">
              <div className="text-wrapper-11">Recommended for you</div>
              <div className="frame-18">
                <div className="text-wrapper-12">See All</div>
                <img className="arrow-forward-2" alt="Arrow forward" src="/img/arrow-forward.png" />
              </div>
            </div>
            <div className="frame-19">
              <div className="card-design">
                <img className="folder-card" alt="Folder card" src="/img/folder-card-2.png" />
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-22">
                      <div className="frame-21">
                        <div className="frame-23">
                          <div className="frame-10">
                            <div className="text-wrapper-13">File Name</div>
                          </div>
                        </div>
                        <Star state="default" />
                      </div>
                      <div className="div-3">
                        <img className="img" alt="Element" src="/img/01.png" />
                        <div className="ellipse-6" />
                        <div className="subheading-wrapper">
                          <div className="subheading">4.3</div>
                        </div>
                        <div className="ellipse-6" />
                        <div className="supporting-text-2">a week ago</div>
                      </div>
                    </div>
                  </div>
                  <img className="more-horiz" alt="More horiz" src="/img/more-horiz.png" />
                </div>
              </div>
              <div className="card-design-2">
                <img className="folder-card" alt="Folder card" src="/img/folder-card.png" />
                <div className="frame-27">
                  <div className="frame-28">
                    <div className="frame-29">
                      <p className="p">Vmware CEO reveals big changes amid multi-cloud shift</p>
                    </div>
                    <img className="frame-30" alt="Frame" src="/img/frame-40093.png" />
                  </div>
                  <div className="frame-31">
                    <div className="text-wrapper-15">10 Dec 2020</div>
                    <div className="text-wrapper-15">10.30 PM IST</div>
                  </div>
                </div>
                <div className="speakers">
                  <div className="text-wrapper-16">Speakers:</div>
                  <div className="div-3">
                    <div className="chip-speaker">
                      <div className="frame-32" />
                      <div className="name">Cameron</div>
                    </div>
                    <div className="chip-speaker">
                      <div className="frame-33" />
                      <div className="name">Sam</div>
                    </div>
                    <div className="name-wrapper">
                      <div className="name-2">+2 more</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="img-3" alt="Apple iphone pro" src="/img/apple-iphone-11-pro-gold.png" />
      </div>
    </div>
  );
};
