/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Icons49 } from "../../icons/Icons49";
import { Icons52 } from "../../icons/Icons52";
import "./style.css";

export const NavLink = ({
  stateProp,
  type,
  className,
  icon = <Icons49 className="icons-49" color="#363636" />,
  divClassName,
  text = "Dashboard",
  override = <Icons52 className="icons-52" />,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
    type: type || "default",
  });

  return (
    <div
      className={`nav-link ${state.type} ${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      {state.type === "default" && (
        <>
          {icon}
          <div className={`dashboard ${divClassName}`}>{text}</div>
        </>
      )}

      {state.type === "collapsed" && <>{override}</>}
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "default" && state.type === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "default",
        };

      case "click":
        return {
          state: "selected",
          type: "default",
        };
    }
  }

  if (state.state === "default" && state.type === "collapsed") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "collapsed",
        };

      case "click":
        return {
          state: "selected",
          type: "collapsed",
        };
    }
  }

  if (state.state === "hover" && state.type === "collapsed") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "default",
          type: "collapsed",
        };
    }
  }

  if (state.state === "selected" && state.type === "collapsed") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "default",
        };
    }
  }

  if (state.state === "hover" && state.type === "default") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "selected",
          type: "collapsed",
        };
    }
  }

  return state;
}

NavLink.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "selected", "default"]),
  type: PropTypes.oneOf(["collapsed", "default"]),
  text: PropTypes.string,
};
