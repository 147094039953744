/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconMg = ({ className }) => {
  return (
    <svg
      className={`icon-mg ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.8294 7.60182L7.60259 11.6403L15.8298 15.6795L24.0567 11.641L15.8294 7.60182ZM16.8292 5.56548L25.0565 9.60465C26.1811 10.1569 26.6453 11.5162 26.093 12.6408C25.8718 13.0915 25.507 13.4561 25.0563 13.6775L16.8295 17.7158C16.1989 18.0254 15.4606 18.0254 14.83 17.7158L6.60277 13.6767C5.47812 13.1246 5.014 11.7651 5.56625 10.6405C5.78748 10.1898 6.15223 9.82518 6.60294 9.60397L14.8298 5.56548C15.4604 5.25596 16.1988 5.25596 16.8292 5.56548ZM6.18677 17.882C5.61848 17.6185 5.37145 16.944 5.63503 16.3757C5.89859 15.8074 6.57301 15.5604 7.1413 15.824L15.7267 19.8064L15.8302 19.861L15.9335 19.8064L24.5184 15.8248C25.0867 15.5613 25.7611 15.8083 26.0247 16.3766C26.2882 16.9449 26.0412 17.6193 25.4729 17.8829L16.932 21.844C16.247 22.2248 15.4132 22.2248 14.7282 21.844L6.18677 17.882ZM6.18677 22.4191C5.61848 22.1554 5.37145 21.4811 5.63503 20.9129C5.89859 20.3446 6.57301 20.0975 7.1413 20.3611L15.7267 24.3434L15.8302 24.3981L15.9335 24.3434L24.5184 20.362C25.0867 20.0982 25.7611 20.3454 26.0247 20.9137C26.2882 21.482 26.0412 22.1562 25.4729 22.4198L16.932 26.3811C16.247 26.7619 15.4132 26.7619 14.7282 26.3809L6.18677 22.4191Z"
        fill="#363636"
      />
    </svg>
  );
};
