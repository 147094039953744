/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Endpoint210 } from "../../icons/Endpoint210";
import { Endpoint28 } from "../../icons/Endpoint28";
import "./style.css";

export const Endpoint = ({ shape, shapeRectangleClassName, shapeMeasure = "/img/shape-measure.svg" }) => {
  return (
    <>
      {shape === "triangle-forward" && <Endpoint28 className="shape-triangle" color="black" />}

      {shape === "triangle-backwards" && <Endpoint210 className="shape-triangle" color="black" />}

      {["circle", "rectangle"].includes(shape) && (
        <div className={`endpoint shape-rectangle ${shape} ${shapeRectangleClassName}`} />
      )}

      {shape === "measure" && (
        <img className={`endpoint shape-measure ${shapeRectangleClassName}`} alt="Shape measure" src={shapeMeasure} />
      )}
    </>
  );
};

Endpoint.propTypes = {
  shape: PropTypes.oneOf(["circle", "triangle-backwards", "triangle-forward", "measure", "rectangle"]),
  shapeMeasure: PropTypes.string,
};
