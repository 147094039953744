/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair28 = ({ className }) => {
  return (
    <svg
      className={`chair-28 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M39.017 21.9537C37.7246 27.7978 37.0783 30.7199 35.0757 32.5617C34.7912 32.8234 34.4894 33.0657 34.1724 33.2871C31.9416 34.8447 28.9489 34.8447 22.9636 34.8447L21.1702 34.8447C15.3825 34.8447 12.4886 34.8447 10.3058 33.3845C9.88419 33.1025 9.48823 32.784 9.12247 32.4325C7.22872 30.6131 6.60901 27.7863 5.36959 22.1328L5.25804 21.624C3.31162 12.7456 2.3384 8.30635 4.20059 5.07447C4.698 4.21119 5.32628 3.43026 6.06301 2.75952C8.82115 0.248439 13.3658 0.24844 22.4551 0.24844V0.24844C31.3261 0.248441 35.7616 0.248442 38.4502 2.69993C39.1683 3.35469 39.7804 4.11695 40.2646 4.95949C42.0777 8.114 41.1199 12.4449 39.2043 21.1066L39.017 21.9537Z"
        fill="#202020"
      />
      <path
        className="path"
        d="M35.2874 21.9251C34.4918 25.7193 34.094 27.6164 32.9238 28.9155C32.4729 29.4162 31.9466 29.8435 31.3641 30.1821C29.8525 31.0608 27.9141 31.0608 24.0374 31.0608L20.152 31.0608C16.4264 31.0608 14.5636 31.0608 13.0964 30.2476C12.4259 29.8759 11.8266 29.3884 11.3263 28.8075C10.2315 27.5365 9.85238 25.7127 9.09418 22.0651L8.45937 19.0111C7.40978 13.9617 6.88499 11.437 7.33648 9.41123C7.99636 6.45044 10.0117 3.97311 12.7762 2.72449C14.6677 1.87017 17.2464 1.87017 22.4038 1.87018V1.87018C27.4368 1.87018 29.9533 1.87018 31.7979 2.7042C34.4937 3.92317 36.4575 6.34139 37.0972 9.23006C37.535 11.2065 37.0185 13.6695 35.9856 18.5954L35.2874 21.9251Z"
        fill="#5E5E5E"
      />
      <path
        className="path"
        d="M33.8428 17.4866C33.4992 18.4361 33.3275 18.9109 33.0827 19.3016C32.4632 20.2906 31.4703 20.9873 30.3296 21.2333C29.8788 21.3306 29.374 21.3306 28.3642 21.3306L15.3055 21.3306C14.1974 21.3306 13.6433 21.3306 13.1544 21.2154C12.0078 20.9455 11.021 20.2195 10.422 19.2053C10.1666 18.7728 10.0016 18.2438 9.67176 17.1859V17.1859C8.52524 13.5087 7.95198 11.6702 8.01044 10.1801C8.14908 6.64674 10.4247 3.5536 13.7566 2.36949C15.1617 1.87016 17.0876 1.87016 20.9393 1.87016L22.8856 1.87016C26.708 1.87016 28.6192 1.87016 29.9928 2.34521C33.52 3.5651 35.8669 6.90994 35.814 10.6418C35.7934 12.095 35.1432 13.8922 33.8428 17.4866V17.4866Z"
        fill="#AFAFAF"
      />
      <path
        className="path"
        d="M22.1945 19.1682L22.1945 17.0059"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.08113"
      />
    </svg>
  );
};
