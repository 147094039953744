/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FileName = ({
  className,
  star = "/img/star-16.svg",
  frameClassName,
  frameClassNameOverride,
  hasFrame = true,
  hasElement = true,
  supportingTextClassName,
  iconButton = "/img/icon-button-6.svg",
}) => {
  return (
    <div className={`file-name ${className}`}>
      <div className={`frame-34 ${frameClassName}`}>
        <div className="frame-35">
          <div className="frame-36">
            <div className="frame-37">
              <div className="text-and-supporting-wrapper">
                <div className="text-and-supporting">
                  <div className={`frame-38 ${frameClassNameOverride}`}>
                    <div className="text">File Name</div>
                    {hasFrame && (
                      <div className="frame-39">
                        <div className="text-wrapper-15">NEW</div>
                      </div>
                    )}

                    <img className="star-2" alt="Star" src={star} />
                  </div>
                  <div className="frame-40">
                    {hasElement && (
                      <div className="element-4">
                        <div className="overlap-group-5">
                          <img className="img-7" alt="Vector" src="/img/vector-10.svg" />
                          <div className="img-wrapper">
                            <img className="img-7" alt="Group" src="/img/group-11.png" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="ellipse-10" />
                    <div className={`supporting-text-2 ${supportingTextClassName}`}>a week ago</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="icon-button" alt="Icon button" src={iconButton} />
        </div>
      </div>
    </div>
  );
};

FileName.propTypes = {
  star: PropTypes.string,
  hasFrame: PropTypes.bool,
  hasElement: PropTypes.bool,
  iconButton: PropTypes.string,
};
