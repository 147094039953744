/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Endpoint266 = ({ className }) => {
  return (
    <svg
      className={`endpoint-2-66 ${className}`}
      fill="none"
      height="4"
      viewBox="0 0 8 4"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M8 0L0 -4V4L8 0Z" fill="black" />
    </svg>
  );
};
