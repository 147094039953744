/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ThirtyTwoArrowChevronRight8 } from "../../icons/ThirtyTwoArrowChevronRight8";
import { ThirtyTwoGeneralPencil30 } from "../../icons/ThirtyTwoGeneralPencil30";
import "./style.css";

export const Button = ({ type, size, icon, className }) => {
  return (
    <div className={`button ${type} ${icon} size-0-${size} ${className}`}>
      {["none", "right"].includes(icon) && (
        <div className="div-2">
          {["outline", "primary", "secondary"].includes(type) && <>Button</>}

          {type === "text" && <>Text Button</>}
        </div>
      )}

      {icon === "right" && (
        <ThirtyTwoArrowChevronRight8
          className="instance-node-3"
          color={type === "primary" ? "white" : type === "secondary" ? "#1E202C" : "#4F78DB"}
        />
      )}

      {["icon-only", "left"].includes(icon) && (
        <ThirtyTwoGeneralPencil30
          className="instance-node-3"
          color={type === "primary" ? "white" : type === "secondary" ? "#1E202C" : "#4F78DB"}
        />
      )}

      {icon === "left" && (
        <div className="div-3">
          {["outline", "primary", "secondary"].includes(type) && <>Button</>}

          {type === "text" && <>Text Button</>}
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "text", "secondary", "outline"]),
  size: PropTypes.oneOf(["large", "small"]),
  icon: PropTypes.oneOf(["icon-only", "none", "right", "left"]),
};
