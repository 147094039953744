/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair24 = ({ className }) => {
  return (
    <svg
      className={`chair-24 ${className}`}
      fill="none"
      height="45"
      viewBox="0 0 45 45"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M22.6895 5.74184C28.5337 7.0343 31.4558 7.68053 33.2976 9.68314C33.5593 9.96769 33.8016 10.2695 34.0229 10.5864C35.5806 12.8172 35.5806 15.8099 35.5806 21.7953L35.5806 23.5887C35.5806 29.3764 35.5806 32.2703 34.1204 34.4531C33.8384 34.8747 33.5198 35.2706 33.1684 35.6364C31.3489 37.5301 28.5222 38.1499 22.8687 39.3893L22.3599 39.5008C13.4814 41.4473 9.04223 42.4205 5.81035 40.5583C4.94706 40.0609 4.16613 39.4326 3.49539 38.6959C0.984314 35.9377 0.984314 31.3931 0.984314 22.3038V22.3038C0.984313 13.4328 0.984313 8.99726 3.4358 6.30868C4.09056 5.59059 4.85282 4.97849 5.69536 4.49424C8.84987 2.68115 13.1807 3.63894 21.8425 5.55452L22.6895 5.74184Z"
        fill="#202020"
      />
      <path
        className="path"
        d="M22.6609 9.47135C26.4551 10.267 28.3523 10.6648 29.6513 11.8349C30.152 12.2859 30.5793 12.8121 30.918 13.3947C31.7966 14.9063 31.7966 16.8446 31.7966 20.7214L31.7966 24.6068C31.7966 28.3324 31.7966 30.1952 30.9834 31.6624C30.6118 32.3329 30.1242 32.9322 29.5434 33.4325C28.2724 34.5273 26.4486 34.9064 22.8009 35.6646L19.747 36.2994C14.6976 37.349 12.1729 37.8738 10.1471 37.4223C7.18628 36.7624 4.70896 34.7471 3.46034 31.9826C2.60602 30.0911 2.60602 27.5124 2.60602 22.355V22.355C2.60602 17.322 2.60602 14.8055 3.44004 12.9609C4.65902 10.2651 7.07723 8.30132 9.9659 7.66155C11.9423 7.22382 14.4053 7.74026 19.3312 8.77316L22.6609 9.47135Z"
        fill="#5E5E5E"
      />
      <path
        className="path"
        d="M18.2224 10.916C19.172 11.2596 19.6467 11.4313 20.0375 11.6761C21.0265 12.2956 21.7232 13.2885 21.9692 14.4292C22.0664 14.88 22.0664 15.3848 22.0664 16.3946L22.0664 29.4533C22.0664 30.5614 22.0664 31.1155 21.9513 31.6044C21.6813 32.751 20.9554 33.7378 19.9412 34.3368C19.5087 34.5922 18.9797 34.7572 17.9217 35.087V35.087C14.2446 36.2335 12.406 36.8068 10.916 36.7483C7.38261 36.6097 4.28947 34.3341 3.10536 31.0022C2.60603 29.5971 2.60603 27.6712 2.60603 23.8195L2.60603 21.8731C2.60603 18.0508 2.60603 16.1396 3.08108 14.766C4.30097 11.2388 7.64581 8.89193 11.3776 8.94481C12.8309 8.96541 14.6281 9.61561 18.2224 10.916V10.916Z"
        fill="#AFAFAF"
      />
      <path
        className="path"
        d="M19.9042 22.5642L17.7419 22.5642"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.08113"
      />
    </svg>
  );
};
