/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ButtonWrapper } from "../ButtonWrapper";
import "./style.css";

export const ButtonDefault = ({ className }) => {
  return (
    <div className={`button-default ${className}`}>
      <ButtonWrapper
        add="/img/add-8.png"
        addClassName="button-2"
        className="button-instance"
        divClassName="button-3"
        frameClassName="design-component-instance-node"
        text="New"
      />
    </div>
  );
};
