/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Endpoint23 = ({ className }) => {
  return (
    <svg
      className={`endpoint-2-3 ${className}`}
      fill="none"
      height="8"
      viewBox="0 0 7 8"
      width="7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0.00418186 4.18565L6.93262 0.721436V7.64987L0.00418186 4.18565Z" fill="white" />
    </svg>
  );
};
