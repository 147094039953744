/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const StateDefault = ({ className }) => {
  return (
    <svg
      className={`state-default ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.20744 4.10578C9.45744 3.33828 10.5433 3.33828 10.7924 4.10578L11.6841 6.84911C11.7386 7.01618 11.8445 7.16176 11.9867 7.26503C12.1288 7.3683 12.3 7.42398 12.4758 7.42411H15.3608C16.1683 7.42411 16.5033 8.45744 15.8508 8.93244L13.5174 10.6274C13.375 10.7308 13.2689 10.8766 13.2144 11.044C13.1599 11.2114 13.1598 11.3917 13.2141 11.5591L14.1058 14.3024C14.3558 15.0699 13.4766 15.7091 12.8224 15.2341L10.4891 13.5391C10.3468 13.4358 10.1754 13.3801 9.99952 13.3801C9.82364 13.3801 9.65227 13.4358 9.50994 13.5391L7.1766 15.2341C6.52327 15.7091 5.64494 15.0699 5.8941 14.3024L6.78577 11.5591C6.84008 11.3917 6.83999 11.2114 6.78549 11.044C6.731 10.8766 6.62491 10.7308 6.48244 10.6274L4.14994 8.93328C3.49744 8.45828 3.83327 7.42494 4.63994 7.42494H7.5241C7.69998 7.42499 7.87136 7.36939 8.01371 7.26611C8.15606 7.16282 8.26208 7.01715 8.3166 6.84994L9.20744 4.10578Z"
        stroke="white"
      />
    </svg>
  );
};
