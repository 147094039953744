/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons44 = ({ className }) => {
  return (
    <svg
      className={`icons-44 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M2.6665 8C2.6665 5.79086 4.45737 4 6.6665 4H25.3332C27.5423 4 29.3332 5.79086 29.3332 8V24C29.3332 26.2091 27.5423 28 25.3332 28H6.6665C4.45736 28 2.6665 26.2091 2.6665 24V8ZM6.6665 6.66667C5.93012 6.66667 5.33317 7.26362 5.33317 8V24C5.33317 24.7364 5.93012 25.3333 6.6665 25.3333H25.3332C26.0695 25.3333 26.6665 24.7364 26.6665 24V8C26.6665 7.26362 26.0695 6.66667 25.3332 6.66667H6.6665Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M19.8029 15.5726C20.2943 15.8183 20.2943 16.5195 19.8029 16.7651L13.9603 19.6864C13.517 19.9081 12.9955 19.5858 12.9955 19.0902V13.2475C12.9955 12.752 13.517 12.4296 13.9603 12.6513L19.8029 15.5726Z"
        fill="#363636"
      />
    </svg>
  );
};
