/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Icon1 } from "../../icons/Icon1";
import { ButtonWrapper } from "../ButtonWrapper";
import "./style.css";

export const PropertyDefaultWrapper = ({
  property1,
  className,
  buttonWrapperAddClassName,
  buttonWrapperButtonClassName,
  buttonWrapperDivClassName,
  buttonWrapperText = "New",
  buttonWrapperFrameClassName,
  buttonWrapperAdd = "/img/add-16.png",
  divClassName,
  arrowBackClassName,
  arrowBack = "/img/arrow-back-12.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`property-default-wrapper property-1-1-${state.property1} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      {["default", "hover"].includes(state.property1) && (
        <ButtonWrapper
          add={buttonWrapperAdd}
          addClassName={buttonWrapperAddClassName}
          className={buttonWrapperButtonClassName}
          divClassName={buttonWrapperDivClassName}
          frameClassName={buttonWrapperFrameClassName}
          text={buttonWrapperText}
        />
      )}

      {["add-team-default-hover", "add-team-default", "variant-11", "variant-12", "variant-7", "variant-8"].includes(
        state.property1
      ) && (
        <div className="add-team">
          {["add-team-default-hover", "add-team-default"].includes(state.property1) && <>Add Team</>}

          {["variant-7", "variant-8"].includes(state.property1) && <>Send</>}

          {["variant-11", "variant-12"].includes(state.property1) && (
            <>
              <img className="add-4" alt="Add" src="/img/add-16.png" />
              <div className="text-wrapper-11">New Course</div>
            </>
          )}
        </div>
      )}

      {["variant-5", "variant-6"].includes(state.property1) && (
        <>
          <div className={`text-wrapper-12 ${divClassName}`}>See all</div>
          <img className={`arrow-back-3 ${arrowBackClassName}`} alt="Arrow back" src={arrowBack} />
        </>
      )}

      {["variant-10", "variant-9"].includes(state.property1) && <Icon1 className="icon-14" color="white" />}
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "hover",
        };
    }
  }

  if (state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "default",
        };
    }
  }

  if (state.property1 === "add-team-default") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "add-team-default-hover",
        };
    }
  }

  if (state.property1 === "add-team-default-hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "add-team-default",
        };
    }
  }

  if (state.property1 === "variant-5") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "variant-6",
        };
    }
  }

  if (state.property1 === "variant-6") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "variant-5",
        };
    }
  }

  if (state.property1 === "variant-7") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "variant-8",
        };
    }
  }

  if (state.property1 === "variant-8") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "variant-7",
        };
    }
  }

  if (state.property1 === "variant-9") {
    switch (action) {
      case "click":
        return {
          property1: "variant-10",
        };
    }
  }

  if (state.property1 === "variant-11") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "variant-12",
        };
    }
  }

  if (state.property1 === "variant-12") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "variant-11",
        };
    }
  }

  return state;
}

PropertyDefaultWrapper.propTypes = {
  property1: PropTypes.oneOf([
    "add-team-default",
    "variant-12",
    "default",
    "variant-5",
    "variant-11",
    "add-team-default-hover",
    "variant-8",
    "variant-7",
    "variant-6",
    "hover",
    "variant-10",
    "variant-9",
  ]),
  buttonWrapperText: PropTypes.string,
  buttonWrapperAdd: PropTypes.string,
  arrowBack: PropTypes.string,
};
