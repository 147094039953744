/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons9 = ({ className }) => {
  return (
    <svg
      className={`icons-9 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.2747 4.97421L5.27195 7.43005L10.275 9.88631L15.2778 7.43047L10.2747 4.97421ZM10.8827 3.73589L15.8858 6.19215C16.5697 6.52797 16.852 7.35456 16.5161 8.03847C16.3816 8.31255 16.1598 8.53427 15.8857 8.66889L10.8829 11.1246C10.4994 11.3129 10.0505 11.3129 9.667 11.1246L4.66394 8.66837C3.98004 8.33265 3.6978 7.50596 4.03363 6.82205C4.16816 6.54797 4.38997 6.32625 4.66405 6.19174L9.66685 3.73589C10.0503 3.54767 10.4994 3.54767 10.8827 3.73589V3.73589ZM4.41097 11.2257C4.06539 11.0654 3.91517 10.6553 4.07545 10.3097C4.23573 9.96412 4.64585 9.8139 4.99143 9.97418L10.2123 12.3959L10.2752 12.4291L10.338 12.3959L15.5586 9.9747C15.9042 9.81443 16.3143 9.96464 16.4746 10.3102C16.6348 10.6558 16.4846 11.0659 16.1391 11.2262L10.9453 13.635C10.5287 13.8665 10.0216 13.8665 9.60509 13.635L4.41097 11.2257ZM4.41097 13.9847C4.06539 13.8244 3.91517 13.4143 4.07545 13.0688C4.23573 12.7232 4.64585 12.573 4.99143 12.7332L10.2123 15.1549L10.2752 15.1882L10.338 15.1549L15.5586 12.7338C15.9042 12.5734 16.3143 12.7237 16.4746 13.0693C16.6348 13.4149 16.4846 13.8249 16.1391 13.9852L10.9453 16.394C10.5287 16.6256 10.0216 16.6256 9.60509 16.3939L4.41097 13.9847Z"
        fill="#363636"
      />
    </svg>
  );
};
