/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const ButtonVariantWrapper = ({ className }) => {
  return (
    <div className={`button-variant-wrapper ${className}`}>
      <div className="text-wrapper-5">See all</div>
      <img className="arrow-back-2" alt="Arrow back" src="/img/arrow-back-3.png" />
    </div>
  );
};
