/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Spacer = ({ size, className }) => {
  return <div className={`spacer ${size} ${className}`} />;
};

Spacer.propTypes = {
  size: PropTypes.oneOf([
    "sixteen",
    "twenty-four",
    "forty-eight",
    "eighty",
    "seventy-two",
    "eighty-eight",
    "ninety-six",
    "forty",
    "thirty-two",
    "fifty-six",
    "eight",
    "sixty-four",
  ]),
};
