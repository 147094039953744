/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons42 = ({ className }) => {
  return (
    <svg
      className={`icons-42 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.1335 28L15.8335 24.4H15.5335C12.6935 24.4 10.2835 23.41 8.3035 21.43C6.3235 19.45 5.3335 17.04 5.3335 14.2C5.3335 11.36 6.3235 8.95 8.3035 6.97C10.2835 4.99 12.6935 4 15.5335 4C16.9535 4 18.2783 4.2648 19.5079 4.7944C20.7383 5.3248 21.8187 6.0548 22.7491 6.9844C23.6787 7.9148 24.4083 8.9948 24.9379 10.2244C25.4683 11.4548 25.7335 12.78 25.7335 14.2C25.7335 15.7 25.4887 17.14 24.9991 18.52C24.5087 19.9 23.8387 21.18 22.9891 22.36C22.1387 23.54 21.1287 24.61 19.9591 25.57C18.7887 26.53 17.5135 27.34 16.1335 28ZM18.5335 23.62C19.9535 22.42 21.1083 21.0152 21.9979 19.4056C22.8883 17.7952 23.3335 16.06 23.3335 14.2C23.3335 12.02 22.5787 10.1748 21.0691 8.6644C19.5587 7.1548 17.7135 6.4 15.5335 6.4C13.3535 6.4 11.5087 7.1548 9.9991 8.6644C8.4887 10.1748 7.7335 12.02 7.7335 14.2C7.7335 16.38 8.4887 18.2252 9.9991 19.7356C11.5087 21.2452 13.3535 22 15.5335 22H18.5335V23.62ZM15.5035 20.77C15.8435 20.77 16.1335 20.65 16.3735 20.41C16.6135 20.17 16.7335 19.88 16.7335 19.54C16.7335 19.2 16.6135 18.91 16.3735 18.67C16.1335 18.43 15.8435 18.31 15.5035 18.31C15.1635 18.31 14.8735 18.43 14.6335 18.67C14.3935 18.91 14.2735 19.2 14.2735 19.54C14.2735 19.88 14.3935 20.17 14.6335 20.41C14.8735 20.65 15.1635 20.77 15.5035 20.77ZM14.6335 16.96H16.4335C16.4335 16.36 16.4935 15.94 16.6135 15.7C16.7335 15.46 17.1135 15.02 17.7535 14.38C18.1135 14.02 18.4135 13.63 18.6535 13.21C18.8935 12.79 19.0135 12.34 19.0135 11.86C19.0135 10.84 18.6687 10.0748 17.9791 9.5644C17.2887 9.0548 16.4735 8.8 15.5335 8.8C14.6535 8.8 13.9135 9.0448 13.3135 9.5344C12.7135 10.0248 12.2935 10.62 12.0535 11.32L13.7335 11.98C13.8335 11.64 14.0235 11.3048 14.3035 10.9744C14.5835 10.6448 14.9935 10.48 15.5335 10.48C16.0735 10.48 16.4787 10.63 16.7491 10.93C17.0187 11.23 17.1535 11.56 17.1535 11.92C17.1535 12.26 17.0535 12.5648 16.8535 12.8344C16.6535 13.1048 16.4135 13.38 16.1335 13.66C15.4335 14.26 15.0087 14.7348 14.8591 15.0844C14.7087 15.4348 14.6335 16.06 14.6335 16.96Z"
        fill="#363636"
      />
    </svg>
  );
};
