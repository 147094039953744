/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Endpoint22 = ({ className }) => {
  return (
    <svg
      className={`endpoint-2-2 ${className}`}
      fill="none"
      height="8"
      viewBox="0 0 8 8"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M3.68373 7.28415L7.14795 0.355713L0.219514 0.355713L3.68373 7.28415Z" fill="white" />
    </svg>
  );
};
