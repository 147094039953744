/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StateDefault } from "../../icons/StateDefault";
import { StateSelected } from "../../icons/StateSelected";
import "./style.css";

export const Star = ({ state }) => {
  return (
    <>
      {state === "default" && <StateDefault className="instance-node-2" />}

      {state === "selected" && <StateSelected className="instance-node-2" />}
    </>
  );
};

Star.propTypes = {
  state: PropTypes.oneOf(["selected", "default"]),
};
