import React from "react";
import { AllFiles } from "../../components/AllFiles";
import { AnnotationText } from "../../components/AnnotationText";
import { Button } from "../../components/Button";
import { ButtonAddTeam } from "../../components/ButtonAddTeam";
import { ButtonAddTeamWrapper } from "../../components/ButtonAddTeamWrapper";
import { ButtonDefault } from "../../components/ButtonDefault";
import { ButtonHover } from "../../components/ButtonHover";
import { ButtonVariant } from "../../components/ButtonVariant";
import { ButtonVariant10 } from "../../components/ButtonVariant10";
import { ButtonVariant11 } from "../../components/ButtonVariant11";
import { ButtonVariant12 } from "../../components/ButtonVariant12";
import { ButtonVariant8 } from "../../components/ButtonVariant8";
import { ButtonVariant9 } from "../../components/ButtonVariant9";
import { ButtonVariantWrapper } from "../../components/ButtonVariantWrapper";
import { CardDesign } from "../../components/CardDesign";
import { Chair } from "../../components/Chair";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { Day } from "../../components/Day";
import { DivWrapper } from "../../components/DivWrapper";
import { Endpoint } from "../../components/Endpoint";
import { Icons } from "../../components/Icons";
import { Line } from "../../components/Line";
import { Menu } from "../../components/Menu";
import { NamecandidateNameMenuScore10001 } from "../../components/NamecandidateNameMenuScore10001";
import { NavLink } from "../../components/NavLink";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { Radio } from "../../components/Radio";
import { RadioFalse } from "../../components/RadioFalse";
import { RadioNo } from "../../components/RadioNo";
import { RadioTrue } from "../../components/RadioTrue";
import { SliderFalse } from "../../components/SliderFalse";
import { Spacer } from "../../components/Spacer";
import { Chair5 } from "../../icons/Chair5";
import { Chair9 } from "../../icons/Chair9";
import { Endpoint22 } from "../../icons/Endpoint22";
import { Endpoint23 } from "../../icons/Endpoint23";
import { Icon12 } from "../../icons/Icon12";
import { Icon13 } from "../../icons/Icon13";
import { IconLg } from "../../icons/IconLg";
import { IconQz } from "../../icons/IconQz";
import { Icons13 } from "../../icons/Icons13";
import { Icons17 } from "../../icons/Icons17";
import { Icons2 } from "../../icons/Icons2";
import { Icons25 } from "../../icons/Icons25";
import { Icons29 } from "../../icons/Icons29";
import { Icons3 } from "../../icons/Icons3";
import { Icons31 } from "../../icons/Icons31";
import { Icons35 } from "../../icons/Icons35";
import { Icons37 } from "../../icons/Icons37";
import { Icons4 } from "../../icons/Icons4";
import { Icons5 } from "../../icons/Icons5";
import { Icons9 } from "../../icons/Icons9";
import { IconsOutlineCheckmark } from "../../icons/IconsOutlineCheckmark";
import { RadioPartial1 } from "../../icons/RadioPartial1";
import { RadioYes } from "../../icons/RadioYes";
import { Star } from "../../icons/Star";
import { Star1 } from "../../icons/Star1";
import { ThirtyTwoGeneralAlert } from "../../icons/ThirtyTwoGeneralAlert";
import { TwentyArrowChevronDown2 } from "../../icons/TwentyArrowChevronDown2";
import "./style.css";

export const Frame = () => {
  return (
    <div className="frame">
      <div className="div-6">
        <div className="overlap-2">
          <div className="background">
            <div className="overlap-3">
              <div className="components">
                <div className="overlap-4">
                  <img className="line-4" alt="Line" src="/img/line-6-1.svg" />
                  <img className="line-5" alt="Line" src="/img/line-7-1.svg" />
                  <img className="line-6" alt="Line" src="/img/line-12-2.svg" />
                  <img className="line-7" alt="Line" src="/img/line-8-1.svg" />
                  <img className="line-8" alt="Line" src="/img/line-9-1.svg" />
                  <img className="line-9" alt="Line" src="/img/line-15.svg" />
                  <div className="card-design-option">
                    <div className="folder-card-4" />
                    <div className="div-7">
                      <div className="div-8">
                        <div className="div-9">
                          <div className="div-8">
                            <div className="frame-wrapper-2">
                              <div className="div-10">
                                <div className="text-wrapper-18">File Name</div>
                              </div>
                            </div>
                            <img className="star-3" alt="Star" src="/img/star-15.svg" />
                          </div>
                          <div className="div-8">
                            <div className="element-5">
                              <div className="overlap-group-6">
                                <img className="vector" alt="Vector" src="/img/vector-9.svg" />
                                <div className="element-6">
                                  <img className="group-2" alt="Group" src="/img/group-10.png" />
                                </div>
                              </div>
                            </div>
                            <div className="ellipse-12" />
                            <div className="subheading-wrapper-2">
                              <div className="subheading-3">4.3</div>
                            </div>
                            <div className="ellipse-12" />
                            <div className="supporting-text-3">a week ago</div>
                          </div>
                        </div>
                      </div>
                      <img className="more-horiz-2" alt="More horiz" src="/img/more-horiz-26.png" />
                    </div>
                  </div>
                  <div className="file-details" />
                  <div className="file-details-2">
                    <div className="div-11">
                      <div className="div-12">
                        <img className="img-8" alt="Frame" src="/img/frame-237623.png" />
                        <div className="div-13">
                          <div className="details-basic-wrapper">
                            <div className="details-basic">
                              <div className="text-wrapper-19">File Name</div>
                              <div className="div-14">
                                <div className="text-wrapper-20">2 days ago</div>
                                <div className="ellipse-13" />
                                <div className="label">
                                  <img className="file-type" alt="File type" src="/img/file-type.png" />
                                  <div className="div-14">
                                    <div className="text-2">PPT</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-14">
                            <div className="text-wrapper-21">Uploaded by:</div>
                            <div className="div-14">
                              <div className="div-15">
                                <div className="div-16">
                                  <div className="div-15">
                                    <img
                                      className="avatar-w-photo"
                                      alt="Avatar w photo"
                                      src="/img/avatar-w-photo-10.png"
                                    />
                                    <div className="text-wrapper-21">Sam Miller</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-17">
                        <div className="text-wrapper-22">Assigned to:</div>
                        <div className="frame-wrapper-3">
                          <div className="div-18">
                            <div className="outside-stroke-wrapper">
                              <div className="outside-stroke" />
                            </div>
                            <div className="avatar-w-photo-2">
                              <div className="outside-stroke" />
                            </div>
                            <div className="avatar-w-photo-3">
                              <div className="outside-stroke" />
                            </div>
                            <div className="avatar-w-photo-4">
                              <div className="outside-stroke" />
                            </div>
                            <div className="avatar-w-photo-wrapper">
                              <div className="avatar-w-photo-5">
                                <div className="outside-stroke" />
                              </div>
                            </div>
                            <div className="overflow">
                              <div className="number-2">34</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="element-notification-tab">
                    <div className="div-19">
                      <div className="title">
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-23">Notifications</div>
                        </div>
                      </div>
                      <img className="line-10" alt="Line" src="/img/line-1-5.svg" />
                    </div>
                    <div className="div-20">
                      <div className="notification">
                        <div className="content-wrapper">
                          <div className="content">
                            <div className="notification-2">
                              <div className="overlap-group-7">
                                <img className="icon-2" alt="Icon" src="/img/icon-1.png" />
                                <div className="ellipse-14" />
                              </div>
                            </div>
                            <div className="notification-content">
                              <div className="title-time">
                                <div className="title-2">Lyia S.</div>
                                <div className="time">34m ago</div>
                              </div>
                              <p className="description">shared a file with you</p>
                            </div>
                          </div>
                        </div>
                        <img className="image" alt="Image" src="/img/image-1.png" />
                      </div>
                      <div className="notification-3">
                        <div className="notification-4">
                          <div className="content-2">
                            <div className="notification-2">
                              <img className="icon-3" alt="Icon" src="/img/icon.png" />
                              <div className="ellipse-15" />
                            </div>
                            <div className="notification-content">
                              <div className="title-time">
                                <div className="title-2">Lyia S.</div>
                                <div className="time">34m ago</div>
                              </div>
                              <div className="description">added a comment</div>
                            </div>
                          </div>
                          <div className="div-wrapper-3">
                            <p className="text-wrapper-24">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam.
                            </p>
                          </div>
                        </div>
                        <div className="image-2" />
                      </div>
                    </div>
                  </div>
                  <div className="members">
                    <div className="container">
                      <div className="column">
                        <div className="table-cell">
                          <div className="avatar" />
                          <div className="div-21">
                            <div className="text-3">Olivia Rhye</div>
                            <div className="supporting-text-4">olivia@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-2" />
                          <div className="div-21">
                            <div className="text-3">Phoenix Baker</div>
                            <div className="supporting-text-4">phoenix@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-3">
                            <div className="text-4">LS</div>
                          </div>
                          <div className="div-21">
                            <div className="text-3">Lana Steiner</div>
                            <div className="supporting-text-4">lana@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-3">
                            <div className="text-4">DW</div>
                          </div>
                          <div className="div-21">
                            <div className="text-3">Demi Wilkinson</div>
                            <div className="supporting-text-4">demi@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-3">
                            <div className="text-4">NC</div>
                          </div>
                          <div className="div-21">
                            <div className="text-3">Natali Craig</div>
                            <div className="supporting-text-4">natali@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-4" />
                          <div className="div-21">
                            <div className="text-3">Candice Wu</div>
                            <div className="supporting-text-4">candice@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-5" />
                          <div className="div-21">
                            <div className="text-3">Drew Cano</div>
                            <div className="supporting-text-4">drew@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-6" />
                          <div className="div-21">
                            <div className="text-3">Orlando Diggs</div>
                            <div className="supporting-text-4">orlando@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell">
                          <div className="avatar-7" />
                          <div className="div-21">
                            <div className="text-3">Andi Lane</div>
                            <div className="supporting-text-4">andi@untitledui.com</div>
                          </div>
                        </div>
                        <div className="table-cell-2">
                          <div className="avatar-8" />
                          <div className="div-21">
                            <div className="text-3">Kate Morrison</div>
                            <div className="supporting-text-4">kate@untitledui.com</div>
                          </div>
                        </div>
                      </div>
                      <div className="column-2">
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-10.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-9.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-8.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-7.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-6.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-5.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-4.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-3.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-3">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-2.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                        <button className="table-cell-4">
                          <button className="button-base-wrapper">
                            <button className="button-base">
                              <img className="delete" alt="Delete" src="/img/delete-1.png" />
                              <div className="text-5">Remove</div>
                            </button>
                          </button>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="widget-leaderboard">
                    <div className="container-2">
                      <div className="div-12">
                        <div className="div-17">
                          <div className="div-22">
                            <div className="text-wrapper-25">Leaderboard</div>
                          </div>
                          <div className="bar">
                            <div className="text-6">Sort by</div>
                            <img className="chevron" alt="Chevron" src="/img/chevron-4.png" />
                          </div>
                        </div>
                        <img className="line-11" alt="Line" src="/img/line-1-4.svg" />
                        <div className="container-3">
                          <div className="contestant" />
                          <div className="contestant-2">
                            <div className="div-14">
                              <div className="div-23">
                                <img className="ellipse-16" alt="Ellipse" src="/img/ellipse-2-9.png" />
                              </div>
                              <div className="div-16">
                                <div className="div-15">
                                  <div className="text-wrapper-26">Sandra</div>
                                  <img className="expand-less" alt="Expand less" src="/img/expand-less-23.png" />
                                </div>
                                <div className="text-wrapper-27">1680 Points</div>
                              </div>
                            </div>
                            <div className="div-23">
                              <div className="overlap-group-8">
                                <img className="polygon" alt="Polygon" src="/img/polygon-2-12.svg" />
                                <img className="polygon-2" alt="Polygon" src="/img/polygon-1-14.svg" />
                                <div className="text-wrapper-28">2</div>
                              </div>
                            </div>
                          </div>
                          <div className="contestant-2">
                            <div className="div-14">
                              <div className="div-23">
                                <div className="overlap-group-9">
                                  <img className="ellipse-17" alt="Ellipse" src="/img/ellipse-2-8.png" />
                                  <div className="text-wrapper-29">EL</div>
                                </div>
                              </div>
                              <div className="div-16">
                                <div className="div-15">
                                  <div className="text-wrapper-26">Ellie</div>
                                  <img className="expand-less" alt="Expand less" src="/img/expand-less-22.png" />
                                </div>
                                <div className="text-wrapper-27">1640 Points</div>
                              </div>
                            </div>
                            <div className="div-23">
                              <div className="overlap-group-8">
                                <img className="polygon" alt="Polygon" src="/img/polygon-2-11.svg" />
                                <img className="polygon-2" alt="Polygon" src="/img/polygon-1-13.svg" />
                                <div className="text-wrapper-28">3</div>
                              </div>
                            </div>
                          </div>
                          <div className="contestant-3">
                            <div className="div-14">
                              <div className="div-23">
                                <div className="overlap-group-9">
                                  <img className="ellipse-17" alt="Ellipse" src="/img/ellipse-2-7.png" />
                                  <div className="ellipse-18" />
                                  <div className="text-wrapper-30">SH</div>
                                </div>
                              </div>
                              <div className="div-16">
                                <div className="div-15">
                                  <div className="text-wrapper-26">Santosh</div>
                                  <img className="expand-less" alt="Expand less" src="/img/expand-less-21.png" />
                                </div>
                                <div className="text-wrapper-27">1620 Points</div>
                              </div>
                            </div>
                            <div className="div-23">
                              <div className="text-wrapper-31">4</div>
                            </div>
                          </div>
                          <div className="contestant-2">
                            <div className="div-14">
                              <div className="div-23">
                                <img className="ellipse-19" alt="Ellipse" src="/img/ellipse-2-6.png" />
                              </div>
                              <div className="div-16">
                                <div className="div-15">
                                  <div className="text-wrapper-26">Kristine</div>
                                  <img className="expand-less" alt="Expand less" src="/img/expand-less-20.png" />
                                </div>
                                <div className="text-wrapper-27">1540 Points</div>
                              </div>
                            </div>
                            <div className="div-23">
                              <div className="text-wrapper-31">5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button-8">
                        <div className="text-wrapper-32">See all</div>
                        <img className="arrow-back-4" alt="Arrow back" src="/img/arrow-back-11.png" />
                      </div>
                    </div>
                  </div>
                  <div className="menu-2">
                    <div className="div-24">
                      <div className="nav-link-10">
                        <img className="icons-28" alt="Icons" src="/img/icons-47.png" />
                        <div className="text-wrapper-33">Dashboard</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-46.png" />
                        <div className="text-wrapper-33">Resource Center</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-45.png" />
                        <div className="text-wrapper-33">Learning Center</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-44.png" />
                        <div className="text-wrapper-33">Auditorium</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-43.png" />
                        <div className="text-wrapper-33">Meetings</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-42.png" />
                        <div className="text-wrapper-33">Quizzes</div>
                      </div>
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-41.png" />
                        <div className="text-wrapper-33">Manage</div>
                      </div>
                    </div>
                    <div className="div-25">
                      <div className="nav-link-10">
                        <img className="icons-29" alt="Icons" src="/img/icons-40.png" />
                        <div className="text-wrapper-33">Logout</div>
                      </div>
                      <div className="extra-3">
                        <div className="text-wrapper-34">Policies</div>
                        <div className="interpunct-2">
                          <div className="ellipse-20" />
                        </div>
                        <div className="text-wrapper-34">Terms &amp; Conditions</div>
                      </div>
                    </div>
                    <img className="chevron-down-3" alt="Chevron down" src="/img/chevron-down-10.png" />
                  </div>
                  <div className="div-26">
                    <div className="overlap-wrapper">
                      <div className="overlap-5">
                        <div className="overlap-group-wrapper-2">
                          <div className="overlap-group-10">
                            <img className="ellipse-21" alt="Ellipse" src="/img/ellipse-21-1.svg" />
                            <img className="ellipse-22" alt="Ellipse" src="/img/ellipse-20-1.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-35">79</div>
                      </div>
                    </div>
                    <div className="div-27">
                      <div className="div-28">
                        <div className="text-wrapper-36">Your Score</div>
                        <p className="text-wrapper-37">
                          You need to complete atleast 90% of the course content and score above 60 to download the
                          certificate
                        </p>
                      </div>
                      <div className="div-wrapper-4">
                        <div className="text-wrapper-38">Download Certifcate</div>
                      </div>
                    </div>
                  </div>
                  <div className="calendar-container">
                    <div className="change-month">
                      <div className="icon-wrapper">
                        <Icon13 className="icon-13" />
                      </div>
                      <div className="month">June 2022</div>
                      <div className="icon-12-wrapper">
                        <Icon12 className="icon-12" />
                      </div>
                    </div>
                    <div className="div-29">
                      <div className="time-picker">
                        <div className="div-30">
                          <div className="calendar-timepicker">
                            <div className="center">
                              <div className="div-31">
                                <div className="text-wrapper-39">09</div>
                                <div className="text-wrapper-40">:</div>
                                <div className="text-wrapper-40">41</div>
                              </div>
                            </div>
                          </div>
                          <div className="segmented-picker">
                            <div className="segmentedpicker">
                              <div className="label-2">AM</div>
                            </div>
                            <div className="segmentedpicker-2">
                              <div className="label-3">PM</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-4">
                      <div className="weekdays">
                        <img className="divider" alt="Divider" src="/img/divider-1.svg" />
                        <div className="div-29">
                          <Day className="day-instance" divClassName="day-2" text="Su" />
                          <Day className="day-instance" divClassName="day-2" text="Mo" />
                          <Day className="day-instance" divClassName="day-2" text="Tu" />
                          <Day className="day-instance" divClassName="day-2" text="We" />
                          <Day className="day-instance" divClassName="day-2" text="Th" />
                          <Day className="day-instance" divClassName="day-2" text="Fr" />
                          <Day className="day-instance" divClassName="day-2" text="Sa" />
                        </div>
                      </div>
                      <div className="month-2">
                        <div className="week">
                          <Day className="day-3" divClassName="day-4" text="27" />
                          <Day className="day-3" divClassName="day-4" text="28" />
                          <Day className="day-3" divClassName="day-4" text="29" />
                          <Day className="day-3" divClassName="day-4" text="30" />
                          <Day className="day-3" divClassName="day-5" text="31" />
                          <Day className="day-3" divClassName="day-5" text="1" />
                          <Day className="day-3" divClassName="day-5" text="2" />
                        </div>
                        <div className="week">
                          <Day className="day-3" divClassName="day-5" text="3" />
                          <Day className="day-3" divClassName="day-5" text="4" />
                          <Day className="day-3" divClassName="day-5" text="5" />
                          <Day className="day-6" divClassName="day-7" text="6" />
                          <Day className="day-8" divClassName="day-5" text="7" />
                          <Day className="day-8" divClassName="day-5" text="8" />
                          <Day className="day-8" divClassName="day-5" text="9" />
                        </div>
                        <div className="week">
                          <Day className="day-8" divClassName="day-5" text="10" />
                          <Day className="day-8" divClassName="day-5" text="11" />
                          <Day className="day-8" divClassName="day-5" text="12" />
                          <Day className="day-8" divClassName="day-5" text="13" />
                          <Day className="day-8" divClassName="day-5" text="14" />
                          <Day className="day-9" divClassName="day-5" text="15" />
                          <Day className="day-3" divClassName="day-5" text="16" />
                        </div>
                        <div className="week">
                          <Day className="day-3" divClassName="day-5" text="17" />
                          <Day className="day-3" divClassName="day-5" text="18" />
                          <Day className="day-3" divClassName="day-5" text="19" />
                          <Day className="day-3" divClassName="day-5" text="20" />
                          <Day className="day-3" divClassName="day-5" text="21" />
                          <Day className="day-3" divClassName="day-5" text="22" />
                          <Day className="day-3" divClassName="day-5" text="23" />
                        </div>
                        <div className="week">
                          <Day className="day-3" divClassName="day-5" text="24" />
                          <Day className="day-3" divClassName="day-5" text="25" />
                          <Day className="day-3" divClassName="day-5" text="26" />
                          <Day className="day-3" divClassName="day-5" text="27" />
                          <Day className="day-3" divClassName="day-5" text="28" />
                          <Day className="day-3" divClassName="day-5" text="29" />
                          <Day className="day-3" divClassName="day-5" text="30" />
                        </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="cancel">
                        <div className="label-4">Cancel</div>
                      </div>
                      <div className="set-date">
                        <div className="label-5">Apply</div>
                      </div>
                    </div>
                  </div>
                  <img className="line-12" alt="Line" src="/img/line-10-4.svg" />
                  <img className="line-13" alt="Line" src="/img/line-11-4.svg" />
                  <img className="line-14" alt="Line" src="/img/line-14.svg" />
                  <div className="contestant-4">
                    <div className="div-14">
                      <div className="div-23">
                        <div className="overlap-group-9">
                          <img className="ellipse-17" alt="Ellipse" src="/img/ellipse-2-5.png" />
                          <div className="ellipse-18" />
                        </div>
                      </div>
                      <div className="div-16">
                        <div className="div-15">
                          <div className="text-wrapper-26">Abhishek</div>
                          <img className="expand-less" alt="Expand less" src="/img/expand-less-19.png" />
                        </div>
                        <div className="text-wrapper-27">2000 Points</div>
                      </div>
                    </div>
                    <div className="div-23">
                      <div className="overlap-group-8">
                        <img className="polygon" alt="Polygon" src="/img/polygon-2-10.svg" />
                        <img className="polygon-2" alt="Polygon" src="/img/polygon-1-12.svg" />
                        <div className="text-wrapper-41">1</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-wrapper-4">
                    <div className="div-32">
                      <div className="div-33">
                        <div className="text-wrapper-42">What’s a poll?</div>
                        <div className="text-wrapper-43">by Adam Joesph</div>
                      </div>
                      <div className="div-22">
                        <div className="div-34">
                          <div className="checkbox-wrapper">
                            <div className="checkbox">
                              <div className="fill" />
                            </div>
                          </div>
                          <div className="text-wrapper-44">Option 1</div>
                        </div>
                        <div className="div-35">
                          <div className="checkbox-wrapper">
                            <div className="checkbox">
                              <div className="fill" />
                            </div>
                          </div>
                          <div className="text-wrapper-44">Option 2</div>
                        </div>
                      </div>
                      <div className="div-36">
                        <div className="search-bar">
                          <div className="text-wrapper-45">Skip</div>
                        </div>
                        <div className="search-bar-2">
                          <div className="text-wrapper-46">Submit</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="element-7">
                    <div className="div-37">
                      <div className="div-38">
                        <div className="div-39">
                          <div className="text-wrapper-47">Upcoming Events</div>
                          <img className="img-9" alt="Frame" src="/img/frame-25.svg" />
                        </div>
                        <img className="line-15" alt="Line" src="/img/line-12-1.svg" />
                      </div>
                      <div className="frame-wrapper-5">
                        <div className="div-24">
                          <div className="frame-wrapper-6">
                            <div className="div-40">
                              <div className="div-41">
                                <div className="div-42">
                                  <div className="div-43">
                                    <div className="text-wrapper-48">24</div>
                                    <div className="text-wrapper-49">AUG</div>
                                  </div>
                                  <div className="div-44">
                                    <div className="text-wrapper-50">Internal Sales Team Standup</div>
                                    <p className="text-wrapper-51">10:00 - 10:30 PM IST</p>
                                  </div>
                                </div>
                                <div className="div-45">
                                  <div className="text-wrapper-52">Speakers:</div>
                                  <div className="div-46">
                                    <div className="chip-speaker-3">
                                      <div className="ellipse-wrapper-2">
                                        <img className="ellipse-23" alt="Ellipse" src="/img/ellipse-1-13.png" />
                                      </div>
                                      <div className="name-3">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-4">
                                      <div className="name-4">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="div-47">
                                <div className="div-wrapper-5">
                                  <div className="text-wrapper-53">Register</div>
                                </div>
                                <img className="more-vert" alt="More vert" src="/img/more-vert-1.png" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-7">
                            <div className="div-40">
                              <div className="div-41">
                                <div className="div-42">
                                  <div className="div-43">
                                    <div className="text-wrapper-48">24</div>
                                    <div className="text-wrapper-49">AUG</div>
                                  </div>
                                  <div className="div-44">
                                    <div className="text-wrapper-50">Annual Hands-On Call</div>
                                    <p className="text-wrapper-51">10:00 - 10:30 PM IST</p>
                                  </div>
                                </div>
                                <div className="div-45">
                                  <div className="text-wrapper-52">Speakers:</div>
                                  <div className="div-46">
                                    <div className="chip-speaker-3">
                                      <div className="ellipse-wrapper-2">
                                        <img className="ellipse-23" alt="Ellipse" src="/img/ellipse-1-12.png" />
                                      </div>
                                      <div className="name-3">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-4">
                                      <div className="name-4">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="div-47">
                                <div className="div-wrapper-5">
                                  <div className="text-wrapper-53">Join Now</div>
                                </div>
                                <img className="more-vert" alt="More vert" src="/img/more-vert.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-session">
                    <div className="container-content">
                      <div className="header-create">
                        <div className="text-wrapper-54">Create Event</div>
                      </div>
                      <div className="container-input-wrapper">
                        <div className="container-input">
                          <div className="container-session">
                            <div className="text-wrapper-55">Session Type:</div>
                            <div className="container-types">
                              <div className="option-live">
                                <div className="checkbox-base-wrapper">
                                  <div className="checkbox-base" />
                                </div>
                                <div className="text-7">Live</div>
                              </div>
                              <div className="option-recorded">
                                <div className="checkbox-base-wrapper">
                                  <div className="check-wrapper">
                                    <div className="check" />
                                  </div>
                                </div>
                                <div className="text-7">Recorded</div>
                              </div>
                            </div>
                          </div>
                          <div className="input-field-title">
                            <div className="text-wrapper-56">Add Title</div>
                          </div>
                          <div className="input-field-desc">
                            <div className="input-field-text">
                              <p className="text-8">
                                <span className="span">
                                  Details about event
                                  <br />
                                </span>
                                <span className="text-wrapper-57">
                                  <br />
                                </span>
                              </p>
                            </div>
                            <div className="toolbar-text">
                              <div className="div-48">
                                <img className="icon-4" alt="Icon" src="/img/icon-11.svg" />
                                <img className="icon-5" alt="Icon" src="/img/icon-10.svg" />
                                <img className="icon-5" alt="Icon" src="/img/icon-9.svg" />
                                <img
                                  className="format-list-bulleted"
                                  alt="Format list bulleted"
                                  src="/img/format-list-bulleted.png"
                                />
                                <img
                                  className="format-list-numbered"
                                  alt="Format list numbered"
                                  src="/img/format-list-numbered.png"
                                />
                              </div>
                              <div className="label-char-limit">
                                <div className="text-wrapper-58">0/200</div>
                              </div>
                            </div>
                          </div>
                          <div className="div-49">
                            <div className="div-50">
                              <div className="text-wrapper-59">Start</div>
                              <div className="input-field-date">
                                <div className="div-51">
                                  <div className="text-wrapper-60">14 April</div>
                                  <img className="chevron-down-4" alt="Chevron down" src="/img/chevron-down-9.svg" />
                                </div>
                                <div className="input-field-time">
                                  <div className="text-wrapper-60">09:00 AM</div>
                                </div>
                              </div>
                            </div>
                            <img className="chevron-down-5" alt="Chevron down" src="/img/chevron-down-8.svg" />
                            <div className="div-50">
                              <div className="text-wrapper-59">End</div>
                              <div className="container-field">
                                <div className="div-51">
                                  <div className="text-wrapper-60">14 April</div>
                                  <img className="chevron-down-4" alt="Chevron down" src="/img/chevron-down-7.svg" />
                                </div>
                                <div className="input-field-time">
                                  <div className="text-wrapper-60">09:00 AM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-52">
                            <div className="container-timezone">
                              <div className="text-wrapper-59">Timezone</div>
                              <div className="input-field-timezone">
                                <div className="text-wrapper-61">(UTC+5:30) IST</div>
                                <img className="chevron-down-6" alt="Chevron down" src="/img/chevron-down-6.svg" />
                              </div>
                            </div>
                            <div className="div-50">
                              <div className="text-wrapper-59">Availaible to:</div>
                              <div className="input-field-timezone">
                                <div className="text-wrapper-61">Select Team or People</div>
                                <img className="chevron-down-6" alt="Chevron down" src="/img/chevron-down-5.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="div-49">
                            <div className="container-5">
                              <div className="text-wrapper-59">Speakers</div>
                              <div className="container-wrapper">
                                <div className="div-30">
                                  <div className="div-48">
                                    <div className="chip-speaker-5">
                                      <div className="div-53">
                                        <div className="div-54">
                                          <img className="ellipse-24" alt="Ellipse" src="/img/ellipse-1-11.png" />
                                        </div>
                                        <div className="name-5">Cameron</div>
                                      </div>
                                      <img className="close" alt="Close" src="/img/close-2.png" />
                                    </div>
                                    <div className="chip-speaker-5">
                                      <div className="div-53">
                                        <div className="div-54">
                                          <img className="ellipse-24" alt="Ellipse" src="/img/ellipse-1-10.png" />
                                        </div>
                                        <div className="name-5">Silviya</div>
                                      </div>
                                      <img className="close" alt="Close" src="/img/close-1.png" />
                                    </div>
                                    <div className="chip-speaker-5">
                                      <div className="div-53">
                                        <div className="div-54">
                                          <img className="ellipse-24" alt="Ellipse" src="/img/ellipse-1-9.png" />
                                        </div>
                                        <div className="name-5">Sam</div>
                                      </div>
                                      <img className="close" alt="Close" src="/img/close.png" />
                                    </div>
                                  </div>
                                  <div className="text-wrapper-62">+Add Speakers</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-embed-link">
                            <div className="text-wrapper-59">Embed Video Link (Youtube/Vimeo)</div>
                            <div className="input-field-link">
                              <div className="container-text">
                                <div className="text-wrapper-63">Add Link</div>
                              </div>
                            </div>
                          </div>
                          <div className="container-auditorium">
                            <div className="top">
                              <div className="title-3">Auditorium Image</div>
                            </div>
                            <div className="container-6">
                              <div className="content-3">
                                <img className="icon-6" alt="Icon" src="/img/icon-8.svg" />
                                <p className="paragraph">
                                  Browse and chose the files you want to upload from your computer
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="container-video">
                            <div className="record">
                              <div className="switch">
                                <div className="overlap-group-11">
                                  <div className="color-l-track" />
                                  <img className="states" alt="States" src="/img/states.png" />
                                  <img className="color-l-thumb" alt="Color l thumb" src="/img/color-l-thumb.svg" />
                                </div>
                              </div>
                              <div className="div-21">
                                <div className="text-wrapper-64">Record Meeting</div>
                              </div>
                            </div>
                            <div className="div-55">
                              <div className="div-54">
                                <div className="icons-outline">
                                  <div className="shape-wrapper">
                                    <img className="shape" alt="Shape" src="/img/shape-1.svg" />
                                  </div>
                                </div>
                              </div>
                              <div className="label-6">
                                <div className="text-wrapper-64">Enable Chat</div>
                              </div>
                            </div>
                            <div className="div-55">
                              <div className="div-54">
                                <div className="icons-outline-2">
                                  <img className="shape-2" alt="Shape" src="/img/shape.png" />
                                </div>
                              </div>
                              <div className="label-6">
                                <div className="text-wrapper-64">Enable Poll</div>
                              </div>
                            </div>
                            <div className="div-55">
                              <div className="div-54">
                                <div className="overlap-group-12">
                                  <div className="fill-2" />
                                  <img
                                    className="icons-outline-3"
                                    alt="Icons outline"
                                    src="/img/icons-outline-checkmark-1.svg"
                                  />
                                </div>
                              </div>
                              <div className="label-6">
                                <div className="text-wrapper-64">Enable Feedback</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-56">
                        <button className="CTA">
                          <div className="text-9">Cancel</div>
                        </button>
                        <button className="CTA-2">
                          <div className="text-10">Save</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="sessions-dropdown">
                    <div className="menu-items">
                      <div className="input-dropdown-menu">
                        <img className="img-10" alt="Calendar add on" src="/img/calendar-add-on-3.png" />
                        <div className="content-4">
                          <div className="text-11">Add to Calendar</div>
                        </div>
                      </div>
                      <div className="input-dropdown-menu">
                        <img className="img-10" alt="Assignment add" src="/img/assignment-add.png" />
                        <div className="content-4">
                          <div className="text-11">Asign to</div>
                        </div>
                      </div>
                      <div className="input-dropdown-menu">
                        <img className="img-10" alt="Edit" src="/img/edit-1.png" />
                        <div className="content-4">
                          <div className="text-11">Edit Event</div>
                        </div>
                      </div>
                      <div className="input-dropdown-menu">
                        <img className="img-10" alt="Delete" src="/img/delete.png" />
                        <div className="content-4">
                          <div className="text-11">Delete Event</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-57">
                    <div className="div-58">
                      <div className="text-wrapper-65">Meeting table</div>
                      <img className="dots-horizontal" alt="Dots horizontal" src="/img/arrow-back-3.png" />
                    </div>
                    <div className="div-59">
                      <div className="overlap-6">
                        <div className="overlap-7">
                          <div className="overlap-group-13">
                            <Chair9 className="chair-9" />
                            <Chair property1="default" />
                          </div>
                          <img className="table" alt="Table" src="/img/table.svg" />
                        </div>
                        <Chair5 className="chair-5" />
                      </div>
                      <Chair property1="default" />
                      <Chair property1="default" />
                      <Chair property1="default" />
                      <Chair property1="default" />
                      <Chair property1="default" />
                      <Chair property1="default" />
                      <Chair property1="default" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="gradient" />
            </div>
          </div>
          <div className="div-60">
            <div className="project-title">
              <div className="project-title-2">
                <div className="text-wrapper-66">Chipsoft - 2022</div>
                <p className="internal-SAAS-app">
                  Internal SAAS app for resource, collaboration <br />
                  and skill management
                </p>
              </div>
              <div className="scope-of-work">
                <div className="text-wrapper-67">Scope of Work</div>
                <div className="labels">
                  <div className="UIX">
                    <div className="frame-wrapper-8">
                      <div className="div-61">
                        <div className="group-3">
                          <div className="overlap-group-14">
                            <img className="line-16" alt="Line" src="/img/line-18.svg" />
                            <img className="line-17" alt="Line" src="/img/line-19.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-68">UI/UX</div>
                      </div>
                    </div>
                  </div>
                  <div className="UIX-2">
                    <div className="frame-wrapper-8">
                      <div className="div-61">
                        <div className="group-3">
                          <div className="overlap-group-14">
                            <img className="line-16" alt="Line" src="/img/line-18.svg" />
                            <img className="line-17" alt="Line" src="/img/line-19.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-68">Webapp</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-info">
              <div className="text-wrapper-69">SAAS</div>
              <div className="text-wrapper-70">IT Services, Healthcare</div>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="container-7">
            <div className="about-the-client">
              <div className="text-wrapper-71">About the client</div>
              <p className="text-wrapper-72">
                Chipsoft is a global software house - headquartered in the Netherlands and servicing businesses all over
                Europe and Asia with a proven expertise and history of over 35+ years, providing IT consulting, building
                and developing cutting-edge software solutions for local and international clients all over the globe.
              </p>
            </div>
            <div className="the-challenge">
              <div className="text-wrapper-73">The Challenge</div>
              <p className="text-wrapper-74">
                A little while ago, not so long ago Chipsoft’s team reached out to us to help navigate, define and build
                a solid internal platform rooted in the intricacies of a strong user experience and a robust User
                Interface. That allows modern enterprises and businesses to manage and store their resources, skill
                development, and run virtual events and much more - All in one platform.
              </p>
            </div>
          </div>
        </div>
        <div className="overlap-8">
          <div className="overlap-9">
            <div className="section-wireframes">
              <div className="resources-drafts">
                <div className="overlap-10">
                  <div className="rectangle-3" />
                  <div className="rectangle-4" />
                  <div className="div-62">
                    <div className="frame-wrapper-9">
                      <div className="div-wrapper-6">
                        <div className="text-wrapper-75">Based on your skills</div>
                      </div>
                    </div>
                    <div className="frame-wrapper-10">
                      <div className="div-63">
                        <div className="frame-wrapper-11">
                          <div className="div-11">
                            <div className="more-horiz-wrapper">
                              <img className="img-11" alt="More horiz" src="/img/more-horiz-25.png" />
                            </div>
                            <div className="frame-wrapper-12">
                              <div className="frame-wrapper-13">
                                <div className="text-and-supporting-wrapper-2">
                                  <div className="text-and-supporting-2">
                                    <div className="div-64">
                                      <div className="text-12">File Name</div>
                                      <div className="div-wrapper-7">
                                        <div className="text-wrapper-76">NEW</div>
                                      </div>
                                      <img className="img-12" alt="Star" src="/img/star-14.svg" />
                                    </div>
                                    <div className="div-65">
                                      <div className="element-8">
                                        <div className="overlap-group-15">
                                          <img className="img-13" alt="Vector" src="/img/vector-8.png" />
                                          <div className="element-9">
                                            <img className="img-13" alt="Group" src="/img/group-9.png" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ellipse-25" />
                                      <div className="supporting-text-5">a week ago</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-14">
                          <div className="frame-wrapper-15">
                            <div className="div-17">
                              <div className="text-wrapper-77">Sales</div>
                              <img className="img-11" alt="More horiz" src="/img/more-horiz-24.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-16">
                          <div className="frame-wrapper-17">
                            <div className="div-11">
                              <div className="name-date">
                                <p className="text-13">Vmware CEO reveals big changes amid multi-cloud shift</p>
                                <div className="div-66">
                                  <div className="text-wrapper-78">10 Dec 2020</div>
                                  <div className="text-wrapper-78">10.30 PM IST</div>
                                </div>
                              </div>
                              <div className="div-67">
                                <div className="text-wrapper-79">Speakers:</div>
                                <div className="div-65">
                                  <div className="chip-speaker-6">
                                    <div className="div-68" />
                                    <div className="name-6">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-7">
                                    <div className="div-69" />
                                    <div className="name-6">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="name-7">+2 more</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-18">
                          <div className="frame-wrapper-17">
                            <div className="div-11">
                              <div className="name-date">
                                <p className="text-14">Vmware CEO reveals big changes amid multi-cloud shift</p>
                                <div className="div-66">
                                  <div className="text-wrapper-78">10 Dec 2020</div>
                                  <div className="text-wrapper-78">10.30 PM IST</div>
                                </div>
                              </div>
                              <div className="div-67">
                                <div className="text-wrapper-79">Speakers:</div>
                                <div className="div-18">
                                  <div className="chip-speaker-9">
                                    <div className="div-70" />
                                    <div className="name-8">Cameron</div>
                                  </div>
                                  <div className="chip-speaker-7">
                                    <div className="div-71" />
                                    <div className="name-9">Sam</div>
                                  </div>
                                  <div className="chip-speaker-8">
                                    <div className="name-10">+2 more</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="line-18" alt="Line" src="/img/line-3-3.svg" />
                  <div className="div-72">
                    <div className="div-73">
                      <div className="div-74">
                        <div className="div-75">
                          <div className="div-wrapper-8">
                            <div className="text-wrapper-80">All Files</div>
                          </div>
                          <div className="div-wrapper-9">
                            <div className="text-wrapper-81">Assigned to Me</div>
                          </div>
                          <div className="div-wrapper-10">
                            <div className="text-wrapper-81">Favorites</div>
                          </div>
                        </div>
                        <div className="div-76">
                          <div className="text-input">
                            <img className="img-14" alt="Search" src="/img/search-3.svg" />
                            <div className="value">Search</div>
                          </div>
                          <div className="frame-wrapper-19">
                            <div className="div-77">
                              <img className="img-14" alt="Add" src="/img/add-14.png" />
                              <div className="text-wrapper-82">New</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img className="line-19" alt="Line" src="/img/line-2-3.svg" />
                    </div>
                    <div className="frame-wrapper-20">
                      <div className="div-78">
                        <div className="dropdown">
                          <div className="div-79">
                            <div className="text-wrapper-83">Sort by:</div>
                            <div className="div-80">
                              <div className="text-wrapper-84">Recently Added</div>
                              <img className="img-12" alt="Chevron right" src="/img/chevron-right-1.svg" />
                            </div>
                          </div>
                          <img className="img-15" alt="Frame" src="/img/frame-472.svg" />
                        </div>
                        <div className="div-81">
                          <div className="div-82">
                            <div className="frame-wrapper-21">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="img-11" alt="More horiz" src="/img/more-horiz-23.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-22">
                                    <div className="text-and-supporting-wrapper-3">
                                      <div className="text-and-supporting-3">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="img-12" alt="Star" src="/img/star-13.svg" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-16" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-16" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-wrapper-23">
                              <div className="frame-wrapper-15">
                                <div className="div-17">
                                  <div className="text-wrapper-77">Sales</div>
                                  <img className="img-11" alt="More horiz" src="/img/more-horiz-22.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-82">
                            <div className="frame-wrapper-21">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="img-11" alt="More horiz" src="/img/more-horiz-21.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-13">
                                    <div className="text-and-supporting-wrapper-2">
                                      <div className="text-and-supporting-2">
                                        <div className="div-65">
                                          <div className="text-12">File Name</div>
                                          <img className="img-12" alt="Star" src="/img/star-12.svg" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-17" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-17" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-wrapper-21">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="img-11" alt="More horiz" src="/img/more-horiz-20.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-13">
                                    <div className="text-and-supporting-wrapper-2">
                                      <div className="text-and-supporting-2">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="img-12" alt="Star" src="/img/star-11.svg" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <div className="overlap-group-15">
                                              <img className="img-13" alt="Vector" src="/img/vector-5.svg" />
                                              <div className="element-9">
                                                <img className="img-13" alt="Group" src="/img/group-6.png" />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-82">
                            <div className="frame-wrapper-21">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="more-horiz-3" alt="More horiz" src="/img/arrow-back-3.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-13">
                                    <div className="text-and-supporting-wrapper-2">
                                      <div className="text-and-supporting-2">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="star-4" alt="Star" src="/img/arrow-back-3.png" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-18" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-18" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-wrapper-21">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="more-horiz-4" alt="More horiz" src="/img/arrow-back-3.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-13">
                                    <div className="text-and-supporting-wrapper-2">
                                      <div className="text-and-supporting-2">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="star-5" alt="Star" src="/img/arrow-back-3.png" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-19" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-19" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-82">
                            <div className="frame-wrapper-24">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="more-horiz-5" alt="More horiz" src="/img/arrow-back-3.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-25">
                                    <div className="text-and-supporting-wrapper-4">
                                      <div className="text-and-supporting-3">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="star-6" alt="Star" src="/img/arrow-back-3.png" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-20" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-20" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-wrapper-24">
                              <div className="div-11">
                                <div className="more-horiz-wrapper">
                                  <img className="more-horiz-6" alt="More horiz" src="/img/arrow-back-3.png" />
                                </div>
                                <div className="frame-wrapper-12">
                                  <div className="frame-wrapper-25">
                                    <div className="text-and-supporting-wrapper-4">
                                      <div className="text-and-supporting-3">
                                        <div className="div-64">
                                          <div className="text-12">File Name</div>
                                          <div className="div-wrapper-7">
                                            <div className="text-wrapper-76">NEW</div>
                                          </div>
                                          <img className="star-7" alt="Star" src="/img/arrow-back-3.png" />
                                        </div>
                                        <div className="div-65">
                                          <div className="element-8">
                                            <img className="img-21" alt="Vector" src="/img/arrow-back-3.png" />
                                            <div className="element-9">
                                              <img className="img-21" alt="Group" src="/img/arrow-back-3.png" />
                                            </div>
                                          </div>
                                          <div className="ellipse-25" />
                                          <div className="supporting-text-5">a week ago</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-wrapper-26">
                    <div className="frame-wrapper-27">
                      <div className="div-11">
                        <div className="div-83">
                          <div className="div-17">
                            <div className="div-wrapper-11">
                              <div className="text-wrapper-85">Leaderboard</div>
                            </div>
                            <div className="bar-2">
                              <div className="text-15">Sort by</div>
                              <img className="chevron-2" alt="Chevron" src="/img/chevron-3.svg" />
                            </div>
                          </div>
                          <img className="line-20" alt="Line" src="/img/line-1-3.svg" />
                          <div className="div-84">
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87">
                                  <div className="ellipse-26" />
                                </div>
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Sam</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-18.png" />
                                  </div>
                                  <div className="text-wrapper-87">2000 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="overlap-group-16">
                                  <img className="polygon-3" alt="Polygon" src="/img/polygon-1-11.svg" />
                                  <div className="text-wrapper-88">1</div>
                                </div>
                              </div>
                            </div>
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87" />
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Sandra</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-17.png" />
                                  </div>
                                  <div className="text-wrapper-89">1680 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="overlap-group-17">
                                  <img className="polygon-3" alt="Polygon" src="/img/polygon-1-10.svg" />
                                  <div className="text-wrapper-90">2</div>
                                </div>
                              </div>
                            </div>
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87">
                                  <div className="text-wrapper-91">EL</div>
                                </div>
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Ellie</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-16.png" />
                                  </div>
                                  <div className="text-wrapper-89">1640 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="overlap-group-18">
                                  <img className="polygon-3" alt="Polygon" src="/img/polygon-1-9.svg" />
                                  <div className="text-wrapper-90">3</div>
                                </div>
                              </div>
                            </div>
                            <div className="div-90">
                              <div className="div-86">
                                <div className="div-87">
                                  <div className="ellipse-26" />
                                </div>
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Santosh</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-15.png" />
                                  </div>
                                  <div className="text-wrapper-89">1620 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="text-wrapper-92">4</div>
                              </div>
                            </div>
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87" />
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Kristine</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-14.png" />
                                  </div>
                                  <div className="text-wrapper-89">1540 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="text-wrapper-92">5</div>
                              </div>
                            </div>
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87" />
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Robert</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-13.png" />
                                  </div>
                                  <div className="text-wrapper-89">1540 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="text-wrapper-92">6</div>
                              </div>
                            </div>
                            <div className="div-85">
                              <div className="div-86">
                                <div className="div-87" />
                                <div className="div-88">
                                  <div className="div-89">
                                    <div className="text-wrapper-86">Pauline</div>
                                    <img className="expand-less-2" alt="Expand less" src="/img/expand-less-12.png" />
                                  </div>
                                  <div className="text-wrapper-89">1540 Points</div>
                                </div>
                              </div>
                              <div className="div-wrapper-12">
                                <div className="text-wrapper-92">7</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div-91">
                          <div className="text-wrapper-93">See all</div>
                          <img className="arrow-back-5" alt="Arrow back" src="/img/arrow-back-10.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-92">
                    <div className="div-93">
                      <div className="div-wrapper-6">
                        <div className="text-wrapper-94">Resources</div>
                      </div>
                      <div className="div-94">
                        <div className="div-wrapper-13">
                          <div className="text-wrapper-95">Share</div>
                        </div>
                        <img className="icon-button-2" alt="Icon button" src="/img/icon-button-5.svg" />
                      </div>
                    </div>
                    <img className="img-11" alt="Frame" src="/img/frame-458-1.svg" />
                  </div>
                  <div className="div-95">
                    <div className="div-21">
                      <div className="div-96">
                        <img className="img-22" alt="Frame" src="/img/arrow-back-3.png" />
                        <div className="text-wrapper-96">Dashboard</div>
                      </div>
                      <div className="div-97">
                        <img className="img-23" alt="Frame" src="/img/arrow-back-3.png" />
                        <div className="text-wrapper-96">Resource Center</div>
                      </div>
                      <div className="div-98">
                        <div className="img-11">
                          <div className="rectangle-5" />
                          <img className="polygon-4" alt="Polygon" src="/img/arrow-back-3.png" />
                        </div>
                        <div className="text-wrapper-96">Learning Center</div>
                      </div>
                      <div className="div-96">
                        <img className="img-24" alt="Frame" src="/img/arrow-back-3.png" />
                        <div className="text-wrapper-96">Auditoriums</div>
                      </div>
                      <div className="div-98">
                        <div className="img-11">
                          <div className="group-4">
                            <div className="overlap-group-19">
                              <div className="ellipse-27" />
                              <div className="ellipse-28" />
                              <div className="ellipse-29" />
                              <div className="ellipse-30" />
                            </div>
                          </div>
                        </div>
                        <div className="text-wrapper-96">Meetings</div>
                      </div>
                      <div className="div-98">
                        <div className="img-11">
                          <div className="group-5" />
                        </div>
                        <div className="text-wrapper-96">Quizzes</div>
                      </div>
                    </div>
                    <div className="div-99">
                      <div className="div-100">
                        <div className="div-101">
                          <div className="text-wrapper-97">People</div>
                          <img className="arrow-drop-down" alt="Arrow drop down" src="/img/arrow-back-3.png" />
                        </div>
                        <div className="div-102">
                          <div className="div-100">
                            <div className="frame-wrapper-28">
                              <div className="div-65">
                                <div className="text-wrapper-98">Marketing</div>
                                <img className="arrow-drop-down-2" alt="Arrow drop down" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                            <img className="img-25" alt="Frame" src="/img/arrow-back-3.png" />
                          </div>
                          <div className="div-100">
                            <div className="frame-wrapper-28">
                              <div className="div-65">
                                <div className="text-wrapper-98">Sales</div>
                                <img className="arrow-drop-down-3" alt="Arrow drop down" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                            <img className="img-26" alt="Frame" src="/img/arrow-back-3.png" />
                          </div>
                        </div>
                      </div>
                      <div className="div-wrapper-14">
                        <div className="text-wrapper-99">Add Team</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-103">
                  <div className="div-wrapper-15">
                    <div className="text-wrapper-100">LOGO</div>
                  </div>
                  <div className="div-104">
                    <img className="img-11" alt="Icon" src="/img/icon-7.svg" />
                    <img className="img-11" alt="Notifications" src="/img/notifications-4.svg" />
                    <div className="div-105">
                      <div className="ellipse-wrapper-3">
                        <div className="ellipse-31" />
                      </div>
                      <div className="text-wrapper-101">Santosh K</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="resources-user-view">
                <div className="overlap-11">
                  <div className="rectangle-6" />
                  <div className="div-106">
                    <div className="div-107">
                      <div className="text-wrapper-102">Assigned to</div>
                      <div className="subtitle-wrapper">
                        <div className="subtitle">39 members</div>
                      </div>
                    </div>
                    <div className="frame-wrapper-29">
                      <div className="frame-wrapper-30">
                        <div className="div-17">
                          <div className="div-108">
                            <div className="avatar-w-photo-6">
                              <div className="outside-stroke-2" />
                            </div>
                            <div className="avatar-w-photo-7">
                              <div className="outside-stroke-2" />
                            </div>
                            <div className="avatar-w-photo-8">
                              <div className="outside-stroke-2" />
                            </div>
                            <div className="avatar-w-photo-9">
                              <div className="outside-stroke-2" />
                            </div>
                            <div className="avatar-w-photo-wrapper-2">
                              <div className="avatar-w-photo-10">
                                <div className="outside-stroke-2" />
                              </div>
                            </div>
                            <div className="number-wrapper">
                              <div className="number-3">34</div>
                            </div>
                          </div>
                          <div className="frame-wrapper-31">
                            <div className="div-109">
                              <img className="add-5" alt="Add" src="/img/add-13.png" />
                              <div className="text-wrapper-82">Add</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comments">
                    <div className="div-110">
                      <div className="title-wrapper">
                        <div className="title-4">Comments</div>
                      </div>
                      <div className="div-111">
                        <img className="thread-line" alt="Thread line" src="/img/thread-line-1.svg" />
                        <div className="div-112">
                          <div className="comment">
                            <img className="avatar-9" alt="Avatar" src="/img/avatar-5.png" />
                            <div className="content-5">
                              <p className="paragraph-2">
                                Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus
                                Lorem ipsum dolor sit amet aliquam, purus sit amet luctus
                              </p>
                              <div className="div-17">
                                <div className="text-wrapper-103">2 mins</div>
                                <div className="settings">
                                  <div className="group-6">
                                    <div className="ellipse-32" />
                                    <div className="ellipse-33" />
                                    <div className="ellipse-34" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dropdown-2">
                            <div className="label-wrapper">
                              <div className="label-7">View all 124 comments</div>
                            </div>
                            <img className="icons-30" alt="Icons" src="/img/icons-39.svg" />
                          </div>
                        </div>
                        <div className="div-112">
                          <div className="comment">
                            <img className="avatar-9" alt="Avatar" src="/img/avatar-4.png" />
                            <div className="content-5">
                              <p className="paragraph-2">
                                Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                              </p>
                              <div className="div-17">
                                <div className="text-wrapper-103">15 mins</div>
                                <div className="settings">
                                  <div className="group-6">
                                    <div className="ellipse-32" />
                                    <div className="ellipse-33" />
                                    <div className="ellipse-34" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dropdown-2">
                            <div className="label-wrapper">
                              <div className="label-7">View all 124 comments</div>
                            </div>
                            <img className="icons-30" alt="Icons" src="/img/icons-38.svg" />
                          </div>
                        </div>
                        <div className="comment-2">
                          <img className="avatar-9" alt="Avatar" src="/img/avatar-3.png" />
                          <div className="content-5">
                            <p className="paragraph-2">
                              Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam,
                            </p>
                            <div className="div-17">
                              <div className="text-wrapper-103">15 mins</div>
                              <div className="settings">
                                <div className="group-6">
                                  <div className="ellipse-32" />
                                  <div className="ellipse-33" />
                                  <div className="ellipse-34" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="input-field">
                      <div className="search-bar-3">
                        <div className="text-wrapper-104">Start typing...</div>
                      </div>
                      <div className="CTA-3">
                        <div className="text-wrapper-105">Send</div>
                      </div>
                    </div>
                    <div className="slider">
                      <div className="rectangle-7" />
                    </div>
                  </div>
                  <div className="div-113">
                    <div className="div-65">
                      <div className="div-wrapper-16">
                        <div className="text-wrapper-106">File Name</div>
                      </div>
                      <img className="img-27" alt="Icon button" src="/img/icon-button-4.svg" />
                    </div>
                    <div className="div-114">
                      <div className="div-wrapper-13">
                        <div className="text-wrapper-95">Share</div>
                      </div>
                      <img className="icon-button-3" alt="Icon button" src="/img/icon-button-3.svg" />
                    </div>
                  </div>
                  <div className="rectangle-8" />
                  <img className="line-21" alt="Line" src="/img/line-2-2.svg" />
                  <img className="line-22" alt="Line" src="/img/line-3-2.svg" />
                  <div className="div-115">
                    <div className="div-116">
                      <img className="img-27" alt="Frame" src="/img/frame-5-5.svg" />
                      <div className="text-wrapper-96">Dashboard</div>
                    </div>
                    <div className="div-117">
                      <img className="img-27" alt="Frame" src="/img/frame-5-4.svg" />
                      <div className="text-wrapper-96">Resource Center</div>
                    </div>
                    <div className="div-116">
                      <div className="img-27">
                        <div className="polygon-wrapper">
                          <img className="polygon-5" alt="Polygon" src="/img/polygon-1-7.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper-96">Learning Center</div>
                    </div>
                    <div className="div-116">
                      <img className="img-27" alt="Frame" src="/img/frame-5-3.svg" />
                      <div className="text-wrapper-96">Auditoriums</div>
                    </div>
                    <div className="div-116">
                      <div className="img-27">
                        <div className="group-4">
                          <div className="overlap-group-19">
                            <div className="ellipse-27" />
                            <div className="ellipse-28" />
                            <div className="ellipse-29" />
                            <div className="ellipse-30" />
                          </div>
                        </div>
                      </div>
                      <div className="text-wrapper-96">Meetings</div>
                    </div>
                  </div>
                  <div className="div-118">
                    <div className="top-2">
                      <div className="title-5">About</div>
                      <p className="paragraph-3">
                        Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam, purus sit amet luctus Lorem ipsum
                        dolor sit amet aliquam, purus sit amet luctus
                      </p>
                    </div>
                    <div className="div-65">
                      <div className="text-wrapper-107">File Type:</div>
                      <div className="div-108">
                        <div className="div-18">
                          <div className="base-status-badge">
                            <div className="text-wrapper-108">PDF</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-119">
                  <div className="div-wrapper-15">
                    <div className="text-wrapper-100">LOGO</div>
                  </div>
                  <div className="div-104">
                    <img className="img-27" alt="Icon" src="/img/icon-6.svg" />
                    <img className="img-27" alt="Notifications" src="/img/notifications-3.svg" />
                    <div className="div-105">
                      <div className="ellipse-wrapper-4">
                        <div className="ellipse-31" />
                      </div>
                      <div className="text-wrapper-101">Santosh K</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="speaker">
                <div className="overlap-12">
                  <div className="rectangle-9" />
                  <div className="div-120" />
                  <div className="div-121">
                    <div className="frame-wrapper-32">
                      <div className="div-48">
                        <img className="div-54" alt="Arrow back" src="/img/arrow-back-9.png" />
                      </div>
                    </div>
                    <div className="heading-and">
                      <div className="heading-and-text">
                        <div className="heading-and-icon">
                          <div className="heading">Annual Sales Team Meetup</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-122">
                    <div className="overlap-13">
                      <div className="rectangle-10" />
                      <div className="arrow-back-ios-new-wrapper">
                        <img
                          className="arrow-back-ios-new"
                          alt="Arrow back ios new"
                          src="/img/arrow-back-ios-new-1.png"
                        />
                      </div>
                      <img className="dots-horizontal-2" alt="Dots horizontal" src="/img/dots-horizontal-3.svg" />
                    </div>
                    <div className="dots-horizontal-wrapper">
                      <img className="dots-horizontal-3" alt="Dots horizontal" src="/img/dots-horizontal-5.svg" />
                    </div>
                    <div className="overlap-14">
                      <div className="rectangle-11" />
                      <img className="dots-horizontal-3" alt="Dots horizontal" src="/img/dots-horizontal-4.svg" />
                      <div className="arrow-back-ios-new-wrapper-2">
                        <img
                          className="arrow-back-ios-new-2"
                          alt="Arrow back ios new"
                          src="/img/arrow-back-ios-new-2.png"
                        />
                      </div>
                    </div>
                    <div className="div-123">
                      <div className="div-124">
                        <div className="ellipse-wrapper-5">
                          <img className="ellipse-35" alt="Ellipse" src="/img/ellipse-123-3.png" />
                        </div>
                        <div className="text-wrapper-109">Adam Joseph</div>
                      </div>
                      <div className="text-wrapper-110">Speaker</div>
                    </div>
                    <div className="div-125">
                      <div className="div-124">
                        <div className="ellipse-wrapper-5">
                          <img className="ellipse-35" alt="Ellipse" src="/img/ellipse-123-2.png" />
                        </div>
                        <div className="text-wrapper-109">Adam Joseph</div>
                      </div>
                      <div className="text-wrapper-110">Speaker</div>
                    </div>
                    <div className="div-126">
                      <div className="div-124">
                        <div className="ellipse-wrapper-5">
                          <img className="ellipse-35" alt="Ellipse" src="/img/ellipse-123-1.png" />
                        </div>
                        <div className="text-wrapper-109">Adam Joseph</div>
                      </div>
                      <div className="text-wrapper-110">Speaker</div>
                    </div>
                  </div>
                  <div className="div-127">
                    <div className="div-128">
                      <div className="div-129">
                        <div className="ellipse-wrapper-6">
                          <img className="ellipse-35" alt="Ellipse" src="/img/ellipse-123.png" />
                        </div>
                        <div className="text-wrapper-111">Adam Joseph</div>
                      </div>
                      <div className="div-wrapper-17">
                        <div className="text-wrapper-112">Senior Manager - Sales</div>
                      </div>
                    </div>
                    <div className="div-wrapper-18">
                      <div className="text-wrapper-113">Speaker</div>
                    </div>
                  </div>
                </div>
                <div className="toolbar">
                  <div className="frame-wrapper-33">
                    <div className="div-130">
                      <img className="img-28" alt="Call end" src="/img/call-end.png" />
                    </div>
                  </div>
                  <div className="keyboard-voice-wrapper">
                    <img className="keyboard-voice" alt="Keyboard voice" src="/img/keyboard-voice.png" />
                  </div>
                  <div className="frame-wrapper-34">
                    <div className="div-130">
                      <img className="img-28" alt="Videocam off" src="/img/videocam-off.png" />
                    </div>
                  </div>
                  <div className="frame-wrapper-34">
                    <div className="div-130">
                      <img className="img-28" alt="Group" src="/img/group-1.png" />
                    </div>
                  </div>
                  <div className="frame-wrapper-34">
                    <div className="div-130">
                      <div className="overlap-group-20">
                        <img className="chat-bubble" alt="Chat bubble" src="/img/chat-bubble.png" />
                        <div className="div-wrapper-19">
                          <div className="text-wrapper-114">1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="segmentedpicker-2" alt="Frame" src="/img/frame-40129.svg" />
                </div>
                <div className="div-131">
                  <div className="overlap-group-wrapper-3">
                    <div className="overlap-group-21">
                      <div className="ellipse-36" />
                    </div>
                  </div>
                  <div className="div-132">
                    <div className="text-wrapper-115">Recording..</div>
                    <div className="text-wrapper-115">40:00</div>
                  </div>
                </div>
              </div>
              <div className="course-details">
                <div className="div-133">
                  <div className="frame-wrapper-35">
                    <div className="arrow-back-wrapper">
                      <img className="arrow-back-6" alt="Arrow back" src="/img/arrow-back-8.png" />
                    </div>
                  </div>
                  <div className="div-134">
                    <div className="div-wrapper-20">
                      <p className="text-wrapper-116">Introduction to Responsive Web Development with HTML and CSS</p>
                    </div>
                    <div className="content-6">
                      <div className="avatar-10" />
                      <div className="name-11">Cameron Williamson</div>
                    </div>
                  </div>
                </div>
                <div className="container-menu">
                  <div className="navlink">
                    <div className="text-wrapper-117">Overview</div>
                  </div>
                  <div className="navlink">
                    <div className="text-wrapper-117">Content</div>
                  </div>
                  <div className="navlink-2">
                    <div className="text-wrapper-117">Q &amp; A</div>
                  </div>
                  <div className="navlink-2">
                    <div className="text-wrapper-117">Note</div>
                  </div>
                  <div className="navlink-3">
                    <div className="text-wrapper-118">Certification</div>
                  </div>
                </div>
                <div className="frame-wrapper-36">
                  <div className="div-24">
                    <div className="div-135">
                      <div className="overlap-wrapper-2">
                        <div className="overlap-15">
                          <div className="overlap-group-wrapper-4">
                            <div className="overlap-group-22">
                              <img className="ellipse-37" alt="Ellipse" src="/img/ellipse-21.svg" />
                              <img className="ellipse-38" alt="Ellipse" src="/img/ellipse-20.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-119">79</div>
                        </div>
                      </div>
                      <div className="div-136">
                        <div className="div-137">
                          <div className="text-wrapper-120">Your Score</div>
                          <p className="text-wrapper-121">
                            You need to complete atleast 90% of the course content and score above 60 to download the
                            certificate
                          </p>
                        </div>
                        <div className="div-wrapper-21">
                          <div className="text-wrapper-122">Download Certifcate</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="video-player">
                  <div className="div-138">
                    <img className="pause" alt="Pause" src="/img/pause.svg" />
                    <div className="overlap-group-wrapper-5">
                      <div className="overlap-group-23">
                        <div className="buffering">
                          <div className="buffering-progress" />
                        </div>
                        <div className="progress-line" />
                      </div>
                    </div>
                  </div>
                  <div className="div-139">
                    <div className="div-wrapper-22">
                      <div className="text-wrapper-123">Take Notes</div>
                    </div>
                    <div className="open-in-full-wrapper">
                      <img className="open-in-full" alt="Open in full" src="/img/open-in-full.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="auditorium-sessions">
                <div className="overlap-16">
                  <div className="base" />
                  <div className="header">
                    <div className="text-wrapper-124">Events</div>
                    <div className="div-140">
                      <div className="text-input-2">
                        <div className="div-141">
                          <img className="search" alt="Search" src="/img/search-2.png" />
                          <div className="value-2">Search</div>
                        </div>
                        <img className="img-14" alt="Tune" src="/img/tune-1.png" />
                      </div>
                      <div className="holder-wrapper">
                        <div className="holder">
                          <img className="img-14" alt="Add" src="/img/add-12.png" />
                          <div className="text-wrapper-125">Create Event</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-142">
                    <div className="nav-bar">
                      <div className="div-17">
                        <div className="div-108">
                          <div className="nav-link-11">
                            <div className="text-wrapper-126">Scheduled</div>
                          </div>
                          <div className="nav-link-12">
                            <div className="text-wrapper-127">Completed</div>
                          </div>
                          <div className="nav-link-12">
                            <div className="text-wrapper-127">Drafts</div>
                          </div>
                          <div className="nav-link-13">
                            <div className="text-wrapper-127">Assigned to me</div>
                          </div>
                          <div className="nav-link-12">
                            <div className="text-wrapper-127">Favourites</div>
                          </div>
                          <div className="nav-link-14">
                            <div className="text-wrapper-128">All</div>
                          </div>
                        </div>
                        <div className="div-143">
                          <img className="img-14" alt="Download" src="/img/download-1.png" />
                          <div className="text-wrapper-129">Download</div>
                        </div>
                      </div>
                    </div>
                    <div className="div-144">
                      <div className="dropdown-wrapper">
                        <div className="dropdown">
                          <div className="div-79">
                            <div className="text-wrapper-130">Sort by:</div>
                            <div className="div-80">
                              <div className="text-wrapper-131">Recently Added</div>
                              <img className="img-12" alt="Chevron right" src="/img/chevron-right.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-events">
                        <div className="event-2">
                          <div className="div-17">
                            <div className="element-10">
                              <div className="div-wrapper-23">
                                <div className="div-wrapper-23">
                                  <div className="live">
                                    <div className="text-wrapper-132">LIVE</div>
                                  </div>
                                </div>
                              </div>
                              <div className="holder-2">
                                <div className="div-100">
                                  <div className="div-141">
                                    <p className="text-wrapper-133">
                                      Vmware CEO reveals big changes amid multi-cloud shift
                                    </p>
                                    <img className="img-14" alt="Star" src="/img/star-6.svg" />
                                  </div>
                                  <div className="div-66">
                                    <div className="div-66">
                                      <div className="text-wrapper-134">10 Dec 2020,</div>
                                      <p className="text-wrapper-78">10.30 PM - 11.30 AM IST</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="speakers-4">
                                  <div className="text-wrapper-135">Speakers:</div>
                                  <div className="div-18">
                                    <div className="chip-speaker-9">
                                      <div className="div-145" />
                                      <div className="name-12">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Sam</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Cory</div>
                                    </div>
                                    <div className="chip-speaker-10">
                                      <div className="name-14">+3</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div-147">
                              <div className="div-148">
                                <div className="div-104">
                                  <div className="calendar-add-on-wrapper">
                                    <img
                                      className="calendar-add-on"
                                      alt="Calendar add on"
                                      src="/img/calendar-add-on-2.png"
                                    />
                                  </div>
                                  <button className="CTA-4">
                                    <div className="text-wrapper-136">View Details</div>
                                  </button>
                                  <button className="CTA-5">
                                    <div className="text-wrapper-137">Register</div>
                                  </button>
                                </div>
                                <img className="img-11" alt="Dots horizontal" src="/img/dots-horizontal-2.png" />
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-135">Attending:</div>
                                <div className="attending">
                                  <div className="avatar-w-photo-11" />
                                  <div className="avatar-w-photo-12" />
                                  <div className="avatar-w-photo-13" />
                                  <div className="avatar-w-photo-14">
                                    <div className="text-wrapper-138">120</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="event-2">
                          <div className="div-17">
                            <div className="element-10">
                              <div className="div-wrapper-23">
                                <div className="div-wrapper-23">
                                  <div className="live">
                                    <div className="text-wrapper-132">SCHEDULED</div>
                                  </div>
                                </div>
                              </div>
                              <div className="holder-2">
                                <div className="div-100">
                                  <div className="div-141">
                                    <div className="text-wrapper-133">Annual Sales Meetup 2022</div>
                                    <img className="img-14" alt="Star" src="/img/star-5.svg" />
                                  </div>
                                  <div className="div-66">
                                    <div className="div-66">
                                      <div className="text-wrapper-134">10 Dec 2020,</div>
                                      <p className="text-wrapper-78">10.30 PM - 11.30 AM IST</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="speakers-4">
                                  <div className="text-wrapper-135">Speakers:</div>
                                  <div className="div-18">
                                    <div className="chip-speaker-9">
                                      <div className="div-145" />
                                      <div className="name-12">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Sam</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Cory</div>
                                    </div>
                                    <div className="chip-speaker-10">
                                      <div className="name-14">+3</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div-147">
                              <div className="div-148">
                                <div className="div-104">
                                  <div className="calendar-add-on-wrapper">
                                    <img
                                      className="calendar-add-on"
                                      alt="Calendar add on"
                                      src="/img/calendar-add-on-1.png"
                                    />
                                  </div>
                                  <button className="CTA-4">
                                    <div className="text-wrapper-136">View Details</div>
                                  </button>
                                  <button className="CTA-5">
                                    <div className="text-wrapper-137">Register</div>
                                  </button>
                                </div>
                                <img className="img-11" alt="Dots horizontal" src="/img/dots-horizontal-1.png" />
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-135">Attending:</div>
                                <div className="attending">
                                  <div className="avatar-w-photo-11" />
                                  <div className="avatar-w-photo-12" />
                                  <div className="avatar-w-photo-13" />
                                  <div className="avatar-w-photo-14">
                                    <div className="text-wrapper-138">120</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="event-2">
                          <div className="div-17">
                            <div className="element-10">
                              <div className="div-wrapper-23">
                                <div className="div-wrapper-23">
                                  <div className="live">
                                    <div className="text-wrapper-132">SCHEDULED</div>
                                  </div>
                                </div>
                              </div>
                              <div className="holder-2">
                                <div className="div-100">
                                  <div className="div-141">
                                    <p className="text-wrapper-133">
                                      Vmware CEO reveals big changes amid multi-cloud shift
                                    </p>
                                    <img className="img-14" alt="Star" src="/img/star-4.svg" />
                                  </div>
                                  <div className="div-66">
                                    <div className="div-66">
                                      <div className="text-wrapper-134">10 Dec 2020,</div>
                                      <p className="text-wrapper-78">10.30 PM - 11.30 AM IST</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="speakers-4">
                                  <div className="text-wrapper-135">Speakers:</div>
                                  <div className="div-18">
                                    <div className="chip-speaker-9">
                                      <div className="div-145" />
                                      <div className="name-12">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Sam</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-146" />
                                      <div className="name-13">Cory</div>
                                    </div>
                                    <div className="chip-speaker-10">
                                      <div className="name-14">+3</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div-147">
                              <div className="div-148">
                                <div className="div-104">
                                  <div className="calendar-add-on-wrapper">
                                    <img
                                      className="calendar-add-on"
                                      alt="Calendar add on"
                                      src="/img/calendar-add-on.png"
                                    />
                                  </div>
                                  <button className="CTA-4">
                                    <div className="text-wrapper-136">View Details</div>
                                  </button>
                                  <button className="CTA-5">
                                    <div className="text-wrapper-137">Register</div>
                                  </button>
                                </div>
                                <img className="img-11" alt="Dots horizontal" src="/img/dots-horizontal.png" />
                              </div>
                              <div className="speakers-5">
                                <div className="text-wrapper-135">Attending:</div>
                                <div className="attending">
                                  <div className="avatar-w-photo-11" />
                                  <div className="avatar-w-photo-12" />
                                  <div className="avatar-w-photo-13" />
                                  <div className="avatar-w-photo-14">
                                    <div className="text-wrapper-138">120</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-3">
                  <div className="div-100">
                    <div className="div-149">
                      <NavLink
                        className="nav-link-15"
                        divClassName="nav-link-16"
                        icon={<Icons37 className="img-14" />}
                        stateProp="default"
                        type="default"
                      />
                      <div className="nav-link-17">
                        <Icons
                          icon="RC"
                          iconRcClassName="icons-31"
                          union="/img/union-5.svg"
                          unionClassName="icons-32"
                        />
                        <div className="dashboard-4">Resource Center</div>
                      </div>
                      <NavLink
                        className="nav-link-15"
                        divClassName="nav-link-16"
                        icon={<Icons4 className="img-14" />}
                        stateProp="default"
                        text="Learning Center"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-18"
                        divClassName="nav-link-19"
                        icon={<Icons35 className="img-14" />}
                        stateProp="selected"
                        text="Auditorium"
                        type="default"
                      />
                      <div className="nav-link-17">
                        <Icons
                          divClassName="icons-36"
                          divClassNameOverride="icons-37"
                          ellipseClassName="icons-34"
                          ellipseClassNameOverride="icons-35"
                          icon="MT"
                          iconRcClassName="icons-31"
                          overlapGroupClassName="icons-33"
                          unionClassName="icons-38"
                        />
                        <div className="dashboard-4">Meetings</div>
                      </div>
                      <NavLink
                        className="nav-link-15"
                        divClassName="nav-link-16"
                        icon={<IconQz className="img-14" />}
                        stateProp="default"
                        text="Quizzes"
                        type="default"
                      />
                      <NavLink
                        className="nav-link-15"
                        divClassName="nav-link-16"
                        icon={<Icons9 className="img-14" />}
                        stateProp="default"
                        text="Manage"
                        type="default"
                      />
                    </div>
                  </div>
                  <div className="div-100">
                    <NavLink
                      className="nav-link-20"
                      divClassName="nav-link-16"
                      icon={<IconLg className="img-14" />}
                      stateProp="default"
                      text="Logout"
                      type="default"
                    />
                    <div className="extra-4">
                      <div className="text-wrapper-139">Policies</div>
                      <div className="interpunct-3">
                        <div className="ellipse-39" />
                      </div>
                      <div className="text-wrapper-139">Terms &amp; Conditions</div>
                    </div>
                  </div>
                </div>
                <div className="div-150">
                  <div className="text-wrapper-140">LOGO</div>
                  <div className="div-151">
                    <div className="div-141">
                      <img className="img-11" alt="Icon" src="/img/icon-5.png" />
                      <img className="img-11" alt="Notifications" src="/img/notifications-2.png" />
                    </div>
                    <div className="div-65">
                      <div className="div-152" />
                      <div className="text-wrapper-141">Santosh</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-admin-view">
                <img className="line-23" alt="Line" src="/img/line-3-1.svg" />
                <div className="overlap-17">
                  <div className="overlap-18">
                    <div className="div-153">
                      <div className="div-140">
                        <div className="div-wrapper-24">
                          <div className="text-wrapper-142">Overview</div>
                        </div>
                        <div className="div-wrapper-25">
                          <div className="text-wrapper-143">My Assignments</div>
                        </div>
                        <div className="div-wrapper-26">
                          <div className="text-wrapper-144">Recommended</div>
                        </div>
                        <div className="div-wrapper-26">
                          <div className="text-wrapper-144">Activity</div>
                        </div>
                      </div>
                      <div className="frame-wrapper-37">
                        <div className="div-76">
                          <div className="text-input-3">
                            <img className="img-14" alt="Search" src="/img/search-1.svg" />
                            <div className="value-3">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line-24" alt="Line" src="/img/line-4-2.svg" />
                    <img className="line-25" alt="Line" src="/img/line-5-1.svg" />
                    <div className="div-154">
                      <div className="div-155" />
                      <div className="intro">
                        <div className="div-112">
                          <div className="div-65">
                            <div className="text-wrapper-145">Sam Miller</div>
                            <img className="img-14" alt="Edit" src="/img/edit.png" />
                          </div>
                          <div className="labels-2">
                            <div className="risk">
                              <div className="text-wrapper-146">1640 Points</div>
                            </div>
                            <div className="div-156">
                              <div className="ellipse-40" />
                              <div className="text-wrapper-147">Sales Manager</div>
                            </div>
                            <div className="div-156">
                              <div className="ellipse-40" />
                              <div className="text-wrapper-147">Singapore Region</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-157">
                          <div className="text-wrapper-148">Skills:</div>
                          <div className="div-141">
                            <div className="div-wrapper-27">
                              <div className="text-wrapper-149">Communication</div>
                            </div>
                            <div className="div-wrapper-27">
                              <div className="text-wrapper-150">Proposals</div>
                            </div>
                            <div className="div-wrapper-27">
                              <div className="text-wrapper-149">Customer Profiling</div>
                            </div>
                            <div className="div-wrapper-27">
                              <div className="text-wrapper-149">Closing</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="menu-4">
                      <div className="div-158">
                        <div className="div-159">
                          <img className="img-29" alt="Frame" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-151">Dashboard</div>
                        </div>
                        <div className="div-160">
                          <img className="img-30" alt="Frame" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-152">Resource Center</div>
                        </div>
                        <div className="div-161">
                          <div className="img-11">
                            <div className="rectangle-5" />
                            <img className="polygon-6" alt="Polygon" src="/img/arrow-back-3.png" />
                          </div>
                          <div className="text-wrapper-152">Learning Center</div>
                        </div>
                        <div className="div-161">
                          <img className="img-31" alt="Frame" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-152">Auditoriums</div>
                        </div>
                        <div className="div-161">
                          <div className="img-11">
                            <div className="group-4">
                              <div className="overlap-group-19">
                                <div className="ellipse-27" />
                                <div className="ellipse-28" />
                                <div className="ellipse-29" />
                                <div className="ellipse-30" />
                              </div>
                            </div>
                          </div>
                          <div className="text-wrapper-152">Meetings</div>
                        </div>
                      </div>
                      <div className="team">
                        <div className="div-162">
                          <div className="div-111">
                            <div className="div-163">
                              <div className="text-wrapper-97">Teams</div>
                              <img className="img-14" alt="Arrow drop down" src="/img/arrow-drop-down-2.png" />
                            </div>
                            <div className="div-111">
                              <div className="div-111">
                                <div className="div-144">
                                  <div className="div-164">
                                    <div className="div-65">
                                      <img
                                        className="arrow-drop-down-4"
                                        alt="Arrow drop down"
                                        src="/img/arrow-back-3.png"
                                      />
                                      <div className="text-wrapper-98">Marketing</div>
                                    </div>
                                    <img className="img-14" alt="More horiz" src="/img/more-horiz-6.png" />
                                  </div>
                                  <div className="div-165">
                                    <div className="div-65">
                                      <div className="avatar-w-photo-15" />
                                      <div className="avatar-w-photo-15" />
                                      <div className="avatar-w-photo-16" />
                                    </div>
                                    <div className="div-65">
                                      <img className="img-12" alt="Add" src="/img/add-11.png" />
                                      <div className="text-wrapper-153">Invite People</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="div-111">
                                <div className="div-144">
                                  <div className="div-164">
                                    <div className="div-65">
                                      <img
                                        className="arrow-drop-down-5"
                                        alt="Arrow drop down"
                                        src="/img/arrow-back-3.png"
                                      />
                                      <div className="text-wrapper-98">Design</div>
                                    </div>
                                    <img className="img-14" alt="More horiz" src="/img/more-horiz-5.png" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="div-166">
                            <img className="add-6" alt="Add" src="/img/add-10.png" />
                            <div className="text-wrapper-98">Add Team</div>
                          </div>
                        </div>
                        <img className="line-20" alt="Line" src="/img/line-2-1.svg" />
                        <div className="div-wrapper-28">
                          <div className="text-wrapper-154">Browse Teams</div>
                        </div>
                      </div>
                      <div className="div-167">
                        <div className="text-wrapper-155">Logout</div>
                        <img className="img-14" alt="Noun dashboard" src="/img/noun-dashboard-5101893-1.svg" />
                      </div>
                      <div className="div-168">
                        <div className="text-wrapper-156">Policies</div>
                        <div className="ellipse-41" />
                        <div className="text-wrapper-156">Terms of Service</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-169">
                    <div className="text-wrapper-157">Resources Assigned to you:</div>
                    <div className="frame-wrapper-38">
                      <div className="frame-wrapper-39">
                        <div className="div-170">
                          <div className="frame-wrapper-40">
                            <div className="frame-wrapper-17">
                              <div className="div-17">
                                <div className="div-79">
                                  <div className="text-wrapper-158">Marketing</div>
                                  <div className="div-wrapper-7">
                                    <div className="text-wrapper-159">NEW</div>
                                  </div>
                                </div>
                                <img className="img-11" alt="More horiz" src="/img/more-horiz-12.png" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-40">
                            <div className="frame-wrapper-17">
                              <div className="div-17">
                                <div className="text-wrapper-77">Sales</div>
                                <img className="img-11" alt="More horiz" src="/img/more-horiz-11.png" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-41">
                            <div className="frame-wrapper-17">
                              <div className="div-17">
                                <div className="div-141">
                                  <div className="text-wrapper-158">Design</div>
                                </div>
                                <img className="more-horiz-7" alt="More horiz" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-171">
                    <div className="text-wrapper-157">Events you’re invited to</div>
                    <div className="frame-wrapper-38">
                      <div className="frame-wrapper-42">
                        <div className="div-170">
                          <div className="frame-wrapper-43">
                            <div className="frame-wrapper-17">
                              <div className="div-11">
                                <div className="name-date">
                                  <p className="text-13">Annual Sales Meetup - 2022</p>
                                  <div className="div-66">
                                    <div className="text-wrapper-78">10 Dec 2020</div>
                                    <div className="text-wrapper-78">10.30 PM IST</div>
                                  </div>
                                </div>
                                <div className="div-67">
                                  <div className="text-wrapper-79">Speakers:</div>
                                  <div className="div-65">
                                    <div className="chip-speaker-6">
                                      <div className="div-172" />
                                      <div className="name-6">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-172" />
                                      <div className="name-6">Sam</div>
                                    </div>
                                    <div className="chip-speaker-8">
                                      <div className="name-7">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-43">
                            <div className="frame-wrapper-17">
                              <div className="div-11">
                                <div className="name-date">
                                  <div className="text-13">Team Brigdging - Scion</div>
                                  <div className="div-66">
                                    <div className="text-wrapper-78">10 Dec 2020</div>
                                    <div className="text-wrapper-78">10.30 PM IST</div>
                                  </div>
                                </div>
                                <div className="div-67">
                                  <div className="text-wrapper-79">Speakers:</div>
                                  <div className="div-65">
                                    <div className="chip-speaker-6">
                                      <div className="div-172" />
                                      <div className="name-6">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-172" />
                                      <div className="name-6">Sam</div>
                                    </div>
                                    <div className="chip-speaker-8">
                                      <div className="name-7">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-44">
                            <div className="frame-wrapper-17">
                              <div className="div-11">
                                <div className="name-date">
                                  <p className="text-13">Vmware CEO reveals big changes amid multi-cloud shift</p>
                                  <div className="div-66">
                                    <div className="text-wrapper-78">10 Dec 2020</div>
                                    <div className="text-wrapper-78">10.30 PM IST</div>
                                  </div>
                                </div>
                                <div className="div-67">
                                  <div className="text-wrapper-79">Speakers:</div>
                                  <div className="div-65">
                                    <div className="chip-speaker-6">
                                      <div className="div-173" />
                                      <div className="name-6">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-7">
                                      <div className="div-174" />
                                      <div className="name-6">Sam</div>
                                    </div>
                                    <div className="chip-speaker-8">
                                      <div className="name-7">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-45">
                            <div className="frame-wrapper-15">
                              <div className="div-17">
                                <div className="div-79">
                                  <div className="text-wrapper-158">Marketing</div>
                                  <div className="div-wrapper-7">
                                    <div className="text-wrapper-160">NEW</div>
                                  </div>
                                </div>
                                <img className="more-horiz-8" alt="More horiz" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-46">
                            <div className="frame-wrapper-15">
                              <div className="div-17">
                                <div className="text-wrapper-77">Sales</div>
                                <img className="more-horiz-9" alt="More horiz" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                          </div>
                          <div className="frame-wrapper-47">
                            <div className="frame-wrapper-15">
                              <div className="div-17">
                                <div className="div-141">
                                  <div className="text-wrapper-158">Design</div>
                                </div>
                                <img className="more-horiz-10" alt="More horiz" src="/img/arrow-back-3.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-175">
                    <div className="text-wrapper-157">Courses assigned to you</div>
                    <div className="frame-wrapper-38">
                      <div className="frame-wrapper-42">
                        <div className="div-170">
                          <div className="course">
                            <div className="overlap-19">
                              <div className="image-3">
                                <img className="icon-button-4" alt="Icon button" src="/img/icon-button-2.svg" />
                                <div className="line-wrapper">
                                  <img className="line-26" alt="Line" src="/img/arrow-back-3.png" />
                                </div>
                              </div>
                              <div className="frame-wrapper-48">
                                <div className="div-11">
                                  <div className="name-date">
                                    <div className="text-16">Sales Cycle - Negotiations</div>
                                  </div>
                                  <div className="speakers-6">
                                    <div className="div-176">
                                      <div className="text-wrapper-161">Instructor</div>
                                      <div className="chip-speaker-wrapper">
                                        <div className="chip-speaker-7">
                                          <div className="div-146" />
                                          <div className="name-15">Cameron</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="div-wrapper-29">
                                      <div className="text-wrapper-162">Continue</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="course">
                            <div className="overlap-19">
                              <div className="image-3">
                                <img className="icon-button-4" alt="Icon button" src="/img/icon-button-1.svg" />
                                <div className="overlap-group-24">
                                  <img className="line-26" alt="Line" src="/img/arrow-back-3.png" />
                                </div>
                              </div>
                              <div className="frame-wrapper-48">
                                <div className="div-11">
                                  <div className="name-date">
                                    <div className="text-16">Review presentations</div>
                                  </div>
                                  <div className="speakers-6">
                                    <div className="div-176">
                                      <div className="text-wrapper-161">Instructor</div>
                                      <div className="chip-speaker-wrapper">
                                        <div className="chip-speaker-7">
                                          <div className="div-146" />
                                          <div className="name-15">Lisa Kudrow</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="div-wrapper-29">
                                      <div className="text-wrapper-162">Continue</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="course-2">
                            <div className="overlap-19">
                              <div className="image-3">
                                <img className="icon-button-5" alt="Icon button" src="/img/arrow-back-3.png" />
                                <div className="overlap-group-25">
                                  <img className="line-26" alt="Line" src="/img/arrow-back-3.png" />
                                </div>
                              </div>
                              <div className="frame-wrapper-48">
                                <div className="div-11">
                                  <div className="name-date">
                                    <div className="text-16">UX review presentations</div>
                                  </div>
                                  <div className="speakers-6">
                                    <div className="div-176">
                                      <div className="text-wrapper-161">Instructor</div>
                                      <div className="chip-speaker-wrapper">
                                        <div className="chip-speaker-6">
                                          <div className="div-146" />
                                          <div className="name-15">Cameron</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="div-wrapper-29">
                                      <div className="text-wrapper-162">Continue</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-177">
                  <div className="text-wrapper-163">Recommended for you</div>
                  <div className="frame-wrapper-49">
                    <div className="frame-wrapper-50">
                      <div className="div-178">
                        <div className="frame-wrapper-51">
                          <div className="frame-wrapper-17">
                            <div className="div-17">
                              <div className="div-79">
                                <div className="text-wrapper-158">Marketing</div>
                                <div className="div-wrapper-30">
                                  <div className="text-wrapper-160">NEW</div>
                                </div>
                              </div>
                              <img className="more-horiz-11" alt="More horiz" src="/img/arrow-back-3.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-52">
                          <div className="frame-wrapper-17">
                            <div className="div-11">
                              <div className="name-date">
                                <p className="text-17">Vmware CEO reveals big changes amid multi-cloud shift</p>
                                <div className="div-66">
                                  <div className="text-wrapper-78">10 Dec 2020</div>
                                  <div className="text-wrapper-78">10.30 PM IST</div>
                                </div>
                              </div>
                              <div className="div-17">
                                <div className="div-67">
                                  <div className="text-wrapper-161">Speakers:</div>
                                  <div className="div-65">
                                    <div className="chip-speaker-6">
                                      <div className="div-179" />
                                      <div className="name-6">Cameron</div>
                                    </div>
                                    <div className="chip-speaker-8">
                                      <div className="name-7">+2 more</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="div-wrapper-31">
                                  <div className="text-wrapper-164">Register</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="course-3">
                          <img className="image-4" alt="Image" src="/img/arrow-back-3.png" />
                          <div className="frame-wrapper-48">
                            <div className="div-11">
                              <div className="name-date">
                                <div className="text-18">UX review presentations</div>
                              </div>
                              <div className="speakers-6">
                                <div className="div-176">
                                  <div className="text-wrapper-165">Instructor</div>
                                  <div className="chip-speaker-wrapper">
                                    <div className="chip-speaker-6">
                                      <div className="div-146" />
                                      <div className="name-15">Cameron</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="div-wrapper-32">
                                  <div className="text-wrapper-164">Start Now</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-53">
                          <div className="frame-wrapper-15">
                            <div className="div-17">
                              <div className="text-wrapper-77">Sales</div>
                              <img className="more-horiz-12" alt="More horiz" src="/img/arrow-back-3.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper-54">
                          <div className="frame-wrapper-15">
                            <div className="div-17">
                              <div className="div-141">
                                <div className="text-wrapper-158">Design</div>
                              </div>
                              <img className="more-horiz-13" alt="More horiz" src="/img/arrow-back-3.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-180">
                  <div className="div-181">
                    <div className="img-wrapper-2">
                      <img className="sticky-note" alt="Sticky note" src="/img/sticky-note-2.svg" />
                    </div>
                    <div className="div-182">
                      <div className="div-65">
                        <div className="text-wrapper-166">Visit Help Center</div>
                        <img className="arrow-back-7" alt="Arrow back" src="/img/arrow-back-7.png" />
                      </div>
                      <p className="text-wrapper-167">Find all FAQs, tutorials, and articles to learn more</p>
                    </div>
                  </div>
                  <div className="div-183">
                    <div className="img-wrapper-2">
                      <img className="mark-chat-unread" alt="Mark chat unread" src="/img/mark-chat-unread.svg" />
                    </div>
                    <div className="div-184">
                      <div className="div-65">
                        <div className="text-wrapper-166">24/7 Support</div>
                        <img className="arrow-back-7" alt="Arrow back" src="/img/arrow-back-6.png" />
                      </div>
                      <p className="text-wrapper-168">Talk to our Customer Support team.</p>
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper-55">
                  <div className="frame-wrapper-56">
                    <div className="div-11">
                      <div className="div-185">
                        <div className="div-17">
                          <div className="div-wrapper-33">
                            <div className="text-wrapper-169">Leaderboard</div>
                          </div>
                          <div className="bar-3">
                            <div className="text-19">Sort by</div>
                            <img className="chevron-3" alt="Chevron" src="/img/chevron-2.svg" />
                          </div>
                        </div>
                        <img className="line-20" alt="Line" src="/img/line-1-2.svg" />
                        <div className="div-186">
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189">
                                <div className="ellipse-42" />
                              </div>
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Sam</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-11.png" />
                                </div>
                                <div className="text-wrapper-171">2000 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="overlap-group-26">
                                <img className="polygon-7" alt="Polygon" src="/img/polygon-2-6.svg" />
                                <img className="polygon-8" alt="Polygon" src="/img/polygon-1-6.svg" />
                                <div className="text-wrapper-172">1</div>
                              </div>
                            </div>
                          </div>
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189" />
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Sandra</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-10.png" />
                                </div>
                                <div className="text-wrapper-173">1680 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="overlap-group-27">
                                <img className="polygon-7" alt="Polygon" src="/img/polygon-2-5.svg" />
                                <img className="polygon-8" alt="Polygon" src="/img/polygon-1-5.svg" />
                                <div className="text-wrapper-174">2</div>
                              </div>
                            </div>
                          </div>
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189">
                                <div className="text-wrapper-175">EL</div>
                              </div>
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Ellie</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-9.png" />
                                </div>
                                <div className="text-wrapper-173">1640 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="overlap-group-27">
                                <img className="polygon-7" alt="Polygon" src="/img/polygon-2-4.svg" />
                                <img className="polygon-8" alt="Polygon" src="/img/polygon-1-4.svg" />
                                <div className="text-wrapper-174">3</div>
                              </div>
                            </div>
                          </div>
                          <div className="div-192">
                            <div className="div-188">
                              <div className="div-189">
                                <div className="ellipse-42" />
                              </div>
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Santosh</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-8.png" />
                                </div>
                                <div className="text-wrapper-173">1620 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="text-wrapper-176">4</div>
                            </div>
                          </div>
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189" />
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Kristine</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-7.png" />
                                </div>
                                <div className="text-wrapper-173">1540 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="text-wrapper-176">5</div>
                            </div>
                          </div>
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189" />
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Robert</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-6.png" />
                                </div>
                                <div className="text-wrapper-173">1540 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="text-wrapper-176">6</div>
                            </div>
                          </div>
                          <div className="div-187">
                            <div className="div-188">
                              <div className="div-189" />
                              <div className="div-190">
                                <div className="div-191">
                                  <div className="text-wrapper-170">Pauline</div>
                                  <img className="expand-less-3" alt="Expand less" src="/img/expand-less-5.png" />
                                </div>
                                <div className="text-wrapper-173">1540 Points</div>
                              </div>
                            </div>
                            <div className="div-wrapper-34">
                              <div className="text-wrapper-176">7</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-193">
                        <div className="text-wrapper-177">See all</div>
                        <img className="arrow-back-8" alt="Arrow back" src="/img/arrow-back-5.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-103">
                  <div className="div-104">
                    <img className="menu-5" alt="Menu" src="/img/arrow-back-3.png" />
                    <div className="text-wrapper-178">LOGO</div>
                  </div>
                  <div className="div-105">
                    <img className="img-11" alt="Notifications" src="/img/notifications-1.svg" />
                    <div className="ellipse-wrapper-3">
                      <div className="ellipse-31" />
                    </div>
                    <div className="text-wrapper-101">Santosh K</div>
                  </div>
                </div>
              </div>
              <div className="auditorium-analytics">
                <div className="overlap-20">
                  <div className="header-2">
                    <div className="text-wrapper-124">Summary</div>
                    <div className="controls">
                      <div className="CTA-6">
                        <div className="holder-3">
                          <img className="img-32" alt="Download" src="/img/download.png" />
                          <div className="text-wrapper-179">Download Report</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-194">
                    <div className="div-195">
                      <div className="text-wrapper-180">32</div>
                      <div className="div-17">
                        <div className="text-wrapper-181">Total Sessions</div>
                        <img className="img-32" alt="Help" src="/img/help-3.png" />
                      </div>
                    </div>
                    <img className="line-27" alt="Line" src="/img/line-48.svg" />
                    <div className="div-195">
                      <div className="text-wrapper-180">94%</div>
                      <div className="div-196">
                        <div className="text-wrapper-182">Engagment Rate</div>
                        <img className="img-32" alt="Help" src="/img/help-2.png" />
                      </div>
                    </div>
                    <img className="line-27" alt="Line" src="/img/line-49.svg" />
                    <div className="div-195">
                      <div className="text-wrapper-183">1h 24m 56s</div>
                      <div className="div-17">
                        <div className="text-wrapper-184">Avg. Engagment Time</div>
                        <img className="img-32" alt="Help" src="/img/help-1.png" />
                      </div>
                    </div>
                    <img className="line-27" alt="Line" src="/img/line-50.svg" />
                    <div className="div-195">
                      <div className="text-wrapper-183">54%</div>
                      <div className="div-107">
                        <div className="text-wrapper-184">Avg. Attendance</div>
                        <img className="img-32" alt="Help" src="/img/help.png" />
                      </div>
                    </div>
                  </div>
                  <div className="chart">
                    <div className="div-197">
                      <div className="heading-2">
                        <div className="text-wrapper-185">Engagment</div>
                        <p className="text-wrapper-186">All about your event analytics in a nutshell</p>
                      </div>
                      <img className="divider-2" alt="Divider" src="/img/divider.svg" />
                    </div>
                    <div className="div-17">
                      <div className="chip-speaker-11">
                        <div className="name-wrapper-2">
                          <div className="name-16">All Sessions</div>
                        </div>
                      </div>
                      <div className="search-bar-4">
                        <Icons31 className="icons-31-instance" />
                        <div className="text-wrapper-187">Search</div>
                      </div>
                    </div>
                    <div className="div-198">
                      <div className="stats-wrapper">
                        <div className="stats">
                          <div className="stat">
                            <div className="div-66">
                              <img className="line-28" alt="Line" src="/img/line-40-4.svg" />
                              <div className="div-21">
                                <div className="text-wrapper-188">Attendees</div>
                                <div className="text-wrapper-189">73</div>
                              </div>
                            </div>
                          </div>
                          <div className="stat">
                            <div className="div-66">
                              <img className="line-28" alt="Line" src="/img/line-40-3.svg" />
                              <div className="div-21">
                                <div className="text-wrapper-188">Raised Hands</div>
                                <div className="text-wrapper-189">26</div>
                              </div>
                            </div>
                          </div>
                          <div className="stat">
                            <div className="div-66">
                              <img className="line-28" alt="Line" src="/img/line-40-2.svg" />
                              <div className="div-21">
                                <div className="text-wrapper-188">Answered Polls</div>
                                <div className="text-wrapper-189">16</div>
                              </div>
                            </div>
                          </div>
                          <div className="stat">
                            <div className="div-66">
                              <img className="line-28" alt="Line" src="/img/line-40-1.svg" />
                              <div className="div-21">
                                <div className="text-wrapper-188">Sent Messages</div>
                                <div className="text-wrapper-189">32</div>
                              </div>
                            </div>
                          </div>
                          <div className="stat">
                            <div className="div-66">
                              <img className="line-28" alt="Line" src="/img/line-40.svg" />
                              <div className="div-21">
                                <div className="text-wrapper-188">Reacted Emojis</div>
                                <div className="text-wrapper-189">12</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="graph">
                        <div className="data">
                          <div className="overlap-group-28">
                            <div className="lines">
                              <img className="line-29" alt="Line" src="/img/line-34.svg" />
                              <img className="line-30" alt="Line" src="/img/line-35.svg" />
                              <img className="line-31" alt="Line" src="/img/line-36.svg" />
                              <img className="line-32" alt="Line" src="/img/line-37.svg" />
                              <img className="line-33" alt="Line" src="/img/line-38.svg" />
                              <img className="line-34" alt="Line" src="/img/line-39.svg" />
                            </div>
                            <div className="graph-2">
                              <div className="column-3">
                                <div className="credit-wrapper">
                                  <div className="credit" />
                                </div>
                                <div className="text-wrapper-190">Raised Hands</div>
                              </div>
                              <div className="column-4">
                                <div className="data-2">
                                  <div className="credit" />
                                </div>
                                <div className="text-wrapper-190">Attendees</div>
                              </div>
                              <div className="column-3">
                                <div className="data-3">
                                  <div className="credit" />
                                </div>
                                <div className="text-wrapper-190">Answered Polls</div>
                              </div>
                              <div className="column-3">
                                <div className="data-4">
                                  <div className="credit" />
                                </div>
                                <div className="text-wrapper-190">Sent Messages</div>
                              </div>
                              <div className="column-3">
                                <div className="data-5">
                                  <div className="credit" />
                                </div>
                                <div className="text-wrapper-190">Reacted Emojis</div>
                              </div>
                            </div>
                          </div>
                          <div className="y-axis">
                            <div className="text-wrapper-191">75</div>
                            <div className="text-wrapper-192">60</div>
                            <div className="text-wrapper-190">45</div>
                            <div className="text-wrapper-192">30</div>
                            <div className="text-wrapper-192">15</div>
                            <div className="text-wrapper-192">0</div>
                          </div>
                        </div>
                        <div className="key">
                          <div className="div-196">
                            <div className="ellipse-43" />
                            <div className="text-wrapper-193">Raised Hands</div>
                          </div>
                          <div className="div-196">
                            <div className="ellipse-43" />
                            <div className="text-wrapper-193">Sent Messages</div>
                          </div>
                          <div className="div-196">
                            <div className="ellipse-43" />
                            <div className="text-wrapper-193">Answered Polls</div>
                          </div>
                          <div className="div-196">
                            <div className="ellipse-43" />
                            <div className="text-wrapper-193">Reacted Emoji</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-199">
                    <div className="div-200">
                      <div className="text-wrapper-194">Top Sessions</div>
                      <div className="div-65">
                        <div className="text-wrapper-195">Attendance</div>
                        <img className="img-32" alt="Expand more" src="/img/expand-more.png" />
                      </div>
                    </div>
                    <div className="div-201">
                      <div className="div-202">
                        <p className="text-wrapper-196">Vmware CEO reveals big changes amid multi-cloud shift</p>
                        <div className="div-196">
                          <div className="bar-4">
                            <img className="line-35" alt="Line" src="/img/line-47-3.svg" />
                          </div>
                          <div className="text-wrapper-197">97</div>
                        </div>
                      </div>
                      <div className="div-202">
                        <p className="text-wrapper-196">Vmware CEO reveals big changes amid multi-cloud shift</p>
                        <div className="div-196">
                          <div className="bar-5">
                            <img className="line-35" alt="Line" src="/img/line-47-2.svg" />
                          </div>
                          <div className="text-wrapper-197">64</div>
                        </div>
                      </div>
                      <div className="div-202">
                        <p className="text-wrapper-196">Vmware CEO reveals big changes amid multi-cloud shift</p>
                        <div className="div-196">
                          <div className="bar-6">
                            <img className="line-35" alt="Line" src="/img/line-47-1.svg" />
                          </div>
                          <div className="text-wrapper-197">43</div>
                        </div>
                      </div>
                      <div className="div-202">
                        <p className="text-wrapper-196">Vmware CEO reveals big changes amid multi-cloud shift</p>
                        <div className="div-196">
                          <div className="bar-7">
                            <img className="line-35" alt="Line" src="/img/line-47.svg" />
                          </div>
                          <div className="text-wrapper-197">32</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-6">
                  <div className="div-203">
                    <img className="menu-7" alt="Menu" src="/img/menu.png" />
                    <div className="div-wrapper-35">
                      <div className="text-wrapper-198">Add your Logo</div>
                    </div>
                  </div>
                  <div className="frame-wrapper-57">
                    <div className="div-204">
                      <div className="ellipse-wrapper-7">
                        <div className="ellipse-44" />
                      </div>
                      <div className="text-wrapper-199">Santosh K</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-205">
                <p className="text-wrapper-200">
                  After a series of holisitic UX audits, assesments - back and forths we started building the
                  Information architecture, flows,
                </p>
                <div className="text-wrapper-201">Wireframes</div>
                <p className="text-wrapper-202">to keeps users focused, on what truly matters.</p>
              </div>
              <div className="about-the-client-wrapper">
                <div className="about-the-client">
                  <p className="first-on-the-list">First on the list - Identify &amp; Define</p>
                </div>
              </div>
              <div className="group-7">
                <div className="overlap-21">
                  <div className="overlap-21">
                    <img className="dashboard-home" alt="Dashboard home" src="/img/dashboard-home-1.png" />
                    <Line
                      className="line-instance"
                      direction="horizontal"
                      element="/img/90.svg"
                      elementClassName="line-36"
                      icon={<Endpoint23 className="endpoint-2-3" />}
                      lineType="solid"
                      prop="line-37"
                      prop1="circle"
                      type="ninety"
                    />
                    <div className="annotation-text-2">
                      <AnnotationText
                        className="annotation-text-instance"
                        direction="default"
                        noteClassName="annotation-text-3"
                        size="small"
                        text="Profile"
                        theme="dark"
                      />
                      <Line
                        className="line-39"
                        direction="vertical"
                        hasElement={false}
                        line="/img/line-13.svg"
                        lineClassName="line-38"
                        lineType="solid"
                        override={<Endpoint22 className="endpoint-2-2" />}
                        type="arrow"
                      />
                    </div>
                    <div className="annotation-text-4">
                      <AnnotationText
                        className="annotation-text-5"
                        direction="default"
                        noteClassName="annotation-text-3"
                        size="small"
                        text="Header"
                        theme="dark"
                      />
                      <Line
                        className="line-40"
                        direction="horizontal"
                        hasElement={false}
                        img="/img/line-12.svg"
                        lineClassName="line-38"
                        lineType="solid"
                        override={<Endpoint shape="circle" shapeRectangleClassName="endpoint-instance" />}
                        type="arrow"
                      />
                    </div>
                    <div className="annotation-text-6">
                      <AnnotationText
                        className="annotation-text-instance"
                        direction="default"
                        noteClassName="annotation-text-3"
                        size="small"
                        text="Navigation Panel"
                        theme="dark"
                      />
                      <Line
                        className="line-40"
                        direction="horizontal"
                        hasElement={false}
                        img="/img/line-11.svg"
                        lineClassName="line-38"
                        lineType="solid"
                        override={<Endpoint shape="circle" shapeRectangleClassName="endpoint-instance" />}
                        type="arrow"
                      />
                    </div>
                    <div className="annotation-text-7">
                      <AnnotationText
                        className="annotation-text-instance"
                        direction="default"
                        noteClassName="annotation-text-3"
                        size="small"
                        text="Secondary Nav"
                        theme="dark"
                      />
                      <Line
                        className="line-40"
                        direction="horizontal"
                        hasElement={false}
                        img="/img/line-10.svg"
                        lineClassName="line-38"
                        lineType="solid"
                        override={<Endpoint shape="circle" shapeRectangleClassName="endpoint-instance" />}
                        type="arrow"
                      />
                    </div>
                    <div className="annotation-text-8">
                      <AnnotationText
                        className="annotation-text-5"
                        direction="default"
                        noteClassName="annotation-text-3"
                        size="small"
                        text="Quick Links"
                        theme="dark"
                      />
                      <Line
                        className="line-40"
                        direction="horizontal"
                        hasElement={false}
                        img="/img/line-9.svg"
                        lineClassName="line-38"
                        lineType="solid"
                        override={<Endpoint shape="circle" shapeRectangleClassName="endpoint-instance" />}
                        type="arrow"
                      />
                    </div>
                  </div>
                  <AnnotationText
                    className="annotation-text-9"
                    direction="default"
                    noteClassName="annotation-text-10"
                    size="small"
                    text="User Activities"
                    theme="light"
                  />
                </div>
                <Line
                  className="measure-6"
                  direction="horizontal"
                  endpointImg="/img/endpoint-2-1.svg"
                  endpointShapeMeasure="/img/endpoint-1-1.svg"
                  endpointShapeRectangleClassName="measure-2"
                  endpointShapeRectangleClassNameOverride="measure-5"
                  imgClassName="line-38"
                  line1="/img/line-8.svg"
                  line2="/img/line-7.svg"
                  lineClassNameOverride="line-38"
                  lineType="solid"
                  midsectionNumberClassName="measure-3"
                  midsectionText="Work Panel"
                  midsectionTypeEqualClassName="measure-4"
                  type="numbers"
                />
                <Line
                  className="measure-7"
                  direction="horizontal"
                  endpointImg="/img/arrow-back-3.png"
                  endpointShapeMeasure="/img/endpoint-1.svg"
                  endpointShapeRectangleClassName="measure-2"
                  endpointShapeRectangleClassNameOverride="measure-11"
                  imgClassName="measure-8"
                  line1="/img/line-6.svg"
                  line2="/img/line.svg"
                  lineClassNameOverride="measure-8"
                  lineType="solid"
                  midsectionNumberClassName="measure-9"
                  midsectionText="Notification Panel"
                  midsectionTypeEqualClassName="measure-10"
                  type="numbers"
                />
              </div>
              <div className="login">
                <div className="frame-wrapper-58">
                  <div className="div-wrapper-36">
                    <div className="text-wrapper-100">LOGO</div>
                  </div>
                </div>
                <div className="div-206">
                  <div className="text-wrapper-203">Welcome Back!</div>
                  <p className="text-wrapper-204">Welcome back! Please enter your details</p>
                </div>
                <div className="div-207">
                  <div className="div-208">
                    <div className="text-wrapper-205">Email</div>
                    <div className="text-input-4">
                      <div className="text-wrapper-206">
                        <div className="text-20">Enter your email</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-208">
                    <div className="text-wrapper-205">Password</div>
                    <div className="text-input-5">
                      <div className="text-wrapper-206">
                        <div className="text-21">***********</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-209">
                  <div className="div-210">
                    <div className="checkbox-2">
                      <div className="icons-outline-wrapper">
                        <IconsOutlineCheckmark className="icons-outline-checkmark" />
                      </div>
                    </div>
                    <div className="text-22">Remember Me</div>
                  </div>
                  <div className="text-23">Forgot Password</div>
                </div>
                <button className="button-9">
                  <div className="text-wrapper-207">Continue</div>
                </button>
                <p className="text-24">
                  <span className="text-wrapper-208">Already have an account?</span>
                  <span className="text-wrapper-209">&nbsp;</span>
                  <span className="text-wrapper-210">Sign In</span>
                </p>
              </div>
              <div className="verify-e-mail">
                <div className="overlap-22">
                  <div className="rectangle-12" />
                  <div className="overlap-group-wrapper-6">
                    <div className="overlap-group-29">
                      <img className="line-41" alt="Line" src="/img/line-1-1.svg" />
                      <img className="line-42" alt="Line" src="/img/line-2.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-211">LOGO</div>
                </div>
                <div className="div-211">
                  <div className="div-212">
                    <div className="text-wrapper-212">Verify E-Mail Address</div>
                    <p className="text-wrapper-213">Code is sent to your example@gmail.com</p>
                  </div>
                  <div className="div-213">
                    <div className="text-input-6">
                      <div className="text-wrapper-214">
                        <div className="text-25">1</div>
                      </div>
                    </div>
                    <div className="text-input-7">
                      <div className="text-wrapper-214">
                        <div className="text-25">-</div>
                      </div>
                    </div>
                    <div className="text-input-7">
                      <div className="text-wrapper-214">
                        <div className="text-26">-</div>
                      </div>
                    </div>
                    <div className="text-input-8">
                      <div className="text-wrapper-214">
                        <div className="text-26">-</div>
                      </div>
                    </div>
                  </div>
                  <p className="text-27">
                    <span className="text-wrapper-215">Already have an account?&nbsp;&nbsp;</span>
                    <span className="text-wrapper-216">Sign In</span>
                  </p>
                  <p className="text-28">
                    <span className="text-wrapper-215">Didn’t recieve code?&nbsp;&nbsp;</span>
                    <span className="text-wrapper-216">Request again</span>
                  </p>
                  <div className="div-wrapper-37">
                    <div className="text-wrapper-217">Verify and Create Account</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-8" />
            <div className="div-214">
              <div className="div-215">
                <p className="text-wrapper-218">Next on the list putting together a robust</p>
                <div className="component-based">
                  Component-based <br />
                  design system
                </div>
              </div>
              <p className="that-s-responsive">
                that’s responsive, adaptable and lightweight based on the Base Web v12 architecture, spacings based on
                the 8px spacing methodology and the color system based on the brand colours&nbsp;&nbsp;for faster
                development across platforms.
              </p>
            </div>
            <div className="typography">
              <div className="div-216">
                <div className="div-217">
                  <div className="text-wrapper-219">Inter</div>
                  <div className="text-wrapper-220">Aa</div>
                </div>
                <div className="div-218">
                  <div className="aa-bb-cc-dd-ee-ff-gg">
                    AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890-+=!@#$%^&amp;*(){"{"}
                    {"}"}|\:;”’&lt;&gt;,.?/
                  </div>
                  <div className="div-219">
                    <div className="text-wrapper-221">SemiBold</div>
                    <div className="text-wrapper-222">Bold</div>
                    <div className="text-wrapper-223">Regular</div>
                  </div>
                </div>
              </div>
              <img className="line-43" alt="Line" src="/img/line-4-1.svg" />
              <div className="div-220">
                <div className="div-221">
                  <div className="div-222">
                    <div className="div-223">
                      <div className="text-wrapper-224">61px</div>
                      <div className="text-wrapper-225">3.812rem</div>
                    </div>
                    <div className="type">Heading 1</div>
                  </div>
                  <div className="div-222">
                    <div className="div-223">
                      <div className="text-wrapper-224">48px</div>
                      <div className="text-wrapper-225">3.062rem</div>
                    </div>
                    <div className="type-2">Heading 2</div>
                  </div>
                  <div className="div-222">
                    <div className="div-223">
                      <div className="text-wrapper-224">40px</div>
                      <div className="text-wrapper-225">2.438rem</div>
                    </div>
                    <div className="type-3">Heading 3</div>
                  </div>
                </div>
                <div className="div-224">
                  <div className="div-221">
                    <div className="div-222">
                      <div className="div-223">
                        <div className="text-wrapper-224">30px</div>
                        <div className="text-wrapper-225">1.938rem</div>
                      </div>
                      <div className="type-4">Heading 4</div>
                    </div>
                    <div className="div-225">
                      <div className="div-223">
                        <div className="text-wrapper-224">24px</div>
                        <div className="text-wrapper-225">1.562rem</div>
                      </div>
                      <div className="type-5">Heading 5</div>
                    </div>
                    <div className="div-225">
                      <div className="div-223">
                        <div className="text-wrapper-224">20px</div>
                        <div className="text-wrapper-225">1.250rem</div>
                      </div>
                      <div className="type-6">Heading 6</div>
                    </div>
                  </div>
                  <div className="div-221">
                    <div className="div-225">
                      <div className="div-223">
                        <div className="text-wrapper-224">16px</div>
                        <div className="text-wrapper-225">1.000rem</div>
                      </div>
                      <div className="type-7">Paragraph</div>
                    </div>
                    <div className="div-225">
                      <div className="div-223">
                        <div className="text-wrapper-224">14px</div>
                        <div className="text-wrapper-225">0.812rem</div>
                      </div>
                      <div className="type-8">Captions</div>
                    </div>
                    <div className="div-225">
                      <div className="div-223">
                        <div className="text-wrapper-224">12px</div>
                        <div className="text-wrapper-225">0.625rem</div>
                      </div>
                      <div className="type-9">Labels</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-10">
              <div className="overlap-23">
                <div className="key-2">
                  <div className="text-wrapper-226">Rounded Primary</div>
                  <div className="text-wrapper-227">Rounded Secondary</div>
                  <div className="text-wrapper-228">Rounded Outline</div>
                  <div className="text-wrapper-229">Rounded Text</div>
                  <div className="text-wrapper-230">Default</div>
                </div>
                <div className="button-11">
                  <Button className="type-primary-size" icon="none" size="small" type="primary" />
                  <Button className="type-primary-size-2" icon="none" size="large" type="primary" />
                  <Button className="type-primary-size-3" icon="right" size="small" type="primary" />
                  <Button className="type-primary-size-4" icon="right" size="large" type="primary" />
                  <Button className="type-primary-size-5" icon="left" size="small" type="primary" />
                  <Button className="type-primary-size-6" icon="left" size="large" type="primary" />
                  <Button className="type-primary-size-7" icon="icon-only" size="small" type="primary" />
                  <Button className="type-primary-size-8" icon="icon-only" size="large" type="primary" />
                  <Button className="type-secondary-size" icon="icon-only" size="small" type="secondary" />
                  <Button className="type-secondary-size-2" icon="icon-only" size="large" type="secondary" />
                  <Button className="type-secondary-size-3" icon="none" size="small" type="secondary" />
                  <Button className="type-secondary-size-4" icon="none" size="large" type="secondary" />
                  <Button className="type-secondary-size-5" icon="right" size="small" type="secondary" />
                  <Button className="type-secondary-size-6" icon="right" size="large" type="secondary" />
                  <Button className="type-secondary-size-7" icon="left" size="small" type="secondary" />
                  <Button className="type-secondary-size-8" icon="left" size="large" type="secondary" />
                  <Button className="type-outline-size" icon="none" size="small" type="outline" />
                  <Button className="type-outline-size-2" icon="none" size="large" type="outline" />
                  <Button className="type-outline-size-3" icon="right" size="small" type="outline" />
                  <Button className="type-outline-size-4" icon="right" size="large" type="outline" />
                  <Button className="type-outline-size-5" icon="left" size="small" type="outline" />
                  <Button className="type-outline-size-6" icon="left" size="large" type="outline" />
                  <Button className="type-outline-size-7" icon="icon-only" size="small" type="outline" />
                  <Button className="type-outline-size-8" icon="icon-only" size="large" type="outline" />
                  <Button className="type-text-size-small" icon="icon-only" size="small" type="text" />
                  <Button className="type-text-size-large" icon="icon-only" size="large" type="text" />
                  <Button className="type-text-size-small-2" icon="none" size="small" type="text" />
                  <Button className="type-text-size-large-2" icon="none" size="large" type="text" />
                  <Button className="type-text-size-small-3" icon="right" size="small" type="text" />
                  <Button className="type-text-size-large-3" icon="right" size="large" type="text" />
                  <Button className="type-text-size-small-4" icon="left" size="small" type="text" />
                  <Button className="type-text-size-large-4" icon="left" size="large" type="text" />
                </div>
              </div>
            </div>
            <div className="element-11">
              <ButtonDefault className="button-default-instance" />
              <ButtonHover className="button-hover-instance" />
              <ButtonAddTeam className="button-add-team-default" />
              <ButtonAddTeamWrapper className="button-add-team-default-hover" />
              <ButtonVariant className="button-variant5" />
              <ButtonVariantWrapper className="button-variant6" />
              <DivWrapper className="button-variant7" />
              <ButtonVariant8 className="button-variant-instance" />
              <ButtonVariant9 className="button-variant9-instance" />
              <ButtonVariant10 className="button-variant10-instance" />
              <ButtonVariant11 className="button-variant11-instance" />
              <ButtonVariant12 className="button-variant12-instance" />
              <div className="text-wrapper-231">Primary / Default</div>
              <div className="text-wrapper-232">Primary / Hover</div>
            </div>
            <div className="spacer-2">
              <Spacer className="size" size="eight" />
              <Spacer className="spacer-instance" size="sixteen" />
              <Spacer className="size-2" size="twenty-four" />
              <Spacer className="size-3" size="thirty-two" />
              <Spacer className="size-4" size="forty" />
              <Spacer className="size-5" size="forty-eight" />
              <Spacer className="size-6" size="fifty-six" />
              <Spacer className="size-7" size="sixty-four" />
              <Spacer className="size-8" size="seventy-two" />
              <Spacer className="size-9" size="eighty" />
              <Spacer className="size-10" size="eighty-eight" />
              <Spacer className="size-11" size="ninety-six" />
            </div>
            <div className="checkbox-3">
              <div className="key-3">
                <div className="text-wrapper-233">Controls</div>
              </div>
              <div className="key-4">
                <div className="group-9">
                  <Radio checked className="radio-instance" />
                  <div className="text-wrapper-234">Checked</div>
                </div>
                <div className="text-wrapper-235">Radio</div>
                <div className="group-10">
                  <Radio checked={false} className="radio-3" ellipseClassName="radio-2" />
                  <div className="text-wrapper-234">Not Checked</div>
                </div>
              </div>
              <div className="text-wrapper-236">Checkbox</div>
              <div className="group-11">
                <RadioPartial1 className="radio-partial" />
                <div className="text-wrapper-237">Partial</div>
                <RadioNo className="radio-no-instance" />
                <div className="text-wrapper-238">Open</div>
                <RadioYes className="radio-yes" />
                <div className="text-wrapper-239">Checked</div>
              </div>
              <div className="text-wrapper-240">Switches</div>
              <div className="text-wrapper-241">Sliders</div>
              <div className="group-12">
                <RadioTrue className="design-component-instance-node-2" />
                <div className="text-wrapper-242">On</div>
              </div>
              <div className="group-13">
                <RadioFalse className="design-component-instance-node-2" />
                <div className="text-wrapper-242">Off</div>
              </div>
              <SliderFalse className="slider-false-instance" />
              <div className="slider-true">
                <div className="overlap-24">
                  <div className="rectangle-13" />
                  <div className="div-226">
                    <div className="overlap-group-30">
                      <div className="rectangle-14" />
                      <div className="ellipse-45" />
                    </div>
                    <div className="div-227">
                      <div className="div-wrapper-38">
                        <div className="text-wrapper-243">50</div>
                      </div>
                      <img className="polygon-9" alt="Polygon" src="/img/arrow-back-3.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-244">Single</div>
              <div className="text-wrapper-245">Single with Value</div>
            </div>
          </div>
          <div className="colour-palate">
            <div className="overlap-25">
              <div className="overlap-26">
                <div className="div-228">
                  <div className="div-229">
                    <div className="text-wrapper-246">Neutrals</div>
                    <div className="div-230">
                      <div className="element-efeee">
                        <div className="div-231">
                          <div className="text-wrapper-247">95</div>
                          <div className="text-wrapper-247">#EFEEE9</div>
                        </div>
                      </div>
                      <div className="element-eaee">
                        <div className="div-231">
                          <div className="text-wrapper-247">90</div>
                          <div className="text-wrapper-247">#EAE8E2</div>
                        </div>
                      </div>
                      <div className="element-eedb">
                        <div className="div-231">
                          <div className="text-wrapper-247">80</div>
                          <div className="text-wrapper-247">#E5E2DB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-232">
                    <div className="text-wrapper-246">01. Warning</div>
                    <div className="div-230">
                      <div className="element-fffae">
                        <div className="div-231">
                          <div className="text-wrapper-247">98</div>
                          <div className="text-wrapper-247">#FFFAE6</div>
                        </div>
                      </div>
                      <div className="element-fffcc">
                        <div className="div-231">
                          <div className="text-wrapper-247">95</div>
                          <div className="text-wrapper-247">#FFF5CC</div>
                        </div>
                      </div>
                      <div className="element-fffb">
                        <div className="div-231">
                          <div className="text-wrapper-247">90</div>
                          <div className="text-wrapper-247">#FFF0B3</div>
                        </div>
                      </div>
                      <div className="element-ffeb">
                        <div className="div-231">
                          <div className="text-wrapper-247">80</div>
                          <div className="text-wrapper-247">#FFEB99</div>
                        </div>
                      </div>
                      <div className="element-fee">
                        <div className="div-231">
                          <div className="text-wrapper-247">70</div>
                          <div className="text-wrapper-247">#FEE266</div>
                        </div>
                      </div>
                      <div className="element-fed">
                        <div className="div-231">
                          <div className="text-wrapper-247">60</div>
                          <div className="text-wrapper-247">#FED833</div>
                        </div>
                      </div>
                      <div className="element-fece">
                        <div className="div-231">
                          <div className="text-wrapper-247">50</div>
                          <div className="text-wrapper-247">#FECE00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-233">
                    <div className="text-wrapper-246">Error - Secondaries</div>
                    <div className="tertiary">
                      <div className="element-feff">
                        <div className="div-231">
                          <div className="text-wrapper-247">90</div>
                          <div className="text-wrapper-247">#FEF5F3</div>
                        </div>
                      </div>
                      <div className="element-fdece">
                        <div className="div-231">
                          <div className="text-wrapper-247">80</div>
                          <div className="text-wrapper-247">#FDECE7</div>
                        </div>
                      </div>
                      <div className="element-fcedb">
                        <div className="div-231">
                          <div className="text-wrapper-247">70</div>
                          <div className="text-wrapper-247">#FCE2DB</div>
                        </div>
                      </div>
                      <div className="element-fbdcf">
                        <div className="div-231">
                          <div className="text-wrapper-247">60</div>
                          <div className="text-wrapper-247">#FBD8CF</div>
                        </div>
                      </div>
                      <div className="element-facb">
                        <div className="div-231">
                          <div className="text-wrapper-247">50</div>
                          <div className="text-wrapper-247">#FAC5B6</div>
                        </div>
                      </div>
                      <div className="element-fbe">
                        <div className="div-231">
                          <div className="text-wrapper-247">40</div>
                          <div className="text-wrapper-247">#F8B19E</div>
                        </div>
                      </div>
                      <div className="element-fe">
                        <div className="div-231">
                          <div className="text-wrapper-247">30</div>
                          <div className="text-wrapper-247">#F69E86</div>
                        </div>
                      </div>
                      <div className="element-dc">
                        <div className="div-231">
                          <div className="text-wrapper-247">20</div>
                          <div className="text-wrapper-247">#D4836C</div>
                        </div>
                      </div>
                      <div className="element-b">
                        <div className="div-231">
                          <div className="text-wrapper-248">10</div>
                          <div className="text-wrapper-248">#B36753</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-234">
                  <div className="text-wrapper-246">Neutrals</div>
                  <div className="div-230">
                    <div className="element-ffffff">
                      <div className="div-231">
                        <div className="text-wrapper-247">95</div>
                        <div className="text-wrapper-247">#FFFFFF</div>
                      </div>
                    </div>
                    <div className="element-ebebeb">
                      <div className="div-231">
                        <div className="text-wrapper-247">90</div>
                        <div className="text-wrapper-247">#EBEBEB</div>
                      </div>
                    </div>
                    <div className="element-ddd">
                      <div className="div-231">
                        <div className="text-wrapper-247">80</div>
                        <div className="text-wrapper-247">#D7D7D7</div>
                      </div>
                    </div>
                    <div className="element-ccc">
                      <div className="div-231">
                        <div className="text-wrapper-247">70</div>
                        <div className="text-wrapper-247">#C3C3C3</div>
                      </div>
                    </div>
                    <div className="element-afafaf">
                      <div className="div-231">
                        <div className="text-wrapper-247">60</div>
                        <div className="text-wrapper-247">#AFAFAF</div>
                      </div>
                    </div>
                    <div className="element-12">
                      <div className="div-231">
                        <div className="text-wrapper-248">50</div>
                        <div className="text-wrapper-248">#868686</div>
                      </div>
                    </div>
                    <div className="element-eee">
                      <div className="div-231">
                        <div className="text-wrapper-248">40</div>
                        <div className="text-wrapper-248">#5E5E5E</div>
                      </div>
                    </div>
                    <div className="element-13">
                      <div className="div-231">
                        <div className="text-wrapper-248">30</div>
                        <div className="text-wrapper-248">#363636</div>
                      </div>
                    </div>
                    <div className="element-bbb">
                      <div className="div-231">
                        <div className="text-wrapper-248">20</div>
                        <div className="text-wrapper-248">#2B2B2B</div>
                      </div>
                    </div>
                    <div className="element-14">
                      <div className="div-231">
                        <div className="text-wrapper-248">10</div>
                        <div className="text-wrapper-248">#202020</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-235">
                  <div className="text-wrapper-246">Primary</div>
                  <div className="div-230">
                    <div className="element-edffb">
                      <div className="div-231">
                        <div className="text-wrapper-247">98</div>
                        <div className="text-wrapper-247">#EDF2FB</div>
                      </div>
                    </div>
                    <div className="element-dcef">
                      <div className="div-236">
                        <div className="text-wrapper-249">95</div>
                        <div className="text-wrapper-249">#DCE4F8</div>
                      </div>
                    </div>
                    <div className="element-cadf">
                      <div className="div-231">
                        <div className="text-wrapper-247">90</div>
                        <div className="text-wrapper-247">#CAD7F4</div>
                      </div>
                    </div>
                    <div className="element-bcf">
                      <div className="div-231">
                        <div className="text-wrapper-247">80</div>
                        <div className="text-wrapper-247">#B9C9F1</div>
                      </div>
                    </div>
                    <div className="element-aee">
                      <div className="div-231">
                        <div className="text-wrapper-247">70</div>
                        <div className="text-wrapper-247">#95AEE9</div>
                      </div>
                    </div>
                    <div className="element-e">
                      <div className="div-231">
                        <div className="text-wrapper-247">60</div>
                        <div className="text-wrapper-247">#7293E2</div>
                      </div>
                    </div>
                    <div className="element-fdb">
                      <div className="div-231">
                        <div className="text-wrapper-248">50</div>
                        <div className="text-wrapper-248">#4F78DB</div>
                      </div>
                    </div>
                    <div className="element-fb">
                      <div className="div-231">
                        <div className="text-wrapper-248">40</div>
                        <div className="text-wrapper-248">#3F60B0</div>
                      </div>
                    </div>
                    <div className="element-f">
                      <div className="div-231">
                        <div className="text-wrapper-248">30</div>
                        <div className="text-wrapper-248">#2F4884</div>
                      </div>
                    </div>
                    <div className="element-15">
                      <div className="div-231">
                        <div className="text-wrapper-248">20</div>
                        <div className="text-wrapper-248">#203159</div>
                      </div>
                    </div>
                    <div className="element-16">
                      <div className="div-231">
                        <div className="text-wrapper-248">10</div>
                        <div className="text-wrapper-248">#182543</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-237">
                <div className="text-wrapper-246">Success</div>
                <div className="div-230">
                  <div className="element-efcf">
                    <div className="div-231">
                      <div className="text-wrapper-247">99</div>
                      <div className="text-wrapper-247">#E6FCF4</div>
                    </div>
                  </div>
                  <div className="element-ccfe">
                    <div className="div-231">
                      <div className="text-wrapper-247">98</div>
                      <div className="text-wrapper-247">#CCF8E8</div>
                    </div>
                  </div>
                  <div className="element-bfdd">
                    <div className="div-231">
                      <div className="text-wrapper-247">95</div>
                      <div className="text-wrapper-247">#B3F5DD</div>
                    </div>
                  </div>
                  <div className="element-fd">
                    <div className="div-231">
                      <div className="text-wrapper-247">90</div>
                      <div className="text-wrapper-247">#99F1D1</div>
                    </div>
                  </div>
                  <div className="element-ebbb">
                    <div className="div-231">
                      <div className="text-wrapper-247">80</div>
                      <div className="text-wrapper-247">#66EBBB</div>
                    </div>
                  </div>
                  <div className="element-ea">
                    <div className="div-231">
                      <div className="text-wrapper-247">70</div>
                      <div className="text-wrapper-247">#33E4A4</div>
                    </div>
                  </div>
                  <div className="label-ddd-wrapper">
                    <div className="div-231">
                      <div className="text-wrapper-247">60</div>
                      <div className="text-wrapper-247">#00DD8D</div>
                    </div>
                  </div>
                  <div className="label-b-wrapper">
                    <div className="div-231">
                      <div className="text-wrapper-247">50</div>
                      <div className="text-wrapper-247">#00B171</div>
                    </div>
                  </div>
                  <div className="element-17">
                    <div className="div-231">
                      <div className="text-wrapper-248">40</div>
                      <div className="text-wrapper-248">#008555</div>
                    </div>
                  </div>
                  <div className="element-18">
                    <div className="div-231">
                      <div className="text-wrapper-248">30</div>
                      <div className="text-wrapper-248">#005838</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="colour-palate-2">
            <div className="element-19">
              <div className="div-238">
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-241">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243" />
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-241">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243" />
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-245">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243" />
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-245">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243" />
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
              </div>
              <div className="div-246">
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-247">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243">
                      <TwentyArrowChevronDown2 className="element-arrow-chevron" />
                    </div>
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-247">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243">
                      <TwentyArrowChevronDown2 className="element-arrow-chevron" />
                    </div>
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-248">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243">
                      <TwentyArrowChevronDown2 className="element-arrow-chevron" />
                    </div>
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                </div>
                <div className="div-239">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-248">
                    <div className="div-242">
                      <div className="text-wrapper-251">Placeholder</div>
                    </div>
                    <div className="div-243">
                      <TwentyArrowChevronDown2 className="element-arrow-chevron" />
                    </div>
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
              </div>
              <div className="div-246">
                <div className="text-area">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-59">
                    <div className="overlap-group-31">
                      <div className="frame-wrapper-59">
                        <div className="div-249">
                          <div className="text-wrapper-251">Write Something..</div>
                        </div>
                      </div>
                      <img className="img-33" alt="Frame" src="/img/frame-54-3.svg" />
                    </div>
                  </div>
                </div>
                <div className="text-area-2">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-59">
                    <div className="overlap-group-32">
                      <div className="frame-wrapper-60">
                        <div className="div-249">
                          <div className="text-wrapper-251">Write Something..</div>
                        </div>
                      </div>
                      <img className="img-34" alt="Frame" src="/img/frame-54-2.svg" />
                    </div>
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
                <div className="text-area-2">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-59">
                    <div className="overlap-group-32">
                      <div className="frame-wrapper-61">
                        <div className="div-249">
                          <div className="text-wrapper-251">Write Something..</div>
                        </div>
                      </div>
                      <img className="img-34" alt="Frame" src="/img/frame-54-1.svg" />
                    </div>
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                </div>
                <div className="text-area-3">
                  <div className="div-240">
                    <div className="text-wrapper-250">Label</div>
                  </div>
                  <div className="div-59">
                    <div className="overlap-group-33">
                      <div className="frame-wrapper-62">
                        <div className="div-249">
                          <div className="text-wrapper-251">Write Something..</div>
                        </div>
                      </div>
                      <img className="img-35" alt="Frame" src="/img/frame-54.svg" />
                    </div>
                  </div>
                  <div className="div-244">
                    <ThirtyTwoGeneralAlert className="element-general-alert" color="#F69E86" />
                    <div className="text-wrapper-253">Something went wrong</div>
                  </div>
                  <div className="div-244">
                    <p className="text-wrapper-252">Tristique senectus et netus et</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-254">Text Inputs</div>
          </div>
          <div className="menu-8">
            <Menu
              className="property-default"
              navLinkIcon={<Icons29 className="icons-39" />}
              navLinkIcon1={<Icons25 className="icons-39" />}
              navLinkIcon2={<IconQz className="icons-39" />}
              navLinkIcon3={<Icons9 className="icons-39" />}
              override={<Icons4 className="icons-39" />}
              property1="default"
            />
            <Menu
              className="property-collapsed"
              navLinkIcon4={<Icons17 className="icons-40" />}
              navLinkIcon5={<Icons4 className="icons-40" />}
              navLinkIcon6={<Icons13 className="icons-40" />}
              navLinkIcon7={<IconQz className="icons-40" />}
              navLinkIcon8={<Icons9 className="icons-40" />}
              property1="collapsed"
            />
          </div>
        </div>
        <div className="div-250">
          <div className="overlap-27">
            <div className="resources-all">
              <div className="div-251">
                <div className="frame-wrapper-63">
                  <div className="div-252">
                    <div className="text-input-9">
                      <div className="div-253">
                        <img className="star-8" alt="Search" src="/img/search.svg" />
                        <div className="value-4">Search</div>
                      </div>
                      <img className="tune" alt="Tune" src="/img/arrow-back-3.png" />
                    </div>
                    <PropertyDefaultWrapper
                      buttonWrapperAdd="/img/arrow-back-3.png"
                      buttonWrapperAddClassName="button-13"
                      buttonWrapperButtonClassName="button-14"
                      buttonWrapperDivClassName="button-15"
                      buttonWrapperFrameClassName="button-16"
                      buttonWrapperText="Add Files"
                      className="button-12"
                      property1="default"
                    />
                  </div>
                </div>
                <div className="div-254">
                  <div className="container-nav-wrapper">
                    <div className="container-nav">
                      <div className="container-menu-2">
                        <div className="navlink-4">
                          <div className="text-wrapper-255">All Files</div>
                        </div>
                        <div className="navlink-5">
                          <div className="text-wrapper-256">Assigned to Me</div>
                          <div className="div-wrapper-39">
                            <div className="text-wrapper-257">1</div>
                          </div>
                        </div>
                        <div className="navlink-6">
                          <div className="text-wrapper-256">Favourites</div>
                        </div>
                        <div className="navlink-5">
                          <div className="text-wrapper-256">Requests</div>
                          <div className="div-wrapper-39">
                            <div className="text-wrapper-257">1</div>
                          </div>
                        </div>
                      </div>
                      <div className="div-255">
                        <div className="value-5">All</div>
                        <img className="chevron-4" alt="Chevron" src="/img/arrow-back-3.png" />
                      </div>
                    </div>
                  </div>
                  <div className="div-256">
                    <CardDesign
                      className="card-design-instance"
                      divClassName="card-design-5"
                      divClassName1="card-design-7"
                      divClassName2="card-design-9"
                      divClassNameOverride="card-design-4"
                      ellipseClassName="card-design-11"
                      folderCardClassName="card-design-2"
                      frameClassName="card-design-3"
                      frameClassName1="card-design-4"
                      frameClassName2="card-design-8"
                      frameClassName3="card-design-10"
                      frameClassNameOverride="card-design-4"
                      frameWrapperClassName="card-design-6"
                      icon={<ConcreteComponentNode state="default" />}
                      moreHoriz="/img/arrow-back-3.png"
                      moreHorizClassName="card-design-13"
                      stateProp="default"
                      subheadingClassName="card-design-9"
                      subheadingWrapperClassName="card-design-8"
                      supportingTextClassName="card-design-12"
                      type="folder"
                    />
                    <CardDesign
                      className="card-design-instance"
                      divClassName="card-design-5"
                      divClassName1="card-design-7"
                      divClassNameOverride="card-design-4"
                      ellipseClassName="card-design-11"
                      folderCardClassName="card-design-14"
                      frameClassName="card-design-3"
                      frameClassName1="card-design-4"
                      frameClassName3="card-design-10"
                      frameClassNameOverride="card-design-4"
                      frameWrapperClassName="card-design-6"
                      hasFrame={false}
                      icon={<ConcreteComponentNode state="default" />}
                      moreHoriz="/img/arrow-back-3.png"
                      moreHorizClassName="card-design-option-2"
                      stateProp="default"
                      subheadingClassName="card-design-9"
                      subheadingWrapperClassName="card-design-8"
                      supportingTextClassName="card-design-12"
                      text="Sales"
                      type="folder"
                    />
                  </div>
                  <div className="card-design-wrapper">
                    <CardDesign
                      className="card-design-15"
                      divClassName="card-design-5"
                      divClassName1="card-design-7"
                      divClassNameOverride="card-design-4"
                      ellipseClassName="card-design-11"
                      folderCardClassName="card-design-16"
                      frameClassName="card-design-3"
                      frameClassName1="card-design-4"
                      frameClassName3="card-design-10"
                      frameClassNameOverride="card-design-4"
                      frameWrapperClassName="card-design-6"
                      hasFrame={false}
                      icon={<Star1 className="star-8" />}
                      moreHoriz="/img/arrow-back-3.png"
                      moreHorizClassName="card-design-17"
                      stateProp="default"
                      subheadingClassName="card-design-9"
                      subheadingWrapperClassName="card-design-8"
                      supportingTextClassName="card-design-12"
                      text="Design"
                      type="folder"
                    />
                  </div>
                </div>
              </div>
              <div className="widget-leaderboard-2">
                <div className="container-8">
                  <div className="main-container">
                    <div className="div-17">
                      <div className="div-wrapper-40">
                        <div className="text-wrapper-258">Leaderboard</div>
                      </div>
                      <div className="bar-8">
                        <div className="text-29">Sort by</div>
                        <img className="chevron-5" alt="Chevron" src="/img/arrow-back-3.png" />
                      </div>
                    </div>
                    <img className="line-44" alt="Line" src="/img/arrow-back-3.png" />
                    <div className="div-24">
                      <div className="contestant-5">
                        <div className="div-257">
                          <div className="image-5">
                            <div className="ellipse-46" />
                          </div>
                          <div className="div-258">
                            <div className="div-259">
                              <div className="text-wrapper-259">Abhishek</div>
                              <img className="expand-less-4" alt="Expand less" src="/img/arrow-back-3.png" />
                            </div>
                            <div className="text-wrapper-260">2000 Points</div>
                          </div>
                        </div>
                        <div className="position">
                          <img className="polygon-10" alt="Polygon" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-261">1</div>
                        </div>
                      </div>
                      <div className="contestant-5">
                        <div className="div-257">
                          <div className="div-260" />
                          <div className="div-258">
                            <div className="div-259">
                              <div className="text-wrapper-259">Sandra</div>
                              <img className="expand-less-5" alt="Expand less" src="/img/arrow-back-3.png" />
                            </div>
                            <div className="text-wrapper-260">1680 Points</div>
                          </div>
                        </div>
                        <div className="div-261">
                          <img className="polygon-11" alt="Polygon" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-262">2</div>
                        </div>
                      </div>
                      <div className="contestant-5">
                        <div className="div-257">
                          <div className="div-wrapper-41">
                            <div className="text-wrapper-263">EL</div>
                          </div>
                          <div className="div-258">
                            <div className="div-259">
                              <div className="text-wrapper-259">Ellie</div>
                              <img className="expand-less-6" alt="Expand less" src="/img/arrow-back-3.png" />
                            </div>
                            <div className="text-wrapper-260">1640 Points</div>
                          </div>
                        </div>
                        <div className="div-262">
                          <img className="polygon-12" alt="Polygon" src="/img/arrow-back-3.png" />
                          <div className="text-wrapper-262">3</div>
                        </div>
                      </div>
                      <div className="contestant-6">
                        <div className="div-257">
                          <div className="div-263">
                            <div className="ellipse-47" />
                            <div className="text-wrapper-264">SH</div>
                          </div>
                          <div className="div-258">
                            <div className="div-259">
                              <div className="text-wrapper-259">Santosh</div>
                              <img className="expand-less-7" alt="Expand less" src="/img/arrow-back-3.png" />
                            </div>
                            <div className="text-wrapper-260">1620 Points</div>
                          </div>
                        </div>
                        <div className="div-wrapper-42">
                          <div className="text-wrapper-265">4</div>
                        </div>
                      </div>
                      <div className="contestant-5">
                        <div className="div-257">
                          <div className="div-264" />
                          <div className="div-258">
                            <div className="div-259">
                              <div className="text-wrapper-259">Kristine</div>
                              <img className="expand-less-8" alt="Expand less" src="/img/arrow-back-3.png" />
                            </div>
                            <div className="text-wrapper-260">1540 Points</div>
                          </div>
                        </div>
                        <div className="div-wrapper-42">
                          <div className="text-wrapper-265">5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PropertyDefaultWrapper
                    arrowBack="/img/arrow-back-3.png"
                    arrowBackClassName="button-18"
                    className="button-19"
                    divClassName="button-17"
                    property1="variant-5"
                  />
                </div>
              </div>
              <div className="div-265">
                <div className="div-266">
                  <div className="frame-wrapper-64">
                    <div className="div-wrapper-43">
                      <div className="text-wrapper-258">Based on your skills</div>
                    </div>
                  </div>
                  <div className="div-267">
                    <CardDesign
                      className="card-design-15"
                      divClassName="card-design-5"
                      divClassName1="card-design-7"
                      divClassNameOverride="card-design-4"
                      elementClassName="card-design-option-3"
                      elementClassNameOverride="card-design-option-4"
                      ellipseClassName1="card-design-11"
                      ellipseClassNameOverride="card-design-11"
                      folderCardClassName="card-design-option-6"
                      frameClassName="card-design-3"
                      frameClassName1="card-design-5"
                      frameClassName3="card-design-4"
                      frameClassNameOverride="card-design-4"
                      frameWrapperClassName="card-design-6"
                      group="/img/group.png"
                      groupClassName="card-design-option-5"
                      hasFrame={false}
                      icon={<Star className="star-8" />}
                      moreHoriz="/img/arrow-back-3.png"
                      moreHorizClassName="card-design-option-7"
                      overlapGroupClassName="card-design-option-4"
                      stateProp="default"
                      subheadingClassNameOverride="card-design-9"
                      subheadingWrapperClassNameOverride="card-design-8"
                      supportingTextClassNameOverride="card-design-12"
                      type="file"
                      vector="/img/vector.svg"
                      vectorClassName="card-design-option-5"
                    />
                    <CardDesign
                      chipSpeakerClassName="card-design-24"
                      chipSpeakerClassNameOverride="card-design-27"
                      className="card-design-29"
                      divClassName3="card-design-7"
                      divClassName4="card-design-21"
                      divClassName5="card-design-21"
                      folderCardClassNameOverride="card-design-14"
                      frame="/img/arrow-back-3.png"
                      frameClassName10="card-design-23"
                      frameClassName11="card-design-10"
                      frameClassName12="card-design-25"
                      frameClassName13="card-design-28"
                      frameClassName4="card-design-18"
                      frameClassName5="card-design-19"
                      frameClassName6="card-design-5"
                      frameClassName7="card-design-4"
                      frameClassName8="card-design-6"
                      frameClassName9="card-design-10"
                      frameWrapperClassNameOverride="card-design-4"
                      imgClassName="card-design-20"
                      nameClassName="card-design-26"
                      nameClassName1="card-design-9"
                      nameClassNameOverride="card-design-26"
                      nameWrapperClassName="card-design-27"
                      speakersClassName="card-design-22"
                      stateProp="default"
                      type="event"
                    />
                    <AllFiles
                      className="all-files-instance"
                      divClassName="all-files-4"
                      frameClassName="all-files-2"
                      frameClassNameOverride="all-files-3"
                      moreHoriz="/img/arrow-back-3.png"
                      moreHorizClassName="all-files-5"
                      property1="variant-12"
                    />
                  </div>
                </div>
                <div className="arrow-back-ios-new-wrapper-3">
                  <img className="arrow-back-ios-new-3" alt="Arrow back ios new" src="/img/arrow-back-3.png" />
                </div>
              </div>
              <div className="comments-2">
                <div className="top-3">
                  <div className="title-6">Recent Activity</div>
                </div>
                <div className="container-9">
                  <img className="thread-line-2" alt="Thread line" src="/img/thread-line.png" />
                  <div className="comment-3">
                    <div className="div-268" />
                    <div className="content-7">
                      <p className="paragraph-4">
                        <span className="text-wrapper-266">Olivia Rhye</span>
                        <span className="text-wrapper-267">&nbsp;</span>
                        <span className="text-wrapper-268">added a new file</span>
                      </p>
                      <div className="rectangle-wrapper">
                        <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-12-1.png" />
                      </div>
                      <div className="div-17">
                        <div className="div-259">
                          <div className="text-wrapper-269">2 mins</div>
                          <div className="text-wrapper-270">-</div>
                          <div className="text-wrapper-271">In Marketing</div>
                        </div>
                        <div className="settings-2">
                          <div className="group-14">
                            <div className="ellipse-48" />
                            <div className="ellipse-49" />
                            <div className="ellipse-50" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comment-3">
                    <div className="div-269" />
                    <div className="content-7">
                      <p className="paragraph-4">
                        <span className="text-wrapper-266">Olivia Rhye</span>
                        <span className="text-wrapper-267">&nbsp;</span>
                        <span className="text-wrapper-268">added a new file</span>
                      </p>
                      <div className="rectangle-wrapper">
                        <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-12.png" />
                      </div>
                      <div className="div-17">
                        <div className="div-259">
                          <div className="text-wrapper-269">2 mins</div>
                          <div className="text-wrapper-270">-</div>
                          <div className="text-wrapper-271">In Marketing</div>
                        </div>
                        <div className="settings-2">
                          <div className="group-14">
                            <div className="ellipse-48" />
                            <div className="ellipse-49" />
                            <div className="ellipse-50" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comment-4">
                    <img className="avatar-11" alt="Avatar" src="/img/avatar.png" />
                    <div className="content-8">
                      <p className="paragraph-5">
                        <span className="text-wrapper-272">Lana Steiner</span>
                        <span className="text-wrapper-273">&nbsp;</span>
                        <span className="text-wrapper-274">added a comment</span>
                      </p>
                      <div className="div-wrapper-44">
                        <p className="text-wrapper-275">
                          Lorem ipsum dolor sit amet, coetur adipiscing elit ut aliquam.
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="div-259">
                          <div className="text-wrapper-269">2 mins</div>
                          <div className="text-wrapper-270">-</div>
                          <div className="text-wrapper-271">In Marketing</div>
                        </div>
                        <div className="settings-2">
                          <div className="group-14">
                            <div className="ellipse-48" />
                            <div className="ellipse-49" />
                            <div className="ellipse-50" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-3">
                    <div className="label-wrapper-2">
                      <div className="label-8">View all 124 comments</div>
                    </div>
                    <img className="star-8" alt="Icons" src="/img/icons-6.png" />
                  </div>
                </div>
              </div>
              <div className="overlap-28">
                <img className="line-45" alt="Line" src="/img/line-3.svg" />
                <div className="div-270">
                  <div className="text-wrapper-276">Your Resources</div>
                  <img className="img-36" alt="Frame" src="/img/arrow-back-3.png" />
                </div>
              </div>
              <img className="line-46" alt="Line" src="/img/line-4.svg" />
              <img className="line-47" alt="Line" src="/img/line-5.png" />
              <NamecandidateNameMenuScore10001
                chevronDown="/img/chevron-down.svg"
                chevronDownClassName="menu-25"
                className="menu-instance"
                dashboardClassName="menu-10"
                dashboardClassNameOverride="menu-10"
                divClassName="menu-22"
                ellipseClassName="menu-24"
                extraClassName="menu-21"
                frameClassName="menu-20"
                iconsDivClassName="menu-18"
                iconsDivClassNameOverride="menu-19"
                iconsEllipseClassName="menu-15"
                iconsEllipseClassNameOverride="menu-16"
                iconsIconRcClassName="menu-13"
                iconsIconRcClassNameOverride="menu-13"
                iconsOverlapGroupClassName="menu-17"
                iconsUnion="/img/union.svg"
                iconsUnionClassName="menu-12"
                iconsUnionClassNameOverride="menu-14"
                interpunctClassName="menu-23"
                navLinkClassName="menu-11"
                navLinkClassNameOverride="menu-11"
                navLinkDivClassName="menu-10"
                navLinkDivClassName1="menu-10"
                navLinkDivClassName2="menu-10"
                navLinkDivClassName3="menu-10"
                navLinkDivClassName4="menu-10"
                navLinkDivClassNameOverride="menu-10"
                navLinkIcon={<Icons5 className="icons-41" />}
                navLinkIcon1={<Icons3 className="icons-41" />}
                navLinkIcon2={<Icons2 className="icons-41" />}
                navLinkIcon3={<Icons9 className="icons-41" />}
                navLinkIcon4={<IconLg className="icons-41" />}
                navLinkStateDefaultTypeClassName="menu-9"
                navLinkStateDefaultTypeClassNameOverride="menu-9"
                navLinkStateHoverTypeClassName="menu-9"
                navLinkStateHoverTypeClassNameOverride="menu-9"
                navLinkStateSelectedTypeClassName="menu-9"
                navLinkStateSelectedTypeClassNameOverride="menu-9"
                override={<Icons4 className="icons-41" />}
                property1="default"
                termsConditionsClassName="menu-22"
              />
              <div className="div-271">
                <div className="div-272" />
                <div className="div-273">
                  <div className="div-274">
                    <img className="icon-7" alt="Icon" src="/img/ellipse-5.png" />
                    <img className="notifications" alt="Notifications" src="/img/ellipse-5.png" />
                  </div>
                  <div className="div-259">
                    <div className="ellipse-wrapper-8">
                      <div className="ellipse-51" />
                    </div>
                    <div className="text-wrapper-277">Santosh</div>
                  </div>
                </div>
              </div>
            </div>
            <img className="dashboard-home-2" alt="Dashboard home" src="/img/dashboard-home.png" />
          </div>
          <p className="text-wrapper-278">Cloud based, cross platform, device agnostic</p>
          <p className="text-wrapper-279">One dashboard to manage, collaborate, create, learn and much more</p>
        </div>
      </div>
    </div>
  );
};
