/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons35 = ({ className }) => {
  return (
    <svg
      className={`icons-35 ${className}`}
      fill="none"
      height="13"
      viewBox="0 0 12 13"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M2.71227 3.92562V7.98366C3.34024 7.82778 4.40795 7.63616 5.86197 7.63616C7.41749 7.63616 8.68618 7.85551 9.39345 8.0119V3.92577C8.61123 4.03366 7.13232 4.21603 6.0554 4.21603C4.97799 4.21603 3.49564 4.03348 2.71227 3.92562ZM1.75781 3.65311C1.75781 3.21607 2.14511 2.88033 2.57795 2.9428C3.24064 3.03846 4.91416 3.26157 6.0554 3.26157C7.19619 3.26157 8.86574 3.03862 9.52754 2.94292C9.96039 2.88032 10.3479 3.21601 10.3479 3.65321V8.30134C10.3479 8.78307 9.8908 9.11373 9.44567 9.00409C8.88368 8.86566 7.55307 8.59061 5.86197 8.59061C4.26565 8.59061 3.18433 8.83574 2.69966 8.97465C2.25321 9.1026 1.75781 8.77922 1.75781 8.27259V3.65311Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M2.71227 10.0554L2.53664 9.6117C2.29157 9.70869 2.17154 9.98599 2.26853 10.2311C2.36549 10.476 2.64265 10.5961 2.88766 10.4993L2.8938 10.4969C2.90036 10.4945 2.91148 10.4904 2.92706 10.4849C2.95822 10.4739 3.00722 10.4572 3.07329 10.4368C3.20545 10.3959 3.4058 10.3399 3.66831 10.2834C4.1934 10.1703 4.9665 10.0554 5.93989 10.0554C6.91404 10.0554 7.74625 10.1705 8.33337 10.2852C8.62661 10.3425 8.85777 10.3995 9.01384 10.4416C9.09185 10.4627 9.151 10.48 9.18969 10.4917C9.20902 10.4975 9.22324 10.502 9.23211 10.5048L9.24141 10.5078L9.24254 10.5082V10.5082C9.49246 10.5912 9.76289 10.4562 9.84619 10.2063C9.92953 9.9563 9.7944 9.68604 9.54436 9.6027L9.39345 10.0554C9.54436 9.6027 9.54436 9.6027 9.54436 9.6027L9.54335 9.60236L9.54181 9.60185L9.53716 9.60033L9.52164 9.59533C9.50856 9.59117 9.49004 9.58537 9.46628 9.57818C9.41877 9.56379 9.35028 9.54381 9.26248 9.52012C9.08692 9.47275 8.8338 9.41047 8.51638 9.34845C7.88219 9.22455 6.98762 9.10099 5.93989 9.10099C4.8914 9.10099 4.05069 9.22472 3.46744 9.35029C3.17578 9.41308 2.94833 9.47636 2.79123 9.52496C2.71267 9.54926 2.65167 9.5699 2.60899 9.58498C2.58765 9.59252 2.57089 9.59868 2.5588 9.60321L2.54418 9.60876L2.53952 9.61057L2.53786 9.61122L2.5372 9.61148C2.5372 9.61148 2.53664 9.6117 2.71227 10.0554Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
