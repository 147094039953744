/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Midsection = ({
  type,
  typeEqualClassName,
  numberClassName,
  text = "12px",
  typeEqual = "/img/type-equal.svg",
  typeCut = "/img/type-cut.svg",
  typeDotted = "/img/type-dotted.svg",
  typeSquiggle = "/img/type-squiggle.svg",
  typeZigzag = "/img/type-zigzag.svg",
}) => {
  return (
    <>
      {["cut", "dotted", "equal", "squiggle", "zigzag"].includes(type) && (
        <img
          className={`midsection type-equal-2 type-0-${type} ${typeEqualClassName}`}
          alt="Type equal"
          src={
            type === "dotted"
              ? typeDotted
              : type === "zigzag"
              ? typeZigzag
              : type === "cut"
              ? typeCut
              : type === "squiggle"
              ? typeSquiggle
              : typeEqual
          }
        />
      )}

      {type === "number" && (
        <div className={`midsection type-number ${typeEqualClassName}`}>
          <div className={`number ${numberClassName}`}>{text}</div>
        </div>
      )}
    </>
  );
};

Midsection.propTypes = {
  type: PropTypes.oneOf(["dotted", "number", "squiggle", "equal", "zigzag", "cut"]),
  text: PropTypes.string,
  typeEqual: PropTypes.string,
  typeCut: PropTypes.string,
  typeDotted: PropTypes.string,
  typeSquiggle: PropTypes.string,
  typeZigzag: PropTypes.string,
};
