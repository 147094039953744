/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair1 = ({ className }) => {
  return (
    <svg
      className={`chair-1 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_87_5942)">
        <path
          className="path"
          d="M22.4461 5.30385C28.2903 6.59631 31.2123 7.24254 33.0541 9.24515C33.3158 9.5297 33.5582 9.83148 33.7795 10.1485C35.3372 12.3793 35.3372 15.3719 35.3372 21.3573L35.3372 23.1507C35.3372 28.9384 35.3372 31.8323 33.877 34.0151C33.595 34.4367 33.2764 34.8326 32.925 35.1984C31.1055 37.0922 28.2788 37.7119 22.6253 38.9513L22.1164 39.0628C13.238 41.0093 8.79879 41.9825 5.56691 40.1203C4.70363 39.6229 3.9227 38.9946 3.25195 38.2579C0.740876 35.4997 0.740875 30.9551 0.740875 21.8658V21.8658C0.740875 12.9948 0.740874 8.55928 3.19236 5.87069C3.84712 5.1526 4.60938 4.5405 5.45192 4.05625C8.60643 2.24316 12.9373 3.20095 21.5991 5.11653L22.4461 5.30385Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M22.4174 9.03337C26.2117 9.82897 28.1088 10.2268 29.4079 11.397C29.9085 11.8479 30.3359 12.3742 30.6745 12.9567C31.5532 14.4683 31.5532 16.4067 31.5532 20.2834L31.5532 24.1688C31.5532 27.8944 31.5532 29.7572 30.7399 31.2244C30.3683 31.8949 29.8807 32.4942 29.2999 32.9945C28.0289 34.0893 26.2051 34.4684 22.5575 35.2266L19.5035 35.8614C14.4541 36.911 11.9294 37.4358 9.9036 36.9843C6.94282 36.3244 4.46549 34.3091 3.21687 31.5446C2.36255 29.6531 2.36255 27.0744 2.36255 21.9171V21.9171C2.36255 16.884 2.36255 14.3675 3.19657 12.523C4.41555 9.82707 6.83377 7.86333 9.72243 7.22356C11.6989 6.78583 14.1618 7.30228 19.0877 8.33517L22.4174 9.03337Z"
          fill="#5E5E5E"
        />
        <path
          className="path"
          d="M17.9789 10.478C18.9285 10.8216 19.4032 10.9933 19.794 11.2381C20.783 11.8576 21.4797 12.8505 21.7257 13.9912C21.8229 14.442 21.8229 14.9468 21.8229 15.9566L21.8229 29.0153C21.8229 30.1234 21.8229 30.6775 21.7078 31.1665C21.4378 32.313 20.7119 33.2998 19.6977 33.8988C19.2652 34.1543 18.7362 34.3192 17.6782 34.649V34.649C14.0011 35.7956 12.1625 36.3688 10.6725 36.3104C7.13911 36.1717 4.04597 33.8961 2.86186 30.5642C2.36253 29.1591 2.36253 27.2332 2.36253 23.3815L2.36253 21.4352C2.36253 17.6128 2.36253 15.7016 2.83758 14.328C4.05747 10.8008 7.40231 8.45395 11.1341 8.50683C12.5874 8.52742 14.3846 9.17763 17.9789 10.478V10.478Z"
          fill="#AFAFAF"
        />
        <path
          className="path"
          d="M19.6607 22.1262L17.4984 22.1262"
          stroke="white"
          strokeLinecap="round"
          strokeWidth="1.08113"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_87_5942">
          <rect
            className="rect"
            fill="white"
            height="43.2454"
            transform="translate(43.9862 0.503662) rotate(90)"
            width="43.2454"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
