/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const SliderFalse = ({ className }) => {
  return (
    <div className={`slider-false ${className}`}>
      <div className="overlap">
        <div className="rectangle" />
        <div className="overlap-group-wrapper">
          <div className="overlap-group-3">
            <div className="rectangle-2" />
            <div className="ellipse-7" />
          </div>
        </div>
      </div>
    </div>
  );
};
