/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair23 = ({ className }) => {
  return (
    <svg
      className={`chair-23 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_87_5963)">
        <path
          className="path"
          d="M21.7893 5.11904C15.9451 6.4115 13.023 7.05773 11.1812 9.06034C10.9195 9.34489 10.6772 9.64667 10.4558 9.96365C8.89819 12.1944 8.89819 15.1871 8.89819 21.1725L8.89819 22.9659C8.89819 28.7536 8.89819 31.6475 10.3584 33.8303C10.6404 34.2519 10.959 34.6478 11.3104 35.0136C13.1299 36.9073 15.9566 37.5271 21.6101 38.7665L22.1189 38.878C30.9974 40.8245 35.4366 41.7977 38.6684 39.9355C39.5317 39.4381 40.3127 38.8098 40.9834 38.0731C43.4945 35.3149 43.4945 30.7703 43.4945 21.681V21.681C43.4945 12.81 43.4945 8.37446 41.043 5.68588C40.3882 4.96779 39.626 4.35569 38.7834 3.87143C35.6289 2.05835 31.298 3.01614 22.6363 4.93172L21.7893 5.11904Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M21.8179 8.84855C18.0237 9.64415 16.1266 10.042 14.8275 11.2121C14.3268 11.6631 13.8995 12.1893 13.5609 12.7719C12.6822 14.2835 12.6822 16.2218 12.6822 20.0986L12.6822 23.984C12.6822 27.7096 12.6822 29.5724 13.4954 31.0396C13.8671 31.7101 14.3546 32.3094 14.9354 32.8097C16.2064 33.9045 18.0302 34.2836 21.6779 35.0418L24.7318 35.6766C29.7813 36.7262 32.306 37.251 34.3318 36.7995C37.2925 36.1396 39.7699 34.1243 41.0185 31.3598C41.8728 29.4683 41.8728 26.8896 41.8728 21.7322V21.7322C41.8728 16.6992 41.8728 14.1827 41.0388 12.3381C39.8198 9.64225 37.4016 7.67851 34.5129 7.03874C32.5365 6.60101 30.0735 7.11746 25.1476 8.15036L21.8179 8.84855Z"
          fill="#5E5E5E"
        />
        <path
          className="path"
          d="M26.2564 10.2932C25.3069 10.6368 24.8321 10.8085 24.4414 11.0533C23.4523 11.6728 22.7557 12.6657 22.5096 13.8064C22.4124 14.2571 22.4124 14.762 22.4124 15.7718L22.4124 28.8305C22.4124 29.9386 22.4124 30.4927 22.5275 30.9816C22.7975 32.1282 23.5235 33.115 24.5377 33.714C24.9702 33.9694 25.4992 34.1344 26.5571 34.4642V34.4642C30.2343 35.6107 32.0728 36.184 33.5628 36.1255C37.0962 35.9869 40.1894 33.7113 41.3735 30.3794C41.8728 28.9743 41.8728 27.0484 41.8728 23.1967L41.8728 21.2503C41.8728 17.428 41.8728 15.5168 41.3978 14.1432C40.1779 10.616 36.833 8.26913 33.1012 8.32201C31.6479 8.3426 29.8508 8.99281 26.2564 10.2932V10.2932Z"
          fill="#AFAFAF"
        />
        <path
          className="path"
          d="M24.5746 21.9414L26.7369 21.9414"
          stroke="white"
          strokeLinecap="round"
          strokeWidth="1.08113"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_87_5963">
          <rect
            className="rect"
            fill="white"
            height="43.2454"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 0.249146 0.318848)"
            width="43.2454"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
