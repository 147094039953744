/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const StateSelected = ({ className }) => {
  return (
    <svg
      className={`state-selected ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.36602 3.28472C7.56602 2.67072 8.43469 2.67072 8.63402 3.28472L9.34736 5.47939C9.39094 5.61304 9.47566 5.72951 9.5894 5.81212C9.70315 5.89474 9.84011 5.93928 9.98069 5.93939H12.2887C12.9347 5.93939 13.2027 6.76605 12.6807 7.14605L10.814 8.50205C10.7 8.58474 10.6152 8.70139 10.5716 8.83529C10.528 8.96918 10.5279 9.11344 10.5714 9.24739L11.2847 11.4421C11.4847 12.0561 10.7814 12.5674 10.258 12.1874L8.39136 10.8314C8.27749 10.7487 8.1404 10.7042 7.99969 10.7042C7.85898 10.7042 7.72189 10.7487 7.60802 10.8314L5.74136 12.1874C5.21869 12.5674 4.51602 12.0561 4.71536 11.4421L5.42869 9.24739C5.47214 9.11344 5.47206 8.96918 5.42847 8.83529C5.38487 8.70139 5.3 8.58474 5.18602 8.50205L3.32002 7.14672C2.79802 6.76672 3.06669 5.94005 3.71202 5.94005H6.01936C6.16006 5.94009 6.29716 5.89561 6.41104 5.81298C6.52492 5.73036 6.60974 5.61382 6.65336 5.48005L7.36602 3.28472Z"
        fill="#FECE00"
      />
    </svg>
  );
};
