/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons27 = ({ className }) => {
  return (
    <svg
      className={`icons-27 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 21 21"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.95801 5.73103V12.7148C6.03872 12.4465 7.87622 12.1167 10.3785 12.1167C13.0555 12.1167 15.2389 12.4942 16.4561 12.7634V5.73129C15.1099 5.91697 12.5647 6.23081 10.7114 6.23081C8.85722 6.23081 6.30615 5.91666 4.95801 5.73103ZM3.31543 5.26206C3.31543 4.50992 3.98195 3.93212 4.72685 4.03964C5.86732 4.20426 8.74737 4.58823 10.7114 4.58823C12.6747 4.58823 15.5479 4.20455 16.6868 4.03984C17.4317 3.93212 18.0986 4.50983 18.0986 5.26222V13.2615C18.0986 14.0905 17.312 14.6596 16.5459 14.4709C15.5788 14.2326 13.2888 13.7593 10.3785 13.7593C7.63132 13.7593 5.77041 14.1812 4.93631 14.4202C4.16799 14.6404 3.31543 14.0839 3.31543 13.212V5.26206Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M4.95801 16.2802L4.65576 15.5165C4.23401 15.6835 4.02743 16.1607 4.19436 16.5824C4.36123 17.0041 4.83819 17.2106 5.25985 17.044L5.27042 17.04C5.28171 17.0358 5.30085 17.0287 5.32766 17.0192C5.38129 17.0003 5.4656 16.9717 5.57931 16.9365C5.80675 16.8661 6.15155 16.7697 6.60333 16.6725C7.50698 16.4779 8.83746 16.2802 10.5126 16.2802C12.1891 16.2802 13.6213 16.4782 14.6317 16.6756C15.1363 16.7742 15.5342 16.8723 15.8028 16.9448C15.937 16.981 16.0388 17.0108 16.1054 17.031C16.1387 17.041 16.1631 17.0487 16.1784 17.0536L16.1944 17.0587L16.1963 17.0593V17.0593C16.6264 17.2022 17.0919 16.97 17.2352 16.5399C17.3786 16.1096 17.1461 15.6445 16.7158 15.5011L16.4561 16.2802C16.7158 15.5011 16.7158 15.5011 16.7158 15.5011L16.714 15.5005L16.7114 15.4996L16.7034 15.497L16.6767 15.4884C16.6542 15.4812 16.6223 15.4712 16.5814 15.4589C16.4996 15.4341 16.3818 15.3997 16.2307 15.3589C15.9285 15.2774 15.4929 15.1702 14.9467 15.0635C13.8552 14.8503 12.3157 14.6376 10.5126 14.6376C8.70821 14.6376 7.26139 14.8506 6.25762 15.0667C5.7557 15.1747 5.36426 15.2836 5.0939 15.3673C4.9587 15.4091 4.85372 15.4446 4.78027 15.4706C4.74355 15.4835 4.7147 15.4941 4.6939 15.5019L4.66874 15.5115L4.66072 15.5146L4.65786 15.5157L4.65672 15.5162C4.65672 15.5162 4.65576 15.5165 4.95801 16.2802Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
