/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair4 = ({ className }) => {
  return (
    <svg
      className={`chair-4 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_87_5962)">
        <path
          className="path"
          d="M4.91726 22.4097C6.20972 28.2539 6.85595 31.176 8.85855 33.0178C9.14311 33.2795 9.44489 33.5218 9.76186 33.7431C11.9927 35.3008 14.9853 35.3008 20.9707 35.3008L22.7641 35.3008C28.5518 35.3008 31.4457 35.3008 33.6285 33.8406C34.0501 33.5586 34.4461 33.24 34.8118 32.8886C36.7056 31.0691 37.3253 28.2424 38.5647 22.5889L38.6762 22.08C40.6227 13.2016 41.5959 8.76241 39.7337 5.53053C39.2363 4.66725 38.608 3.88632 37.8713 3.21557C35.1131 0.704497 30.5685 0.704497 21.4792 0.704497V0.704497C12.6082 0.704497 8.17268 0.704497 5.4841 3.15598C4.76601 3.81075 4.1539 4.57301 3.66965 5.41554C1.85656 8.57006 2.81435 12.9009 4.72993 21.5627L4.91726 22.4097Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M8.64683 22.3811C9.44243 26.1754 9.84023 28.0725 11.0104 29.3716C11.4614 29.8722 11.9876 30.2995 12.5702 30.6382C14.0817 31.5168 16.0201 31.5168 19.8969 31.5168H23.7822C27.5078 31.5168 29.3706 31.5168 30.8378 30.7036C31.5083 30.332 32.1076 29.8444 32.608 29.2636C33.7028 27.9926 34.0819 26.1688 34.8401 22.5212L35.4749 19.4672C36.5245 14.4178 37.0493 11.8931 36.5978 9.86728C35.9379 6.9065 33.9226 4.42917 31.158 3.18055C29.2665 2.32623 26.6879 2.32623 21.5305 2.32623V2.32623C16.4975 2.32623 13.981 2.32623 12.1364 3.16026C9.44053 4.37923 7.47679 6.79745 6.83702 9.68611C6.39929 11.6626 6.91574 14.1255 7.94864 19.0514L8.64683 22.3811Z"
          fill="#5E5E5E"
        />
        <path
          className="path"
          d="M10.0915 17.9426C10.435 18.8922 10.6068 19.3669 10.8516 19.7577C11.471 20.7467 12.4639 21.4433 13.6047 21.6894C14.0554 21.7866 14.5603 21.7866 15.5701 21.7866L28.6287 21.7866C29.7369 21.7866 30.291 21.7866 30.7799 21.6715C31.9265 21.4015 32.9133 20.6755 33.5123 19.6613C33.7677 19.2289 33.9326 18.6999 34.2625 17.6419V17.6419C35.409 13.9648 35.9823 12.1262 35.9238 10.6362C35.7852 7.10279 33.5096 4.00965 30.1776 2.82554C28.7726 2.32621 26.8467 2.32621 22.995 2.32621H21.0486C17.2263 2.32621 15.3151 2.32621 13.9415 2.80126C10.4143 4.02115 8.06741 7.366 8.12029 11.0978C8.14088 12.5511 8.79109 14.3483 10.0915 17.9426V17.9426Z"
          fill="#AFAFAF"
        />
        <path
          className="path"
          d="M21.7397 19.6243L21.7397 17.462"
          stroke="white"
          strokeLinecap="round"
          strokeWidth="1.08113"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_87_5962">
          <rect
            className="rect"
            fill="white"
            height="43.2454"
            transform="matrix(1 0 0 -1 0.117188 43.9495)"
            width="43.2454"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
