/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconAd = ({ className }) => {
  return (
    <svg
      className={`icon-ad ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5 6.15535V14.6587C6.31587 14.3321 8.5532 13.9305 11.6 13.9305C14.8595 13.9305 17.518 14.3902 19 14.7179V6.15567C17.3609 6.38174 14.2619 6.76388 12.0053 6.76388C9.74767 6.76388 6.6415 6.38137 5 6.15535ZM3 5.58433C3 4.66852 3.81155 3.965 4.71854 4.09592C6.10718 4.29636 9.61392 4.76388 12.0053 4.76388C14.3958 4.76388 17.8942 4.2967 19.281 4.09616C20.188 3.965 21 4.66841 21 5.58452V15.3244C21 16.3338 20.0422 17.0267 19.1094 16.797C17.9318 16.5069 15.1436 15.9305 11.6 15.9305C8.25501 15.9305 5.98918 16.4442 4.97358 16.7353C4.03807 17.0034 3 16.3258 3 15.2642V5.58433Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M5 19L4.63199 18.0702C4.11846 18.2734 3.86693 18.8545 4.07018 19.368C4.27336 19.8814 4.85411 20.1329 5.36752 19.93L5.3804 19.9251C5.39415 19.92 5.41744 19.9114 5.45009 19.8999C5.51538 19.8768 5.61805 19.8419 5.75649 19.7991C6.03343 19.7134 6.45325 19.596 7.00334 19.4776C8.10362 19.2407 9.72361 19 11.7633 19C13.8046 19 15.5484 19.2411 16.7787 19.4814C17.3931 19.6015 17.8775 19.721 18.2045 19.8092C18.368 19.8533 18.492 19.8896 18.573 19.9141C18.6135 19.9264 18.6433 19.9357 18.6619 19.9416L18.6814 19.9479L18.6838 19.9487C19.2075 20.1227 19.7741 19.8398 19.9487 19.3162C20.1233 18.7923 19.8402 18.226 19.3162 18.0513L19 19C19.3162 18.0513 19.3162 18.0513 19.3162 18.0513L19.3141 18.0506L19.3109 18.0495L19.3011 18.0463L19.2686 18.0359C19.2412 18.0272 19.2024 18.015 19.1526 17.9999C19.0531 17.9698 18.9095 17.9279 18.7256 17.8783C18.3577 17.779 17.8273 17.6485 17.1622 17.5186C15.8332 17.2589 13.9587 17 11.7633 17C9.56624 17 7.80459 17.2593 6.58241 17.5224C5.97127 17.654 5.49465 17.7866 5.16546 17.8884C5.00084 17.9393 4.87301 17.9826 4.78359 18.0142C4.73888 18.03 4.70375 18.0429 4.67842 18.0524L4.64779 18.064L4.63803 18.0678L4.63454 18.0692L4.63315 18.0697C4.63315 18.0697 4.63199 18.0702 5 19Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
