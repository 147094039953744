/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Star18 = ({ className }) => {
  return (
    <svg
      className={`star-18 ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.92444 3.86456C9.14974 3.17291 10.1283 3.17291 10.3528 3.86456L11.1563 6.33678C11.2054 6.48734 11.3009 6.61853 11.429 6.71159C11.5571 6.80466 11.7114 6.85483 11.8698 6.85495H14.4697C15.1973 6.85495 15.4992 7.78616 14.9112 8.21422L12.8085 9.74171C12.6801 9.83485 12.5845 9.96625 12.5354 10.1171C12.4863 10.2679 12.4862 10.4304 12.5351 10.5813L13.3387 13.0535C13.564 13.7452 12.7717 14.3212 12.1822 13.8931L10.0794 12.3656C9.95118 12.2725 9.79675 12.2223 9.63825 12.2223C9.47975 12.2223 9.32531 12.2725 9.19705 12.3656L7.09431 13.8931C6.50555 14.3212 5.71402 13.7452 5.93856 13.0535L6.74211 10.5813C6.79105 10.4304 6.79096 10.2679 6.74185 10.1171C6.69275 9.96625 6.59714 9.83485 6.46875 9.74171L4.36677 8.21497C3.77875 7.78691 4.0814 6.8557 4.80834 6.8557H7.40747C7.56596 6.85574 7.7204 6.80564 7.84869 6.71256C7.97697 6.61949 8.07252 6.48821 8.12165 6.33753L8.92444 3.86456Z"
        stroke="#868686"
        strokeWidth="0.750977"
      />
    </svg>
  );
};
