/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconQz } from "../../icons/IconQz";
import { Icons13 } from "../../icons/Icons13";
import { Icons17 } from "../../icons/Icons17";
import { Icons25 } from "../../icons/Icons25";
import { Icons29 } from "../../icons/Icons29";
import { Icons4 } from "../../icons/Icons4";
import { Icons9 } from "../../icons/Icons9";
import { Icons } from "../Icons";
import { NavLink } from "../NavLink";
import "./style.css";

export const Menu = ({
  property1,
  className,
  navLinkIcon = <Icons29 className="icon-instance-node" />,
  override = <Icons4 className="icon-instance-node" />,
  navLinkIcon1 = <Icons25 className="icon-instance-node" />,
  navLinkIcon2 = <IconQz className="icon-instance-node" />,
  navLinkIcon3 = <Icons9 className="icon-instance-node" />,
  navLinkIcon4 = <Icons17 className="icons-17" />,
  navLinkIcon5 = <Icons4 className="icons-17" />,
  navLinkIcon6 = <Icons13 className="icons-17" />,
  navLinkIcon7 = <IconQz className="icons-17" />,
  navLinkIcon8 = <Icons9 className="icons-17" />,
}) => {
  return (
    <div className={`menu property-1-${property1} ${className}`}>
      {property1 === "default" && (
        <>
          <div className="frame-5">
            <NavLink
              className="nav-link-instance"
              divClassName="nav-link-2"
              icon={navLinkIcon}
              override={navLinkIcon4}
              stateProp="default"
              text="Dashboard"
              type="default"
            />
            <div className="nav-link-3">
              <Icons icon="RC" iconRcClassName="icons-instance" union="/img/union-3.svg" unionClassName="icons-2" />
              <div className="dashboard-2">Resource Center</div>
            </div>
            <NavLink
              className="nav-link-instance"
              divClassName="nav-link-2"
              icon={override}
              override={navLinkIcon5}
              stateProp="default"
              text="Learning Center"
              type="default"
            />
            <NavLink
              className="nav-link-instance"
              divClassName="nav-link-2"
              icon={navLinkIcon1}
              override={navLinkIcon6}
              stateProp="default"
              text="Auditorium"
              type="default"
            />
            <div className="nav-link-3">
              <Icons
                divClassName="icons-7"
                divClassNameOverride="icons-8"
                ellipseClassName="icons-5"
                ellipseClassNameOverride="icons-6"
                icon="MT"
                iconRcClassName="icons-instance"
                overlapGroupClassName="icons-4"
                unionClassName="icons-3"
              />
              <div className="dashboard-2">Meetings</div>
            </div>
            <NavLink
              className="nav-link-instance"
              divClassName="nav-link-2"
              icon={navLinkIcon2}
              override={navLinkIcon7}
              stateProp="default"
              text="Quizzes"
              type="default"
            />
            <NavLink
              className="nav-link-instance"
              divClassName="nav-link-2"
              icon={navLinkIcon3}
              override={navLinkIcon8}
              stateProp="default"
              text="Manage"
              type="default"
            />
          </div>
          <div className="frame-6">
            <NavLink
              className="nav-link-4"
              divClassName="nav-link-2"
              stateProp="default"
              text="Logout"
              type="default"
            />
            <div className="extra">
              <div className="text-wrapper-10">Policies</div>
              <div className="interpunct">
                <div className="ellipse-8" />
              </div>
              <div className="text-wrapper-10">Terms &amp; Conditions</div>
            </div>
          </div>
        </>
      )}

      {property1 === "collapsed" && (
        <>
          <div className="frame-7">
            <NavLink
              className="nav-link-5"
              icon={navLinkIcon}
              override={navLinkIcon4}
              stateProp="default"
              type="collapsed"
            />
            <div className="icons-wrapper">
              <Icons icon="RC" iconRcClassName="icons-9" union="/img/union-1.svg" unionClassName="icons-10" />
            </div>
            <NavLink
              className="nav-link-5"
              icon={override}
              override={navLinkIcon5}
              stateProp="default"
              type="collapsed"
            />
            <NavLink
              className="nav-link-5"
              icon={navLinkIcon1}
              override={navLinkIcon6}
              stateProp="default"
              type="collapsed"
            />
            <div className="icons-instance-wrapper">
              <Icons
                divClassName="icons-15"
                divClassNameOverride="icons-16"
                ellipseClassName="icons-13"
                ellipseClassNameOverride="icons-14"
                icon="MT"
                iconRcClassName="icons-9"
                overlapGroupClassName="icons-12"
                unionClassName="icons-11"
              />
            </div>
            <NavLink
              className="nav-link-5"
              icon={navLinkIcon2}
              override={navLinkIcon7}
              stateProp="default"
              type="collapsed"
            />
            <NavLink
              className="nav-link-5"
              icon={navLinkIcon3}
              override={navLinkIcon8}
              stateProp="default"
              type="collapsed"
            />
          </div>
          <NavLink className="nav-link-5" stateProp="default" type="collapsed" />
        </>
      )}

      <img
        className="chevron-down"
        alt="Chevron down"
        src={property1 === "collapsed" ? "/img/chevron-down-1.svg" : "/img/chevron-down-3.svg"}
      />
    </div>
  );
};

Menu.propTypes = {
  property1: PropTypes.oneOf(["collapsed", "default"]),
};
