/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ButtonWrapper } from "../ButtonWrapper";
import "./style.css";

export const ButtonHover = ({ className }) => {
  return (
    <div className={`button-hover ${className}`}>
      <ButtonWrapper
        add="/img/add-6.png"
        addClassName="button-6"
        className="button-4"
        divClassName="button-7"
        frameClassName="button-5"
        text="New"
      />
    </div>
  );
};
