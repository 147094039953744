/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon12 = ({ className }) => {
  return (
    <svg
      className={`icon-12 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 11 14"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.73882 4.44602L7.23535 7.74403L4.73882 9.63386"
        stroke="#868686"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.29696"
      />
    </svg>
  );
};
