/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons13 = ({ className }) => {
  return (
    <svg
      className={`icons-13 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 27 27"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5.81109 7.24878V16.4416C7.23365 16.0885 9.65239 15.6544 12.9462 15.6544C16.47 15.6544 19.344 16.1513 20.9462 16.5056V7.24912C19.1742 7.49353 15.824 7.90665 13.3844 7.90665C10.9437 7.90665 7.58568 7.49312 5.81109 7.24878ZM3.64893 6.63145C3.64893 5.64139 4.52628 4.88083 5.50681 5.02236C7.00804 5.23905 10.7991 5.74448 13.3844 5.74448C15.9687 5.74448 19.7508 5.23943 21.25 5.02263C22.2305 4.88083 23.1084 5.64128 23.1084 6.63167V17.1613C23.1084 18.2525 22.0729 19.0016 21.0645 18.7532C19.7914 18.4396 16.7771 17.8166 12.9462 17.8166C9.33002 17.8166 6.88047 18.3719 5.78253 18.6865C4.77117 18.9764 3.64893 18.2438 3.64893 17.0961V6.63145Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M5.81109 21.1349L5.41324 20.1297C4.85807 20.3494 4.58615 20.9776 4.80588 21.5327C5.02553 22.0877 5.65337 22.3596 6.20841 22.1403L6.22233 22.135C6.23719 22.1295 6.26238 22.1202 6.29767 22.1077C6.36826 22.0828 6.47925 22.0451 6.62892 21.9988C6.92831 21.9061 7.38217 21.7792 7.97686 21.6512C9.16635 21.3951 10.9177 21.1349 13.1227 21.1349C15.3295 21.1349 17.2148 21.3955 18.5448 21.6554C19.2091 21.7851 19.7327 21.9143 20.0863 22.0097C20.263 22.0574 20.397 22.0966 20.4846 22.1231C20.5284 22.1364 20.5606 22.1465 20.5807 22.1529L20.6018 22.1596L20.6044 22.1605V22.1605C21.1705 22.3486 21.7831 22.0428 21.9718 21.4767C22.1606 20.9103 21.8545 20.2981 21.2881 20.1093L20.9462 21.1349C21.2881 20.1093 21.2881 20.1093 21.2881 20.1093L21.2858 20.1085L21.2823 20.1074L21.2718 20.1039L21.2366 20.0926C21.207 20.0832 21.165 20.07 21.1112 20.0537C21.0036 20.0211 20.8484 19.9759 20.6495 19.9222C20.2518 19.8149 19.6784 19.6738 18.9594 19.5333C17.5227 19.2526 15.4962 18.9727 13.1227 18.9727C10.7476 18.9727 8.84308 19.253 7.5218 19.5375C6.86111 19.6797 6.34584 19.8231 5.98996 19.9332C5.812 19.9882 5.67381 20.035 5.57713 20.0691C5.52879 20.0862 5.49082 20.1002 5.46343 20.1104L5.43032 20.123L5.41976 20.1271L5.416 20.1286L5.41449 20.1292C5.41449 20.1292 5.41324 20.1297 5.81109 21.1349Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
