/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const ButtonVariant12 = ({ className }) => {
  return (
    <button className={`button-variant12 ${className}`}>
      <div className="frame-4">
        <img className="add-3" alt="Add" src="/img/add-2.png" />
        <div className="text-wrapper-9">New Course</div>
      </div>
    </button>
  );
};
