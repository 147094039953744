/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ButtonWrapper = ({
  className,
  frameClassName,
  addClassName,
  add = "/img/add-15.png",
  divClassName,
  text = "New",
}) => {
  return (
    <button className={`button-wrapper ${className}`}>
      <div className={`frame-2 ${frameClassName}`}>
        <img className={`add ${addClassName}`} alt="Add" src={add} />
        <div className={`new ${divClassName}`}>{text}</div>
      </div>
    </button>
  );
};

ButtonWrapper.propTypes = {
  add: PropTypes.string,
  text: PropTypes.string,
};
