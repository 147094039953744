/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconLg = ({ className }) => {
  return (
    <svg
      className={`icon-lg ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M11.8162 13.3797C11.5195 13.6764 11.0384 13.6764 10.7417 13.3797L7.57602 10.214C7.43353 10.0715 7.35348 9.87825 7.35348 9.67675C7.35348 9.47525 7.43353 9.282 7.57602 9.13952L10.7417 5.97383C11.0384 5.67712 11.5195 5.67712 11.8162 5.97383C12.1129 6.27053 12.1129 6.75159 11.8162 7.0483L9.18772 9.67675L11.8162 12.3052C12.1129 12.6019 12.1129 13.083 11.8162 13.3797Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M16.4707 9.67676C16.4707 10.0964 16.1305 10.4365 15.7109 10.4365H8.11328C7.69367 10.4365 7.35348 10.0964 7.35348 9.67675C7.35348 9.25714 7.69367 8.91699 8.11328 8.91699H15.7109C16.1305 8.91699 16.4707 9.25715 16.4707 9.67676Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M8.87305 15.375C8.87305 15.7946 8.53289 16.1348 8.11328 16.1348H5.58073C5.04339 16.1348 4.52806 15.9213 4.1481 15.5414C3.76815 15.1614 3.55469 14.6461 3.55469 14.1087V5.24479C3.55469 4.70745 3.76815 4.19212 4.1481 3.81216C4.52806 3.43221 5.04339 3.21875 5.58073 3.21875H8.11328C8.53289 3.21875 8.87305 3.55891 8.87305 3.97852C8.87305 4.39812 8.53289 4.73828 8.11328 4.73828H5.58073C5.4464 4.73828 5.31756 4.79165 5.22257 4.88664C5.12758 4.98162 5.07422 5.11046 5.07422 5.24479V14.1087C5.07422 14.2431 5.12758 14.3719 5.22257 14.4669C5.31756 14.5619 5.44639 14.6152 5.58073 14.6152H8.11328C8.53289 14.6152 8.87305 14.9554 8.87305 15.375Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
