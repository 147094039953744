/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair21 = ({ className }) => {
  return (
    <svg
      className={`chair-21 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.94862 21.7519C6.24108 15.9077 6.88731 12.9857 8.88992 11.1439C9.17447 10.8821 9.47625 10.6398 9.79323 10.4185C12.024 8.86084 15.0167 8.86084 21.0021 8.86084H22.7954C28.5832 8.86084 31.4771 8.86084 33.6599 10.321C34.0815 10.603 34.4774 10.9216 34.8432 11.273C36.7369 13.0925 37.3566 15.9192 38.5961 21.5727L38.7076 22.0816C40.654 30.96 41.6272 35.3992 39.7651 38.6311C39.2676 39.4944 38.6394 40.2753 37.9026 40.946C35.1445 43.4571 30.5999 43.4571 21.5106 43.4571V43.4571C12.6396 43.4571 8.20404 43.4571 5.51546 41.0056C4.79737 40.3509 4.18527 39.5886 3.70101 38.7461C1.88793 35.5916 2.84572 31.2607 4.7613 22.5989L4.94862 21.7519Z"
        fill="#202020"
      />
      <path
        className="path"
        d="M8.6782 21.7805C9.4738 17.9863 9.87161 16.0891 11.0418 14.7901C11.4928 14.2894 12.019 13.8621 12.6015 13.5235C14.1131 12.6448 16.0515 12.6448 19.9283 12.6448L23.8136 12.6448C27.5392 12.6448 29.402 12.6448 30.8692 13.458C31.5397 13.8297 32.139 14.3172 32.6393 14.898C33.7341 16.169 34.1133 17.9928 34.8715 21.6405L35.5063 24.6944C36.5559 29.7438 37.0806 32.2685 36.6292 34.2943C35.9693 37.2551 33.9539 39.7325 31.1894 40.9811C29.2979 41.8354 26.7192 41.8354 21.5619 41.8354V41.8354C16.5288 41.8354 14.0123 41.8354 12.1678 41.0014C9.4719 39.7824 7.50816 37.3642 6.86839 34.4755C6.43066 32.4991 6.94711 30.0361 7.98001 25.1102L8.6782 21.7805Z"
        fill="#5E5E5E"
      />
      <path
        className="path"
        d="M10.1229 26.219C10.4664 25.2695 10.6382 24.7947 10.8829 24.4039C11.5024 23.4149 12.4953 22.7183 13.6361 22.4722C14.0868 22.375 14.5917 22.375 15.6014 22.375L28.6601 22.375C29.7683 22.375 30.3224 22.375 30.8113 22.4901C31.9578 22.7601 32.9446 23.4861 33.5436 24.5003C33.7991 24.9328 33.964 25.4617 34.2939 26.5197V26.5197C35.4404 30.1968 36.0137 32.0354 35.9552 33.5254C35.8166 37.0588 33.541 40.152 30.209 41.3361C28.8039 41.8354 26.8781 41.8354 23.0263 41.8354H21.08C17.2576 41.8354 15.3465 41.8354 13.9729 41.3604C10.4457 40.1405 8.09878 36.7956 8.15166 33.0638C8.17225 31.6105 8.82246 29.8134 10.1229 26.219V26.219Z"
        fill="#AFAFAF"
      />
      <path
        className="path"
        d="M21.7711 24.5374L21.7711 26.6996"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.08113"
      />
    </svg>
  );
};
