/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons41 = ({ className }) => {
  return (
    <svg
      className={`icons-41 ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 20 19"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.0184 4.67977L5.25824 7.01649L10.0186 9.35361L14.7788 7.01688L10.0184 4.67977ZM10.5969 3.50151L15.3573 5.83863C16.008 6.15817 16.2766 6.94466 15.9571 7.5954C15.829 7.85618 15.618 8.06715 15.3572 8.19524L10.5971 10.5319C10.2322 10.711 9.805 10.711 9.44013 10.5319L4.67973 8.19475C4.02899 7.87531 3.76044 7.08872 4.07998 6.43798C4.20799 6.1772 4.41904 5.96623 4.67983 5.83823L9.43998 3.50151C9.80485 3.32241 10.2321 3.32241 10.5969 3.50151V3.50151ZM4.43903 10.628C4.11021 10.4755 3.96727 10.0853 4.11978 9.75647C4.27228 9.42765 4.66251 9.28472 4.99133 9.43722L9.95893 11.7415L10.0188 11.7731L10.0786 11.7415L15.046 9.43771C15.3748 9.28522 15.765 9.42815 15.9175 9.75696C16.07 10.0858 15.9271 10.476 15.5983 10.6285L10.6564 12.9205C10.26 13.1408 9.77756 13.1408 9.38122 12.9205L4.43903 10.628ZM4.43903 13.2533C4.11021 13.1007 3.96727 12.7105 4.11978 12.3817C4.27228 12.0529 4.66251 11.91 4.99133 12.0625L9.95893 14.3667L10.0188 14.3983L10.0786 14.3667L15.046 12.0629C15.3748 11.9104 15.765 12.0534 15.9175 12.3822C16.07 12.711 15.9271 13.1011 15.5983 13.2536L10.6564 15.5457C10.26 15.766 9.77756 15.766 9.38122 15.5456L4.43903 13.2533Z"
        fill="#363636"
      />
    </svg>
  );
};
