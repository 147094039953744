/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Radio = ({ checked, className, ellipseClassName }) => {
  return (
    <div className={`radio checked-${checked} ${className}`}>
      <div className={`ellipse-4 ${ellipseClassName}`} />
    </div>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
};
