/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Star = ({ className }) => {
  return (
    <svg
      className={`star ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.60876 3.96517C7.7987 3.38205 8.62368 3.38205 8.81298 3.96517L9.49044 6.04946C9.53183 6.1764 9.61229 6.287 9.72032 6.36546C9.82834 6.44393 9.95841 6.48623 10.0919 6.48633H12.2838C12.8974 6.48633 13.1519 7.27142 12.6561 7.63231L10.8833 8.92011C10.7751 8.99864 10.6945 9.10942 10.6531 9.23659C10.6117 9.36375 10.6116 9.50075 10.6529 9.62796L11.3303 11.7123C11.5203 12.2954 10.8523 12.781 10.3553 12.4201L8.58252 11.1323C8.47439 11.0538 8.34418 11.0115 8.21055 11.0115C8.07692 11.0115 7.94672 11.0538 7.83858 11.1323L6.0658 12.4201C5.56942 12.781 4.90209 12.2954 5.0914 11.7123L5.76886 9.62796C5.81012 9.50075 5.81005 9.36375 5.76864 9.23659C5.72724 9.10942 5.64664 8.99864 5.53839 8.92011L3.76624 7.63294C3.27049 7.27205 3.52565 6.48696 4.13853 6.48696H6.32982C6.46344 6.487 6.59365 6.44475 6.7018 6.36628C6.80995 6.28781 6.89051 6.17714 6.93193 6.0501L7.60876 3.96517Z"
        stroke="white"
        strokeWidth="0.759766"
      />
    </svg>
  );
};
