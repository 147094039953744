/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const TwentyArrowChevronDown2 = ({ className }) => {
  return (
    <svg
      className={`twenty-arrow-chevron-down-2 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M14.9643 7.74408C15.2897 8.06951 15.2897 8.59715 14.9643 8.92259L10.3809 13.5059C10.0555 13.8314 9.52787 13.8314 9.20244 13.5059L4.6191 8.92259C4.29367 8.59715 4.29367 8.06951 4.6191 7.74408C4.94454 7.41864 5.47218 7.41864 5.79761 7.74408L9.79169 11.7382L13.7858 7.74408C14.1112 7.41864 14.6388 7.41864 14.9643 7.74408Z"
        fill="#1E202C"
        fillRule="evenodd"
      />
    </svg>
  );
};
