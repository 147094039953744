/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Endpoint210 = ({ color = "white", className }) => {
  return (
    <svg
      className={`endpoint-2-10 ${className}`}
      fill="none"
      height="8"
      viewBox="0 0 8 8"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 4L8 0V8L0 4Z" fill={color} />
    </svg>
  );
};

Endpoint210.propTypes = {
  color: PropTypes.string,
};
