/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Star1 = ({ className }) => {
  return (
    <svg
      className={`star-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.60876 3.55502C7.7987 2.9719 8.62368 2.9719 8.81298 3.55502L9.49044 5.63931C9.53183 5.76624 9.61229 5.87685 9.72032 5.95531C9.82834 6.03377 9.95841 6.07607 10.0919 6.07617H12.2838C12.8974 6.07617 13.1519 6.86126 12.6561 7.22215L10.8833 8.50995C10.7751 8.58848 10.6945 8.69927 10.6531 8.82643C10.6117 8.95359 10.6116 9.0906 10.6529 9.2178L11.3303 11.3021C11.5203 11.8852 10.8523 12.3708 10.3553 12.0099L8.58252 10.7221C8.47439 10.6436 8.34418 10.6014 8.21055 10.6014C8.07692 10.6014 7.94672 10.6436 7.83858 10.7221L6.0658 12.0099C5.56942 12.3708 4.90209 11.8852 5.0914 11.3021L5.76886 9.2178C5.81012 9.0906 5.81005 8.95359 5.76864 8.82643C5.72724 8.69927 5.64664 8.58848 5.53839 8.50995L3.76624 7.22278C3.27049 6.8619 3.52565 6.0768 4.13853 6.0768H6.32982C6.46344 6.07684 6.59365 6.0346 6.7018 5.95613C6.80995 5.87766 6.89051 5.76698 6.93193 5.63994L7.60876 3.55502Z"
        stroke="white"
        strokeWidth="0.759766"
      />
    </svg>
  );
};
