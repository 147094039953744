/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons31 = ({ className }) => {
  return (
    <svg
      className={`icons-31 ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 9 9"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.03785 1.63452C2.80532 1.63452 1.80615 2.63369 1.80615 3.86622C1.80615 5.09876 2.80532 6.09792 4.03785 6.09792C4.53914 6.09792 5.00182 5.93265 5.37438 5.65362L6.68177 6.96101C6.80627 7.08552 7.00814 7.08552 7.13264 6.96101C7.25715 6.83651 7.25715 6.63464 7.13264 6.51014L5.82525 5.20275C6.10428 4.83019 6.26955 4.3675 6.26955 3.86622C6.26955 2.63369 5.27039 1.63452 4.03785 1.63452ZM2.44378 3.86622C2.44378 2.98584 3.15747 2.27215 4.03785 2.27215C4.91823 2.27215 5.63192 2.98584 5.63192 3.86622C5.63192 4.7466 4.91823 5.46029 4.03785 5.46029C3.15747 5.46029 2.44378 4.7466 2.44378 3.86622Z"
        fill="#B7B7B7"
        fillRule="evenodd"
      />
    </svg>
  );
};
