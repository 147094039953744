/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const VideoProgress = ({
  playing,
  className,
  overlapGroupClassName,
  backgroundClassName,
  bufferingClassName,
  bufferingProgressClassName,
  progressLineClassName,
  playClassName,
  pause = "/img/pause-1.svg",
}) => {
  return (
    <div className={`video-progress ${className}`}>
      <div className={`overlap-group-2 ${overlapGroupClassName}`}>
        <div className={`background ${backgroundClassName}`} />
        <div className={`buffering ${bufferingClassName}`}>
          <div className={`buffering-progress playing-${playing} ${bufferingProgressClassName}`} />
        </div>
        <div className={`progress-line playing-0-${playing} ${progressLineClassName}`} />
      </div>
      <img className={`play ${playClassName}`} alt="Play" src={playing ? pause : "/img/play.svg"} />
    </div>
  );
};

VideoProgress.propTypes = {
  playing: PropTypes.bool,
  pause: PropTypes.string,
};
