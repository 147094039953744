/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair5 = ({ className }) => {
  return (
    <svg
      className={`chair-5 ${className}`}
      fill="none"
      height="6"
      viewBox="0 0 59 6"
      width="59"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_57_1473)">
        <path
          className="path"
          d="M38.7515 8.14606C33.5055 7.60656 30.8826 7.33681 28.3243 8.07181C27.9607 8.17625 27.6024 8.29468 27.2517 8.4263C24.7833 9.35264 23.0856 11.0504 19.69 14.446L18.6726 15.4634C15.3891 18.7468 13.7474 20.3886 13.6366 21.9449C13.6152 22.2455 13.6366 22.5395 13.7004 22.8235C14.031 24.2941 15.8622 25.2613 19.5246 27.1956L19.8542 27.3697C25.6057 30.4075 28.4814 31.9263 32.0335 31.5737C32.9823 31.4795 33.9417 31.2932 34.8776 31.0213C38.3813 30.0034 40.9595 27.4252 46.116 22.2687V22.2687C51.1486 17.2361 53.6649 14.7198 53.2972 12.6606C53.199 12.1107 52.9577 11.5974 52.5818 11.1392C51.1745 9.42364 47.287 9.02384 39.5118 8.22425L38.7515 8.14606Z"
          fill="#202020"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_57_1473">
          <rect
            className="rect"
            fill="white"
            height="34.6957"
            transform="matrix(-0.707107 0.707107 0.962455 0.271441 24.8418 0.731934)"
            width="34.6957"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
