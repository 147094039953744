/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Endpoint24 } from "../../icons/Endpoint24";
import { Endpoint266 } from "../../icons/Endpoint266";
import { Endpoint } from "../Endpoint";
import { Midsection } from "../Midsection";
import "./style.css";

export const Line = ({
  type,
  lineType,
  direction,
  className,
  elementClassName,
  element = "/img/90-6.svg",
  icon = <Endpoint266 className="endpoint-2-66" />,
  prop,
  prop1 = "rectangle",
  hasElement = true,
  lineClassName,
  line = "/img/line-125.svg",
  override = <Endpoint24 className="endpoint-2-5" color="black" />,
  img = "/img/line-126.svg",
  endpointShapeRectangleClassName,
  endpointShapeMeasure = "/img/shape-measure.svg",
  lineClassNameOverride,
  line1 = "/img/line-112.svg",
  midsectionNumberClassName,
  midsectionTypeEqualClassName,
  midsectionText = "12px",
  imgClassName,
  line2 = "/img/line-111.svg",
  endpointShapeRectangleClassNameOverride,
  endpointImg = "/img/endpoint-2-12.svg",
}) => {
  return (
    <div className={`line ${type} ${lineType} ${direction} ${className}`}>
      {((lineType === "dashed" && type === "ninety") ||
        (lineType === "solid" && type === "ninety") ||
        type === "arrow" ||
        type === "measure-gap" ||
        type === "measure") && (
        <>
          <>
            {hasElement && (
              <>
                <>
                  {["arrow", "measure-gap", "measure"].includes(type) && (
                    <Endpoint
                      shape={type === "arrow" ? "rectangle" : "measure"}
                      shapeMeasure={
                        direction === "horizontal" && type === "measure"
                          ? "/img/shape-measure.svg"
                          : direction === "horizontal" &&
                            type === "measure-gap" &&
                            ["dashed", "solid"].includes(lineType)
                          ? "/img/endpoint-1-44.svg"
                          : direction === "horizontal" && type === "measure-gap" && lineType === "dotted"
                          ? "/img/endpoint-1-46.svg"
                          : type === "measure" && direction === "vertical"
                          ? "/img/endpoint-1-18.svg"
                          : direction === "vertical" && type === "measure-gap" && ["dashed", "solid"].includes(lineType)
                          ? "/img/endpoint-1-21.svg"
                          : direction === "vertical" && type === "measure-gap" && lineType === "dotted"
                          ? "/img/endpoint-1-23.svg"
                          : undefined
                      }
                      shapeRectangleClassName={`${
                        direction === "horizontal" && type === "arrow"
                          ? "class"
                          : type === "arrow" && direction === "vertical"
                          ? "class-2"
                          : direction === "horizontal" && type === "measure-gap" && lineType === "dotted"
                          ? "class-3"
                          : (direction === "vertical" && lineType === "dashed" && type === "measure-gap") ||
                            (direction === "vertical" && lineType === "solid" && type === "measure-gap") ||
                            (direction === "vertical" && type === "measure")
                          ? "class-4"
                          : direction === "vertical" && type === "measure-gap" && lineType === "dotted"
                          ? "class-5"
                          : "class-6"
                      }`}
                    />
                  )}

                  {type === "ninety" && (
                    <img
                      className={`img ${elementClassName}`}
                      alt="Element"
                      src={
                        lineType === "solid" && direction === "vertical"
                          ? "/img/90-5.svg"
                          : direction === "vertical" && lineType === "dashed"
                          ? "/img/90-3.svg"
                          : direction === "horizontal" && lineType === "dashed"
                          ? "/img/90-1.svg"
                          : element
                      }
                    />
                  )}
                </>
              </>
            )}
          </>
        </>
      )}

      {type === "ninety" && ["dashed", "solid"].includes(lineType) && <>{icon}</>}

      {type === "ninety" && lineType === "dotted" && (
        <div className="overlap-group-2">
          <img className="element-2" alt="Element" src={direction === "vertical" ? "/img/90-4.svg" : "/img/90-2.svg"} />
          {direction === "vertical" && <Endpoint24 className="endpoint-2-4" color="black" />}

          {direction === "horizontal" && <Endpoint266 className="endpoint-2-66" />}

          <Endpoint
            shape="rectangle"
            shapeRectangleClassName={`${direction === "horizontal" ? "class-7" : "class-8"}`}
          />
        </div>
      )}

      {["arrow", "measure-gap", "measure"].includes(type) && (
        <img
          className={`img-2 ${lineClassName}`}
          alt="Line"
          src={
            type === "arrow" && direction === "vertical" && lineType === "solid"
              ? line
              : type === "arrow" && direction === "vertical" && lineType === "dotted"
              ? "/img/line-124.svg"
              : type === "arrow" && direction === "vertical" && lineType === "dashed"
              ? "/img/line-123.svg"
              : type === "arrow" && direction === "horizontal" && lineType === "dotted"
              ? "/img/line-122.svg"
              : type === "arrow" && direction === "horizontal" && lineType === "dashed"
              ? "/img/line-121.svg"
              : lineType === "solid" && type === "measure" && direction === "horizontal"
              ? "/img/line-120.svg"
              : lineType === "solid" && type === "measure-gap"
              ? "/img/line-79.svg"
              : direction === "horizontal" && type === "measure" && lineType === "dotted"
              ? "/img/line-118.svg"
              : type === "measure-gap" && lineType === "dotted"
              ? "/img/line-77.svg"
              : direction === "horizontal" && type === "measure" && lineType === "dashed"
              ? "/img/line-116.svg"
              : type === "measure-gap" && lineType === "dashed"
              ? "/img/line-75.svg"
              : lineType === "solid" && type === "measure" && direction === "vertical"
              ? "/img/line-80.svg"
              : type === "measure" && direction === "vertical" && lineType === "dotted"
              ? "/img/line-78.svg"
              : type === "measure" && direction === "vertical" && lineType === "dashed"
              ? "/img/line-76.svg"
              : img
          }
        />
      )}

      {((lineType === "dashed" && type === "ninety") ||
        (lineType === "solid" && type === "ninety") ||
        type === "arrow" ||
        type === "measure-gap" ||
        type === "measure") && <>{override}</>}

      {["cut", "dotted", "equal", "numbers", "squiggle", "zigzag"].includes(type) && (
        <>
          <Endpoint
            shape="measure"
            shapeMeasure={endpointShapeMeasure}
            shapeRectangleClassName={endpointShapeRectangleClassName}
          />
          <img
            className={`img-3 ${lineClassNameOverride}`}
            alt="Line"
            src={
              lineType === "solid" && direction === "horizontal" && type === "equal"
                ? "/img/line-113.svg"
                : lineType === "solid" && direction === "horizontal" && type === "numbers"
                ? line1
                : lineType === "solid" && type === "equal" && direction === "vertical"
                ? "/img/line-109.svg"
                : type === "equal" && direction === "vertical" && lineType === "dotted"
                ? "/img/line-108.svg"
                : type === "equal" && direction === "vertical" && lineType === "dashed"
                ? "/img/line-105.svg"
                : direction === "vertical" && lineType === "dotted" && ["dotted", "cut"].includes(type)
                ? "/img/line-96.svg"
                : direction === "vertical" && lineType === "dashed" && ["cut", "dotted"].includes(type)
                ? "/img/line-93.svg"
                : direction === "vertical" && lineType === "solid" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-85.svg"
                : direction === "vertical" && lineType === "dotted" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-84.svg"
                : direction === "vertical" && lineType === "dashed" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-81.svg"
                : lineType === "solid" && direction === "vertical" && type === "numbers"
                ? "/img/line-73.svg"
                : direction === "vertical" && type === "numbers" && lineType === "dotted"
                ? "/img/line-72.svg"
                : direction === "vertical" && type === "numbers" && lineType === "dashed"
                ? "/img/line-69.svg"
                : direction === "horizontal" && type === "equal" && lineType === "dotted"
                ? "/img/line-68.svg"
                : direction === "horizontal" && type === "numbers" && lineType === "dotted"
                ? "/img/line-66.svg"
                : direction === "horizontal" && type === "equal" && lineType === "dashed"
                ? "/img/line-63.svg"
                : direction === "horizontal" && type === "numbers" && lineType === "dashed"
                ? "/img/line-62.svg"
                : direction === "horizontal" && lineType === "solid" && ["cut", "dotted"].includes(type)
                ? "/img/line-53.svg"
                : direction === "horizontal" && lineType === "dotted" && ["dotted", "cut"].includes(type)
                ? "/img/line-52.svg"
                : direction === "horizontal" && lineType === "dashed" && ["cut", "dotted"].includes(type)
                ? "/img/line-45.svg"
                : direction === "horizontal" && lineType === "solid" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-30.svg"
                : direction === "horizontal" && lineType === "dotted" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-29.svg"
                : direction === "horizontal" && lineType === "dashed" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-26.svg"
                : "/img/line-97.svg"
            }
          />
          <Midsection
            numberClassName={midsectionNumberClassName}
            text={midsectionText}
            type={
              type === "numbers"
                ? "number"
                : type === "cut"
                ? "cut"
                : type === "dotted"
                ? "dotted"
                : type === "squiggle"
                ? "squiggle"
                : type === "zigzag"
                ? "zigzag"
                : "equal"
            }
            typeCut={
              direction === "vertical" && type === "cut"
                ? "/img/midsection-23.svg"
                : direction === "horizontal" && type === "cut"
                ? "/img/midsection-9.svg"
                : undefined
            }
            typeDotted={
              direction === "vertical" && type === "dotted"
                ? "/img/midsection-20.svg"
                : direction === "horizontal" && type === "dotted"
                ? "/img/midsection-6.svg"
                : undefined
            }
            typeEqual={
              direction === "horizontal" && type === "equal"
                ? "/img/midsection-12.svg"
                : type === "equal" && direction === "vertical"
                ? "/img/midsection-26.svg"
                : undefined
            }
            typeEqualClassName={midsectionTypeEqualClassName}
            typeSquiggle={
              direction === "vertical" && type === "squiggle"
                ? "/img/midsection-17.svg"
                : direction === "horizontal" && type === "squiggle"
                ? "/img/midsection.svg"
                : undefined
            }
            typeZigzag={
              direction === "vertical" && type === "zigzag"
                ? "/img/midsection-14.svg"
                : direction === "horizontal" && type === "zigzag"
                ? "/img/midsection-3.svg"
                : undefined
            }
          />
          <img
            className={`img-4 ${imgClassName}`}
            alt="Line"
            src={
              lineType === "solid" && direction === "horizontal" && type === "equal"
                ? "/img/line-113.svg"
                : lineType === "solid" && direction === "horizontal" && type === "numbers"
                ? line2
                : lineType === "solid" && type === "equal" && direction === "vertical"
                ? "/img/line-109.svg"
                : type === "equal" && direction === "vertical" && lineType === "dotted"
                ? "/img/line-107.svg"
                : type === "equal" && direction === "vertical" && lineType === "dashed"
                ? "/img/line-105.svg"
                : direction === "vertical" && lineType === "dotted" && ["dotted", "cut"].includes(type)
                ? "/img/line-95.svg"
                : direction === "vertical" && lineType === "dashed" && ["cut", "dotted"].includes(type)
                ? "/img/line-93.svg"
                : direction === "vertical" && lineType === "solid" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-85.svg"
                : direction === "vertical" && lineType === "dotted" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-83.svg"
                : direction === "vertical" && lineType === "dashed" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-81.svg"
                : lineType === "solid" && direction === "vertical" && type === "numbers"
                ? "/img/line-73.svg"
                : direction === "vertical" && type === "numbers" && lineType === "dotted"
                ? "/img/line-71.svg"
                : direction === "vertical" && type === "numbers" && lineType === "dashed"
                ? "/img/line-69.svg"
                : direction === "horizontal" && type === "equal" && lineType === "dotted"
                ? "/img/line-67.svg"
                : direction === "horizontal" && type === "numbers" && lineType === "dotted"
                ? "/img/line-65.svg"
                : direction === "horizontal" && type === "equal" && lineType === "dashed"
                ? "/img/line-63.svg"
                : direction === "horizontal" && type === "numbers" && lineType === "dashed"
                ? "/img/line-61.svg"
                : direction === "horizontal" && lineType === "solid" && ["cut", "dotted"].includes(type)
                ? "/img/line-53.svg"
                : direction === "horizontal" && lineType === "dotted" && ["dotted", "cut"].includes(type)
                ? "/img/line-51.svg"
                : direction === "horizontal" && lineType === "dashed" && ["cut", "dotted"].includes(type)
                ? "/img/line-45.svg"
                : direction === "horizontal" && lineType === "solid" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-30.svg"
                : direction === "horizontal" && lineType === "dotted" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-28.svg"
                : direction === "horizontal" && lineType === "dashed" && ["squiggle", "zigzag"].includes(type)
                ? "/img/line-26.svg"
                : "/img/line-97.svg"
            }
          />
          <Endpoint
            shape="measure"
            shapeMeasure={endpointImg}
            shapeRectangleClassName={endpointShapeRectangleClassNameOverride}
          />
        </>
      )}
    </div>
  );
};

Line.propTypes = {
  type: PropTypes.oneOf([
    "numbers",
    "dotted",
    "measure",
    "measure-gap",
    "squiggle",
    "equal",
    "zigzag",
    "arrow",
    "ninety",
    "cut",
  ]),
  lineType: PropTypes.oneOf(["dashed", "dotted", "solid"]),
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  element: PropTypes.string,
  prop1: PropTypes.string,
  hasElement: PropTypes.bool,
  line: PropTypes.string,
  img: PropTypes.string,
  endpointShapeMeasure: PropTypes.string,
  line1: PropTypes.string,
  midsectionText: PropTypes.string,
  line2: PropTypes.string,
  endpointImg: PropTypes.string,
};
