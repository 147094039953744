/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1Hover = ({ className }) => {
  return (
    <svg
      className={`property-1-hover ${className}`}
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_57_1456)">
        <path
          className="path"
          d="M8.9562 23.5024C10.9337 14.5605 11.9225 10.0895 14.4743 6.85383C16.1643 4.71085 18.3194 2.98022 20.7768 1.79284C24.4872 0 29.0662 0 38.2241 0H41.8515C51.0275 0 55.6155 0 59.3307 1.79844C61.7894 2.98865 63.945 4.72285 65.6341 6.86965C68.1864 10.1135 69.1689 14.5951 71.1339 23.5583L71.4422 24.9646C74.3928 38.4231 75.868 45.1523 74.0813 50.422C72.5647 54.895 69.514 58.687 65.4695 61.1263C60.7047 64 53.8157 64 40.0376 64C26.2365 64 19.336 64 14.5677 61.1205C10.5203 58.6764 7.4697 54.8774 5.95723 50.3978C4.17535 45.1202 5.66542 38.3825 8.64556 24.907L8.9562 23.5024Z"
          fill="#203159"
        />
        <path
          className="path"
          d="M15.5761 24.8695C17.245 16.9104 18.0795 12.9309 20.7294 10.3703C21.2255 9.89096 21.7621 9.45525 22.3331 9.06807C25.3829 7 29.449 7 37.5812 7H42.4803C50.6302 7 54.7051 7 57.7586 9.07526C58.3279 9.46218 58.8628 9.89737 59.3575 10.376C62.0108 12.9432 62.8401 16.9329 64.4987 24.9122L65.4934 29.6977C67.7815 40.7056 68.9256 46.2096 67.3595 50.4936C66.1306 53.8551 63.8102 56.7075 60.7691 58.5948C56.8935 61 51.2718 61 40.0286 61C28.7675 61 23.1369 61 19.2586 58.5902C16.2155 56.6994 13.8951 53.8419 12.669 50.4756C11.1065 46.1852 12.262 40.6746 14.573 29.6532L15.5761 24.8695Z"
          fill="#3F60B0"
        />
        <path
          className="path"
          d="M18.165 32.9039C19.0765 30.3844 19.5323 29.1246 20.2762 28.1491C21.2785 26.8347 22.6634 25.863 24.2404 25.3676C25.4109 25 26.7505 25 29.4298 25L51.8677 25C54.7588 25 56.2043 25 57.4497 25.4206C59.0214 25.9514 60.3892 26.9577 61.3638 28.3002C62.136 29.3639 62.5663 30.7439 63.4269 33.5039C66.0472 41.9079 67.3573 46.1098 66.7473 49.4879C65.9783 53.7466 63.283 57.4103 59.4462 59.4121C56.4029 61 52.0014 61 43.1984 61H37.8784C28.9923 61 24.5493 61 21.5254 59.4372C17.4501 57.331 14.6652 53.3619 14.0711 48.8131C13.6303 45.4379 15.1418 41.2599 18.165 32.9039Z"
          fill="#4F78DB"
        />
        <path className="path" d="M40 29L40 31L40 33" stroke="white" strokeLinecap="round" strokeWidth="2" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_57_1456">
          <rect className="rect" fill="white" height="80" width="80" />
        </clipPath>
      </defs>
    </svg>
  );
};
