/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconAd } from "../../icons/IconAd";
import { IconLg } from "../../icons/IconLg";
import { IconMg } from "../../icons/IconMg";
import { IconQz } from "../../icons/IconQz";
import { Icons4 } from "../../icons/Icons4";
import { Icons49 } from "../../icons/Icons49";
import "./style.css";

export const Icons = ({
  icon,
  iconRcClassName,
  unionClassName,
  union = "/img/union-6.svg",
  overlapGroupClassName,
  ellipseClassName,
  ellipseClassNameOverride,
  divClassName,
  divClassNameOverride,
}) => {
  return (
    <>
      {icon === "DB" && <Icons49 className="instance-node-2" color="#363636" />}

      {["MT", "RC"].includes(icon) && (
        <div className={`icons ${iconRcClassName}`}>
          {icon === "RC" && <img className={`union ${unionClassName}`} alt="Union" src={union} />}

          {icon === "MT" && (
            <div className={`group ${unionClassName}`}>
              <div className={`overlap-group ${overlapGroupClassName}`}>
                <div className={`ellipse ${ellipseClassName}`} />
                <div className={`div ${ellipseClassNameOverride}`} />
                <div className={`ellipse-2 ${divClassName}`} />
                <div className={`ellipse-3 ${divClassNameOverride}`} />
              </div>
            </div>
          )}
        </div>
      )}

      {icon === "LC" && <Icons4 className="instance-node-2" />}

      {icon === "AD" && <IconAd className="instance-node-2" />}

      {icon === "QZ" && <IconQz className="instance-node-2" />}

      {icon === "MG" && <IconMg className="instance-node-2" />}

      {icon === "LG" && <IconLg className="instance-node-2" />}
    </>
  );
};

Icons.propTypes = {
  icon: PropTypes.oneOf(["QZ", "LG", "AD", "DB", "MT", "LC", "RC", "MG"]),
  union: PropTypes.string,
};
