/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Endpoint210 } from "../../icons/Endpoint210";
import { Endpoint24 } from "../../icons/Endpoint24";
import { Endpoint26 } from "../../icons/Endpoint26";
import { Endpoint28 } from "../../icons/Endpoint28";
import { Line } from "../Line";
import "./style.css";

export const AnnotationText = ({ size, theme, direction, className, noteClassName, text = "Annotation" }) => {
  return (
    <div className={`annotation-text direction-${direction} ${size} ${theme} ${className}`}>
      {direction === "default" && (
        <div className={`note ${noteClassName}`}>
          {size === "small" && <>{text}</>}

          {size === "large" && (
            <p className="text-wrapper-280">
              Longer annotation index that accounts for at least three lines of text at a fixed width of 256px.
            </p>
          )}
        </div>
      )}

      {["left", "up"].includes(direction) && (
        <Line
          className={`${direction === "up" ? "class-9" : "class-10"}`}
          direction={direction === "up" ? "vertical" : "horizontal"}
          hasElement={false}
          img={
            direction === "left" && theme === "dark"
              ? "/img/line-25.svg"
              : direction === "left" && theme === "light"
              ? "/img/line-24.svg"
              : undefined
          }
          line={
            theme === "dark" && direction === "up"
              ? "/img/line-21.svg"
              : theme === "light" && direction === "up"
              ? "/img/line-20.svg"
              : undefined
          }
          lineClassName={`${direction === "left" && "class-11"}`}
          lineType="solid"
          override={
            direction === "up" ? (
              <Endpoint26
                className="endpoint-2"
                color={theme === "dark" ? "black" : theme === "light" ? "white" : undefined}
              />
            ) : (
              <Endpoint210
                className="endpoint-2-10"
                color={theme === "dark" ? "black" : theme === "light" ? "white" : undefined}
              />
            )
          }
          type="arrow"
        />
      )}

      {["down", "left", "right", "up"].includes(direction) && (
        <div className="note-wrapper">
          <div className="text-wrapper">{text}</div>
        </div>
      )}

      {["down", "right"].includes(direction) && (
        <Line
          className={`${direction === "down" ? "class-9" : "class-12"}`}
          direction={direction === "down" ? "vertical" : "horizontal"}
          hasElement={false}
          img={direction === "right" && theme === "light" ? "/img/line-22.svg" : "/img/line-23.svg"}
          line={direction === "down" && theme === "light" ? "/img/line-16.svg" : "/img/line-17.svg"}
          lineType="solid"
          override={
            direction === "down" ? (
              <Endpoint24
                className="endpoint-2"
                color={theme === "dark" ? "black" : theme === "light" ? "white" : undefined}
              />
            ) : (
              <Endpoint28
                className="endpoint-2"
                color={theme === "dark" ? "black" : theme === "light" ? "white" : undefined}
              />
            )
          }
          type="arrow"
        />
      )}
    </div>
  );
};

AnnotationText.propTypes = {
  size: PropTypes.oneOf(["large", "small"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  direction: PropTypes.oneOf(["up", "right", "default", "left", "down"]),
  text: PropTypes.string,
};
