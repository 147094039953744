/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons18 = ({ className }) => {
  return (
    <svg
      className={`icons-18 ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 20 19"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12.3342 13.5989C12.0329 13.9002 11.5445 13.9002 11.2432 13.5989L8.02867 10.3844C7.88398 10.2397 7.8027 10.0435 7.8027 9.83886C7.8027 9.63425 7.88398 9.43802 8.02867 9.29334L11.2432 6.07882C11.5445 5.77754 12.0329 5.77754 12.3342 6.07882C12.6355 6.3801 12.6355 6.86858 12.3342 7.16986L9.66523 9.83886L12.3342 12.5079C12.6355 12.8091 12.6355 13.2976 12.3342 13.5989Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M17.0605 9.83887C17.0605 10.2649 16.7151 10.6104 16.2891 10.6104H8.57422C8.14814 10.6104 7.8027 10.2649 7.8027 9.83886C7.8027 9.41278 8.14814 9.06738 8.57422 9.06738H16.2891C16.7151 9.06738 17.0605 9.41279 17.0605 9.83887Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M9.3457 15.625C9.3457 16.0511 9.0003 16.3965 8.57422 16.3965H6.0026C5.45698 16.3965 4.9337 16.1797 4.54788 15.7939C4.16206 15.4081 3.94531 14.8848 3.94531 14.3392V5.33854C3.94531 4.79291 4.16206 4.26963 4.54788 3.88382C4.9337 3.498 5.45698 3.28125 6.0026 3.28125H8.57422C9.0003 3.28125 9.3457 3.62666 9.3457 4.05273C9.3457 4.47881 9.0003 4.82422 8.57422 4.82422H6.0026C5.8662 4.82422 5.73538 4.87841 5.63892 4.97486C5.54247 5.07132 5.48828 5.20214 5.48828 5.33854V14.3392C5.48828 14.4756 5.54247 14.6064 5.63892 14.7029C5.73538 14.7993 5.8662 14.8535 6.0026 14.8535H8.57422C9.0003 14.8535 9.3457 15.1989 9.3457 15.625Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
