/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons3 = ({ className }) => {
  return (
    <svg
      className={`icons-3 ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.31445 5.37204V11.8326C5.3142 11.5844 7.01406 11.2794 9.32891 11.2794C11.8054 11.2794 13.8252 11.6286 14.9512 11.8776V5.37228C13.7059 5.54404 11.3514 5.83438 9.63686 5.83438C7.92157 5.83438 5.56161 5.54376 4.31445 5.37204ZM2.79492 4.93819C2.79492 4.2424 3.41151 3.70789 4.10061 3.80735C5.15565 3.95964 7.81995 4.31485 9.63686 4.31485C11.4531 4.31485 14.111 3.9599 15.1647 3.80754C15.8538 3.70788 16.4707 4.24231 16.4707 4.93834V12.3384C16.4707 13.1053 15.743 13.6317 15.0343 13.4572C14.1396 13.2368 12.0212 12.7989 9.32891 12.7989C6.7875 12.7989 5.066 13.1892 4.29438 13.4103C3.58361 13.614 2.79492 13.0992 2.79492 12.2926V4.93819Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M4.31445 15.131L4.03485 14.4245C3.64469 14.5789 3.45359 15.0204 3.60801 15.4106C3.76238 15.8006 4.20361 15.9917 4.59368 15.8376L4.60347 15.8339C4.61391 15.8299 4.63161 15.8234 4.65641 15.8146C4.70602 15.7971 4.78402 15.7706 4.88921 15.7381C5.09962 15.673 5.41858 15.5838 5.83652 15.4938C6.67248 15.3139 7.90329 15.131 9.45296 15.131C11.0039 15.131 12.3288 15.3141 13.2635 15.4967C13.7303 15.588 14.0983 15.6787 14.3468 15.7458C14.471 15.7793 14.5652 15.8068 14.6268 15.8255C14.6576 15.8348 14.6802 15.8419 14.6943 15.8464L14.7091 15.8511L14.7109 15.8517V15.8517C15.1088 15.9839 15.5393 15.769 15.672 15.3712C15.8046 14.9731 15.5895 14.5429 15.1914 14.4102L14.9512 15.131C15.1914 14.4102 15.1914 14.4102 15.1914 14.4102L15.1898 14.4096L15.1874 14.4088L15.18 14.4064L15.1553 14.3985C15.1344 14.3918 15.1049 14.3826 15.0671 14.3711C14.9915 14.3482 14.8824 14.3164 14.7427 14.2787C14.4632 14.2033 14.0602 14.1041 13.5548 14.0054C12.5452 13.8082 11.121 13.6114 9.45296 13.6114C7.78373 13.6114 6.44529 13.8084 5.51672 14.0083C5.05239 14.1083 4.69027 14.209 4.44016 14.2864C4.31509 14.3251 4.21797 14.358 4.15003 14.382C4.11606 14.394 4.08937 14.4038 4.07013 14.411L4.04686 14.4198L4.03944 14.4227L4.03679 14.4237L4.03573 14.4242C4.03573 14.4242 4.03485 14.4245 4.31445 15.131Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
