/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconAd } from "../../icons/IconAd";
import { IconLg } from "../../icons/IconLg";
import { IconMg } from "../../icons/IconMg";
import { IconQz } from "../../icons/IconQz";
import { Icons4 } from "../../icons/Icons4";
import { Icons49 } from "../../icons/Icons49";
import { Icons52 } from "../../icons/Icons52";
import { Icons57 } from "../../icons/Icons57";
import { Icons } from "../Icons";
import { NavLink } from "../NavLink";
import "./style.css";

export const NamecandidateNameMenuScore10001 = ({
  property1,
  className,
  navLinkStateDefaultTypeClassName,
  navLinkIcon = <Icons49 className="icons-27" color="#363636" />,
  navLinkDivClassName,
  navLinkClassName,
  iconsUnionClassName,
  iconsIconRcClassName,
  iconsUnion = "/img/union-6.svg",
  dashboardClassName,
  navLinkStateDefaultTypeClassNameOverride,
  override = <Icons4 className="icons-27" />,
  navLinkDivClassNameOverride,
  navLinkStateHoverTypeClassName,
  navLinkIcon1 = <IconAd className="icons-27" />,
  navLinkDivClassName1,
  navLinkClassNameOverride,
  iconsUnionClassNameOverride,
  iconsIconRcClassNameOverride,
  iconsEllipseClassName,
  iconsEllipseClassNameOverride,
  iconsOverlapGroupClassName,
  iconsDivClassName,
  iconsDivClassNameOverride,
  dashboardClassNameOverride,
  navLinkStateHoverTypeClassNameOverride,
  navLinkIcon2 = <IconQz className="icons-27" />,
  navLinkDivClassName2,
  navLinkStateSelectedTypeClassName,
  navLinkIcon3 = <IconMg className="icons-27" />,
  navLinkDivClassName3,
  frameClassName,
  navLinkStateSelectedTypeClassNameOverride,
  navLinkIcon4 = <IconLg className="icons-27" />,
  navLinkDivClassName4,
  extraClassName,
  divClassName,
  interpunctClassName,
  ellipseClassName,
  termsConditionsClassName,
  chevronDownClassName,
  chevronDown = "/img/chevron-down-12.svg",
}) => {
  return (
    <div className={`namecandidate-name-menu-score-1000-1 property-1-3-${property1} ${className}`}>
      {property1 === "default" && (
        <>
          <div className="frame-44">
            <NavLink
              className={navLinkStateDefaultTypeClassName}
              divClassName={navLinkDivClassName}
              icon={navLinkIcon}
              stateProp="default"
              text="Dashboard"
              type="default"
            />
            <div className={`nav-link-6 ${navLinkClassName}`}>
              <Icons
                icon="RC"
                iconRcClassName={iconsIconRcClassName}
                union={iconsUnion}
                unionClassName={iconsUnionClassName}
              />
              <div className={`dashboard-3 ${dashboardClassName}`}>Resource Center</div>
            </div>
            <NavLink
              className={navLinkStateDefaultTypeClassNameOverride}
              divClassName={navLinkDivClassNameOverride}
              icon={override}
              stateProp="default"
              text="Learning Center"
              type="default"
            />
            <NavLink
              className={navLinkStateHoverTypeClassName}
              divClassName={navLinkDivClassName1}
              icon={navLinkIcon1}
              stateProp="default"
              text="Auditorium"
              type="default"
            />
            <div className={`nav-link-6 ${navLinkClassNameOverride}`}>
              <Icons
                divClassName={iconsDivClassName}
                divClassNameOverride={iconsEllipseClassName}
                ellipseClassName={iconsEllipseClassNameOverride}
                ellipseClassNameOverride={iconsDivClassNameOverride}
                icon="MT"
                iconRcClassName={iconsIconRcClassNameOverride}
                overlapGroupClassName={iconsOverlapGroupClassName}
                unionClassName={iconsUnionClassNameOverride}
              />
              <div className={`dashboard-3 ${dashboardClassNameOverride}`}>Meetings</div>
            </div>
            <NavLink
              className={navLinkStateHoverTypeClassNameOverride}
              divClassName={navLinkDivClassName2}
              icon={navLinkIcon2}
              stateProp="default"
              text="Quizzes"
              type="default"
            />
            <NavLink
              className={navLinkStateSelectedTypeClassName}
              divClassName={navLinkDivClassName3}
              icon={navLinkIcon3}
              stateProp="default"
              text="Manage"
              type="default"
            />
          </div>
          <div className={`frame-45 ${frameClassName}`}>
            <NavLink
              className={navLinkStateSelectedTypeClassNameOverride}
              divClassName={navLinkDivClassName4}
              icon={navLinkIcon4}
              stateProp="default"
              text="Logout"
              type="default"
            />
            <div className={`extra-2 ${extraClassName}`}>
              <div className={`text-wrapper-17 ${divClassName}`}>Policies</div>
              <div className={`ellipse-wrapper ${interpunctClassName}`}>
                <div className={`ellipse-11 ${ellipseClassName}`} />
              </div>
              <div className={`text-wrapper-17 ${termsConditionsClassName}`}>Terms &amp; Conditions</div>
            </div>
          </div>
        </>
      )}

      {property1 === "collapsed" && (
        <>
          <div className="frame-46">
            <NavLink
              className={navLinkStateDefaultTypeClassName}
              divClassName={navLinkDivClassName}
              icon={navLinkIcon}
              override={<Icons52 className="icons-18" />}
              stateProp="default"
              type="collapsed"
            />
            <div className={`nav-link-7 ${navLinkClassName}`}>
              <Icons icon="RC" iconRcClassName="icons-19" union="/img/union-7.svg" unionClassName="icons-20" />
            </div>
            <NavLink
              className={navLinkStateDefaultTypeClassNameOverride}
              divClassName={navLinkDivClassNameOverride}
              icon={override}
              override={<Icons4 className="icons-18" />}
              stateProp="default"
              type="collapsed"
            />
            <NavLink
              className={navLinkStateHoverTypeClassName}
              divClassName={navLinkDivClassName1}
              icon={navLinkIcon1}
              override={<Icons57 className="icons-18" />}
              stateProp="default"
              type="collapsed"
            />
            <div className={`nav-link-8 ${navLinkClassNameOverride}`}>
              <Icons
                divClassName="icons-25"
                divClassNameOverride="icons-26"
                ellipseClassName="icons-23"
                ellipseClassNameOverride="icons-24"
                icon="MT"
                iconRcClassName="icons-19"
                overlapGroupClassName="icons-22"
                unionClassName="icons-21"
              />
            </div>
            <NavLink
              className={navLinkStateHoverTypeClassNameOverride}
              divClassName={navLinkDivClassName2}
              icon={navLinkIcon2}
              override={<IconQz className="icons-18" />}
              stateProp="default"
              type="collapsed"
            />
            <NavLink
              className={navLinkStateSelectedTypeClassName}
              divClassName={navLinkDivClassName3}
              icon={navLinkIcon3}
              override={<IconMg className="icons-18" />}
              stateProp="default"
              type="collapsed"
            />
          </div>
          <NavLink
            className="nav-link-9"
            override={<IconLg className="icons-18" />}
            stateProp="default"
            type="collapsed"
          />
        </>
      )}

      <img
        className={`chevron-down-2 ${chevronDownClassName}`}
        alt="Chevron down"
        src={property1 === "collapsed" ? "/img/chevron-down-11.svg" : chevronDown}
      />
    </div>
  );
};

NamecandidateNameMenuScore10001.propTypes = {
  property1: PropTypes.oneOf(["collapsed", "default"]),
  iconsUnion: PropTypes.string,
  chevronDown: PropTypes.string,
};
