/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons57 = ({ className }) => {
  return (
    <svg
      className={`icons-57 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M6.66667 8.20714V19.545C8.42116 19.1094 11.4043 18.5741 15.4667 18.5741C19.8127 18.5741 23.3573 19.1869 25.3333 19.6239V8.20757C23.1479 8.509 19.0159 9.01852 16.0071 9.01852C12.9969 9.01852 8.85533 8.50851 6.66667 8.20714ZM4 7.44578C4 6.22471 5.08206 5.28668 6.29139 5.46124C8.14291 5.72848 12.8186 6.35185 16.0071 6.35185C19.1944 6.35185 23.859 5.72895 25.708 5.46156C26.9173 5.28667 28 6.22456 28 7.44604V20.4325C28 21.7785 26.7229 22.7023 25.4792 22.396C23.9091 22.0092 20.1915 21.2407 15.4667 21.2407C11.0067 21.2407 7.98557 21.9256 6.63145 22.3137C5.3841 22.6712 4 21.7677 4 20.3522V7.44578Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M6.66667 25.3333L6.17599 24.0936C5.49128 24.3646 5.15591 25.1393 5.4269 25.824C5.69781 26.5085 6.47215 26.8439 7.15669 26.5734L7.17386 26.5669C7.19219 26.56 7.22325 26.5485 7.26678 26.5332C7.35384 26.5024 7.49073 26.4559 7.67533 26.3988C8.04457 26.2846 8.60433 26.128 9.33778 25.9701C10.8048 25.6543 12.9648 25.3334 15.6844 25.3334C18.4061 25.3334 20.7312 25.6548 22.3716 25.9753C23.1908 26.1353 23.8367 26.2946 24.2727 26.4123C24.4907 26.4711 24.656 26.5194 24.764 26.5522C24.8181 26.5685 24.8578 26.581 24.8826 26.5889L24.9085 26.5972L24.9117 26.5982C25.6099 26.8302 26.3655 26.4531 26.5982 25.755C26.8311 25.0564 26.4536 24.3013 25.755 24.0684L25.3333 25.3333C25.755 24.0684 25.755 24.0684 25.755 24.0684L25.7521 24.0675L25.7478 24.0661L25.7348 24.0618L25.6915 24.0478C25.6549 24.0362 25.6032 24.02 25.5368 23.9999C25.4041 23.9597 25.2127 23.9039 24.9674 23.8377C24.4769 23.7054 23.7697 23.5313 22.8829 23.3581C21.111 23.0119 18.6116 22.6667 15.6844 22.6667C12.755 22.6667 10.4061 23.0124 8.77655 23.3632C7.96169 23.5386 7.3262 23.7154 6.88728 23.8512C6.66779 23.9191 6.49735 23.9768 6.37812 24.0189C6.3185 24.04 6.27167 24.0572 6.23789 24.0699L6.19705 24.0854L6.18404 24.0904L6.17939 24.0922L6.17753 24.093C6.17753 24.093 6.17599 24.0936 6.66667 25.3333Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
