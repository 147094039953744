/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair13 = ({ className }) => {
  return (
    <svg
      className={`chair-13 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_87_5935)">
        <path
          className="path"
          d="M4.96123 21.7519C6.25369 15.9077 6.89992 12.9857 8.90253 11.1439C9.18708 10.8821 9.48886 10.6398 9.80584 10.4185C12.0366 8.86084 15.0293 8.86084 21.0147 8.86084H22.8081C28.5958 8.86084 31.4897 8.86084 33.6725 10.321C34.0941 10.603 34.49 10.9216 34.8558 11.273C36.7495 13.0925 37.3692 15.9192 38.6087 21.5727L38.7202 22.0816C40.6666 30.96 41.6399 35.3992 39.7777 38.6311C39.2803 39.4944 38.652 40.2753 37.9152 40.946C35.1571 43.4571 30.6125 43.4571 21.5232 43.4571V43.4571C12.6522 43.4571 8.21665 43.4571 5.52807 41.0056C4.80998 40.3509 4.19788 39.5886 3.71363 38.7461C1.90054 35.5916 2.85833 31.2607 4.77391 22.5989L4.96123 21.7519Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M8.69081 21.7805C9.48641 17.9863 9.88421 16.0891 11.0544 14.7901C11.5054 14.2894 12.0316 13.8621 12.6141 13.5235C14.1257 12.6448 16.0641 12.6448 19.9409 12.6448L23.8262 12.6448C27.5518 12.6448 29.4146 12.6448 30.8818 13.458C31.5523 13.8297 32.1516 14.3172 32.6519 14.898C33.7468 16.169 34.1259 17.9928 34.8841 21.6405L35.5189 24.6944C36.5685 29.7438 37.0933 32.2685 36.6418 34.2943C35.9819 37.2551 33.9666 39.7325 31.202 40.9811C29.3105 41.8354 26.7318 41.8354 21.5745 41.8354V41.8354C16.5414 41.8354 14.0249 41.8354 12.1804 41.0014C9.48451 39.7824 7.52077 37.3642 6.881 34.4755C6.44327 32.4991 6.95971 30.0361 7.99261 25.1102L8.69081 21.7805Z"
          fill="#5E5E5E"
        />
        <path
          className="path"
          d="M10.1355 26.219C10.479 25.2695 10.6508 24.7947 10.8955 24.4039C11.515 23.4149 12.5079 22.7183 13.6487 22.4722C14.0994 22.375 14.6043 22.375 15.614 22.375L28.6727 22.375C29.7809 22.375 30.335 22.375 30.8239 22.4901C31.9704 22.7601 32.9572 23.4861 33.5563 24.5003C33.8117 24.9328 33.9766 25.4617 34.3065 26.5197V26.5197C35.453 30.1968 36.0263 32.0354 35.9678 33.5254C35.8292 37.0588 33.5536 40.152 30.2216 41.3361C28.8165 41.8354 26.8907 41.8354 23.0389 41.8354H21.0926C17.2702 41.8354 15.3591 41.8354 13.9855 41.3604C10.4583 40.1405 8.11138 36.7956 8.16427 33.0638C8.18486 31.6105 8.83507 29.8134 10.1355 26.219V26.219Z"
          fill="#AFAFAF"
        />
        <path
          className="path"
          d="M21.7837 24.5374L21.7837 26.6996"
          stroke="white"
          strokeLinecap="round"
          strokeWidth="1.08113"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_87_5935">
          <rect
            className="rect"
            fill="white"
            height="43.2454"
            transform="translate(0.161194 0.212158)"
            width="43.2454"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
