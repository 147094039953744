/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const RadioYes = ({ className }) => {
  return (
    <svg
      className={`radio-yes ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#1847ED" height="16" rx="4" width="16" />
      <path
        className="path"
        clipRule="evenodd"
        d="M13.6622 3.25071C14.0761 3.61646 14.115 4.24843 13.7493 4.66225L6.67858 12.6622C6.48027 12.8866 6.19186 13.0103 5.89262 12.9993C5.59339 12.9883 5.31481 12.8438 5.13349 12.6056L2.20419 8.75593C1.86976 8.31642 1.95494 7.68901 2.39445 7.35457C2.83396 7.02013 3.46137 7.10531 3.79581 7.54482L5.9871 10.4246L12.2507 3.33775C12.6165 2.92393 13.2484 2.88497 13.6622 3.25071Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
