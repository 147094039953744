/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const RadioPartial1 = ({ className }) => {
  return (
    <svg
      className={`radio-partial-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_87_1756)">
        <rect className="rect" fill="#1847ED" height="16" rx="4" width="16" />
        <path className="path" d="M3.5 8H12.5" stroke="white" strokeLinecap="round" strokeWidth="2" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_87_1756">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
