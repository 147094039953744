/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Chair9 = ({ className }) => {
  return (
    <svg
      className={`chair-9 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 59 14"
      width="59"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_57_1469)">
        <path
          className="path"
          d="M16.761 13.7809C21.0745 10.7469 23.2312 9.22987 25.8225 8.62111C26.1907 8.53461 26.5612 8.46286 26.9315 8.40632C29.5377 8.00847 31.8486 8.6602 36.4704 9.96368L37.8552 10.3542C42.3244 11.6147 44.5589 12.2449 45.4161 13.5486C45.5816 13.8004 45.7067 14.0674 45.7897 14.3464C46.2198 15.791 45.0947 17.5296 42.8445 21.0068L42.6419 21.3198C39.1081 26.7805 37.3412 29.5109 34.0697 30.9388C33.1959 31.3202 32.2677 31.6265 31.3183 31.8465C27.764 32.6704 24.2547 31.6807 17.2362 29.7013V29.7013C10.3862 27.7694 6.96117 26.8034 6.27586 24.8272C6.09283 24.2993 6.05261 23.7336 6.15666 23.1502C6.54623 20.9657 9.74277 18.7173 16.1358 14.2206L16.761 13.7809Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M19.6244 14.6093C22.3913 12.6301 23.7747 11.6405 25.4153 11.1583C26.0476 10.9725 26.6963 10.8447 27.3383 10.7794C29.004 10.6101 30.5007 11.0323 33.4943 11.8765L36.4945 12.7227C39.3713 13.534 40.8097 13.9397 41.4813 14.7206C41.7882 15.0774 41.9744 15.4845 42.0312 15.923C42.1555 16.8825 41.4136 17.9997 39.9297 20.2342L38.6874 22.105C36.6333 25.1981 35.6062 26.7447 34.1083 27.7957C31.9191 29.3316 28.9575 30.2982 26.1144 30.4045C24.1692 30.4772 22.178 29.9156 18.1956 28.7925V28.7925C14.3092 27.6964 12.366 27.1484 11.4148 26.2735C10.0246 24.9949 9.88018 23.1953 11.0249 21.4172C11.8082 20.2007 13.6042 18.9159 17.1963 16.3463L19.6244 14.6093Z"
          fill="#5E5E5E"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_57_1469">
          <rect
            className="rect"
            fill="white"
            height="34.6957"
            transform="matrix(0.962455 0.27144 -0.707107 0.707107 25.2744 0.515625)"
            width="34.6957"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
