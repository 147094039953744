/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ThirtyTwoArrowChevronRight8 = ({ color = "#1E202C", className }) => {
  return (
    <svg
      className={`thirty-two-arrow-chevron-right-8 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5.23472 3.21666C5.44808 3.0033 5.794 3.0033 6.00735 3.21666L9.01217 6.22147C9.22552 6.43483 9.22552 6.78075 9.01217 6.9941L6.00735 9.99892C5.794 10.2123 5.44808 10.2123 5.23472 9.99892C5.02137 9.78556 5.02137 9.43964 5.23472 9.22629L7.85323 6.60779L5.23472 3.98928C5.02137 3.77593 5.02137 3.43001 5.23472 3.21666Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

ThirtyTwoArrowChevronRight8.propTypes = {
  color: PropTypes.string,
};
