/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons25 = ({ className }) => {
  return (
    <svg
      className={`icons-25 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.70268 5.12591V12.0205C5.7696 11.7557 7.58365 11.4301 10.054 11.4301C12.6969 11.4301 14.8524 11.8028 16.054 12.0685V5.12617C14.725 5.30948 12.2124 5.61932 10.3827 5.61932C8.55214 5.61932 6.03362 5.30917 4.70268 5.12591ZM3.08105 4.66292C3.08105 3.92038 3.73907 3.34996 4.47447 3.4561C5.60039 3.61862 8.4437 3.99769 10.3827 3.99769C12.3209 3.99769 15.1575 3.6189 16.2819 3.4563C17.0173 3.34995 17.6756 3.92029 17.6756 4.66308V12.5603C17.6756 13.3787 16.899 13.9405 16.1428 13.7542C15.1879 13.5191 12.9272 13.0517 10.054 13.0517C7.34188 13.0517 5.50471 13.4682 4.68126 13.7042C3.92274 13.9216 3.08105 13.3722 3.08105 12.5114V4.66292Z"
        fill="#363636"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M4.70268 15.5405L4.40429 14.7866C3.98792 14.9514 3.78397 15.4225 3.94877 15.8389C4.11351 16.2551 4.58439 16.4591 5.00066 16.2946L5.01111 16.2906C5.02225 16.2864 5.04114 16.2795 5.06761 16.2701C5.12055 16.2514 5.2038 16.2231 5.31605 16.1884C5.54059 16.1189 5.88099 16.0238 6.327 15.9277C7.21913 15.7357 8.53263 15.5405 10.1864 15.5405C11.8415 15.5405 13.2554 15.736 14.2529 15.9309C14.7512 16.0282 15.1439 16.1251 15.4091 16.1966C15.5416 16.2324 15.6421 16.2618 15.7078 16.2817C15.7407 16.2916 15.7648 16.2992 15.7799 16.304L15.7957 16.3091L15.7976 16.3097V16.3097C16.2222 16.4508 16.6817 16.2215 16.8232 15.7969C16.9648 15.3721 16.7352 14.9129 16.3104 14.7713L16.054 15.5405C16.3104 14.7713 16.3104 14.7713 16.3104 14.7713L16.3087 14.7707L16.3061 14.7698L16.2982 14.7673L16.2718 14.7588C16.2496 14.7517 16.2181 14.7419 16.1778 14.7296C16.097 14.7052 15.9807 14.6712 15.8315 14.631C15.5332 14.5505 15.1032 14.4447 14.5639 14.3393C13.4864 14.1288 11.9665 13.9189 10.1864 13.9189C8.40503 13.9189 6.97667 14.1291 5.98571 14.3424C5.49019 14.4491 5.10374 14.5566 4.83683 14.6392C4.70336 14.6805 4.59972 14.7156 4.52721 14.7412C4.49095 14.754 4.46248 14.7645 4.44194 14.7722L4.4171 14.7816L4.40918 14.7847L4.40636 14.7858L4.40523 14.7862C4.40523 14.7862 4.40429 14.7866 4.70268 15.5405Z"
        fill="#363636"
        fillRule="evenodd"
      />
    </svg>
  );
};
