/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { StateDefault } from "../../icons/StateDefault";
import "./style.css";

export const CardDesign = ({
  stateProp,
  type,
  className,
  folderCardClassName,
  frameClassName,
  frameClassNameOverride,
  divClassName,
  divClassNameOverride,
  frameWrapperClassName,
  frameClassName1,
  divClassName1,
  frameClassName2,
  divClassName2,
  icon = <StateDefault className="star-26" />,
  frameClassName3,
  subheadingWrapperClassName,
  subheadingClassName,
  ellipseClassName,
  supportingTextClassName,
  moreHorizClassName,
  moreHoriz = "/img/more-horiz-31.png",
  text = "Marketing",
  hasFrame = true,
  elementClassName,
  overlapGroupClassName,
  vectorClassName,
  vector = "/img/vector-10.svg",
  elementClassNameOverride,
  groupClassName,
  group = "/img/group-20.png",
  ellipseClassNameOverride,
  subheadingWrapperClassNameOverride,
  subheadingClassNameOverride,
  ellipseClassName1,
  supportingTextClassNameOverride,
  folderCardClassNameOverride,
  frameClassName4,
  frameClassName5,
  frameWrapperClassNameOverride,
  frameClassName6,
  frameClassName7,
  frameClassName8,
  divClassName3,
  imgClassName,
  frame = "/img/frame-40093-4.svg",
  frameClassName9,
  divClassName4,
  divClassName5,
  speakersClassName,
  frameClassName10,
  frameClassName11,
  chipSpeakerClassName,
  frameClassName12,
  nameClassName,
  chipSpeakerClassNameOverride,
  frameClassName13,
  nameClassNameOverride,
  nameWrapperClassName,
  nameClassName1,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
    type: type || "folder",
  });

  return (
    <div
      className={`card-design ${state.type} state-0-${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      {state.state === "default" && ["file", "folder"].includes(state.type) && (
        <>
          <div className={`folder-card ${folderCardClassName}`} />
          <div className={`frame-8 ${frameClassName}`}>
            <div className={`frame-9 ${frameClassNameOverride}`}>
              <div className={`frame-10 ${divClassName}`}>
                <div className={`frame-9 ${divClassNameOverride}`}>
                  <div className={`frame-wrapper ${frameWrapperClassName}`}>
                    <div className={`frame-11 ${frameClassName1}`}>
                      <div className={`div-4 ${divClassName1}`}>
                        {state.type === "folder" && <>{text}</>}

                        {state.type === "file" && <>File Name</>}
                      </div>
                      {hasFrame && (
                        <div className={`frame-12 ${frameClassName2}`}>
                          <div className={`UNREAD-ITEMS ${divClassName2}`}>
                            {state.type === "folder" && <>UNREAD ITEMS</>}

                            {state.type === "file" && <>UNREAD</>}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {icon}
                </div>
                <div className={`frame-13 ${frameClassName3}`}>
                  {state.type === "file" && (
                    <>
                      <div className={`element-3 ${elementClassName}`}>
                        <div className={`overlap-group-4 ${overlapGroupClassName}`}>
                          <img className={`img-5 ${vectorClassName}`} alt="Vector" src={vector} />
                          <div className={`group-wrapper ${elementClassNameOverride}`}>
                            <img className={`img-5 ${groupClassName}`} alt="Group" src={group} />
                          </div>
                        </div>
                      </div>
                      <div className={`ellipse-9 ${ellipseClassNameOverride}`} />
                    </>
                  )}

                  <div
                    className={`subheading-wrapper ${
                      state.type === "file"
                        ? subheadingWrapperClassNameOverride
                        : state.type === "folder"
                        ? subheadingWrapperClassName
                        : undefined
                    }`}
                  >
                    <div
                      className={`subheading ${
                        state.type === "file"
                          ? subheadingClassNameOverride
                          : state.type === "folder"
                          ? subheadingClassName
                          : undefined
                      }`}
                    >
                      4.3
                    </div>
                  </div>
                  <div
                    className={`ellipse-9 ${
                      state.type === "folder" ? ellipseClassName : state.type === "file" ? ellipseClassName1 : undefined
                    }`}
                  />
                  <div
                    className={`supporting-text ${
                      state.type === "folder"
                        ? supportingTextClassName
                        : state.type === "file"
                        ? supportingTextClassNameOverride
                        : undefined
                    }`}
                  >
                    a week ago
                  </div>
                </div>
              </div>
            </div>
            <img className={`img-6 ${moreHorizClassName}`} alt="More horiz" src={moreHoriz} />
          </div>
        </>
      )}

      {(state.type === "course" ||
        state.type === "event-02" ||
        (state.state === "default" && state.type === "event")) && (
        <>
          <div className={`folder-card-2 ${folderCardClassNameOverride}`}>
            {state.type === "course" && (
              <>
                <img className="line-2" alt="Line" src="/img/line-4-3.svg" />
                <img className="line-3" alt="Line" src="/img/line-5-2.svg" />
              </>
            )}
          </div>
          <div className={`frame-14 ${frameClassName4}`}>
            <div className={`frame-15 ${frameClassName5}`}>
              {["event-02", "event"].includes(state.type) && (
                <>
                  <div className={`frame-16 ${frameWrapperClassNameOverride}`}>
                    <div className={`frame-17 ${frameClassName6}`}>
                      <div className={`frame-18 ${frameClassName7}`}>
                        <div className={`frame-19 ${frameClassName8}`}>
                          <p className={`p ${divClassName3}`}>Vmware CEO reveals big changes amid multi-cloud shift</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className={`img-6 ${imgClassName}`}
                    alt="Frame"
                    src={state.type === "event-02" ? "/img/frame-40093-3.svg" : frame}
                  />
                </>
              )}

              {state.type === "course" && (
                <>
                  <div className="frame-16">
                    <div className="frame-17">
                      <div className="frame-18">
                        <div className="frame-19">
                          <div className="div-4">Course Name</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="frame-20" alt="Frame" src="/img/frame-40192.svg" />
                  <img className="frame-21" alt="Frame" src="/img/frame-40093-2.svg" />
                </>
              )}
            </div>
            <div className={`frame-22 ${frameClassName9}`}>
              {["event-02", "event"].includes(state.type) && (
                <>
                  <div className={`text-wrapper-13 ${divClassName4}`}>10 Dec 2020</div>
                  <div className={`text-wrapper-13 ${divClassName5}`}>10.30 PM IST</div>
                </>
              )}

              {state.type === "course" && (
                <div className="frame-23">
                  <div className="subheading-2">Design</div>
                </div>
              )}
            </div>
          </div>
          <div className={`speakers ${speakersClassName}`}>
            <div className={`speakers-2 ${frameClassName10}`}>
              {state.type === "event" && <>Speakers:</>}

              {["course", "event-02"].includes(state.type) && (
                <>
                  <div className="div-5">
                    {state.type === "event-02" && <>Speakers:</>}

                    {state.type === "course" && <>Instructor</>}
                  </div>
                  <div className="frame-24">
                    <div className="chip-speaker">
                      <div className="frame-25" />
                      <div className="name">Cameron</div>
                    </div>
                    {state.type === "event-02" && (
                      <div className="name-wrapper">
                        <div className="name-2">+2 more</div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={`frame-26 ${frameClassName11}`}>
              {state.type === "event" && (
                <>
                  <div className={`chip-speaker ${chipSpeakerClassName}`}>
                    <div className={`frame-27 ${frameClassName12}`} />
                    <div className={`name ${nameClassName}`}>Cameron</div>
                  </div>
                  <div className={`chip-speaker-2 ${chipSpeakerClassNameOverride}`}>
                    <div className={`frame-28 ${frameClassName13}`} />
                    <div className={`name ${nameClassNameOverride}`}>Sam</div>
                  </div>
                  <div className={`name-wrapper ${nameWrapperClassName}`}>
                    <div className={`name-2 ${nameClassName1}`}>+2 more</div>
                  </div>
                </>
              )}

              {["course", "event-02"].includes(state.type) && (
                <div className="register">
                  {state.type === "event-02" && <>Register</>}

                  {state.type === "course" && <>Continue</>}
                </div>
              )}

              {state.type === "course" && (
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward.png" />
              )}
            </div>
          </div>
        </>
      )}

      {state.state === "hover" && (
        <div className="folder-card-3">
          {state.type === "event" && (
            <>
              <div className="frame-29">
                <div className="frame-15">
                  <div className="frame-16">
                    <div className="frame-17">
                      <div className="frame-18">
                        <div className="frame-19">
                          <p className="p">Vmware CEO reveals big changes amid multi-cloud shift</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="img-6" alt="Frame" src="/img/frame-40093-1.svg" />
                </div>
                <div className="frame-22">
                  <div className="text-wrapper-13">10 Dec 2020</div>
                  <div className="text-wrapper-13">10.30 PM IST</div>
                </div>
              </div>
              <div className="speakers-3">
                <div className="div-5">Speakers:</div>
                <div className="frame-24">
                  <div className="chip-speaker">
                    <div className="frame-30" />
                    <div className="name">Cameron</div>
                  </div>
                  <div className="chip-speaker-2">
                    <div className="frame-28" />
                    <div className="name">Sam</div>
                  </div>
                  <div className="name-wrapper">
                    <div className="name-2">+2 more</div>
                  </div>
                </div>
              </div>
            </>
          )}

          {["file", "folder"].includes(state.type) && (
            <div className="frame-31">
              <div className="frame-9">
                <div className="frame-10">
                  <div className="frame-9">
                    <div className="frame-wrapper">
                      <div className="frame-32">
                        <div className="div-4">
                          {state.type === "file" && <>File Name</>}

                          {state.type === "folder" && <>{text}</>}
                        </div>
                        {state.type === "file" && (
                          <div className="frame-12">
                            <div className="text-wrapper-14">UNREAD</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <StateDefault className="star-26" />
                  </div>
                  <div className="frame-24">
                    <div className="frame-33">
                      <div className="subheading">4.3</div>
                    </div>
                    <div className="ellipse-9" />
                    <div className="supporting-text">a week ago</div>
                  </div>
                </div>
              </div>
              <img className="img-6" alt="More horiz" src="/img/more-horiz-31.png" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "default" && state.type === "folder") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "folder",
        };
    }
  }

  if (state.state === "hover" && state.type === "folder") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "default",
          type: "folder",
        };
    }
  }

  if (state.state === "default" && state.type === "file") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "file",
        };
    }
  }

  if (state.state === "hover" && state.type === "file") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "default",
          type: "file",
        };
    }
  }

  if (state.state === "default" && state.type === "event") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "event",
        };
    }
  }

  if (state.state === "hover" && state.type === "event") {
    switch (action) {
      case "mouse_leave":
        return {
          state: "default",
          type: "course",
        };

      case "click":
        return {
          state: "hover",
          type: "file",
        };
    }
  }

  if (state.state === "default" && state.type === "event-02") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "event",
        };
    }
  }

  if (state.state === "default" && state.type === "course") {
    switch (action) {
      case "mouse_enter":
        return {
          state: "hover",
          type: "event",
        };
    }
  }

  return state;
}

CardDesign.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "default"]),
  type: PropTypes.oneOf(["file", "folder", "course", "event-02", "event"]),
  moreHoriz: PropTypes.string,
  text: PropTypes.string,
  hasFrame: PropTypes.bool,
  vector: PropTypes.string,
  group: PropTypes.string,
  frame: PropTypes.string,
};
