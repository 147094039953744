/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Icon1 } from "../../icons/Icon1";
import "./style.css";

export const ButtonVariant10 = ({ className }) => {
  return (
    <div className={`button-variant10 ${className}`}>
      <Icon1 className="icon-1" color="white" />
    </div>
  );
};
