/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1Default = ({ className }) => {
  return (
    <svg
      className={`property-1-default ${className}`}
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_57_1450)">
        <path
          className="path"
          d="M8.87992 39.8473C11.2709 29.0361 12.4663 23.6305 16.171 20.2234C16.6974 19.7392 17.2556 19.2909 17.842 18.8815C21.9688 16 27.505 16 38.5774 16H41.8949C52.6017 16 57.9551 16 61.9931 18.7012C62.773 19.2229 63.5055 19.8122 64.1821 20.4623C67.6854 23.8282 68.8318 29.0574 71.1246 39.5158L71.331 40.4572C74.9317 56.8814 76.7321 65.0936 73.2872 71.0723C72.367 72.6693 71.2048 74.1139 69.8419 75.3547C64.7396 80 56.3324 80 39.5181 80C23.1075 80 14.9022 80 9.92852 75.465C8.60012 74.2537 7.46779 72.8436 6.57196 71.285C3.21792 65.4494 4.98974 57.4377 8.53339 41.4143L8.87992 39.8473Z"
          fill="#202020"
        />
        <path
          className="path"
          d="M15.7793 39.9003C17.2511 32.8813 17.987 29.3718 20.1517 26.9686C20.986 26.0424 21.9595 25.2519 23.0371 24.6255C25.8334 23 29.4192 23 36.5909 23H43.7785C50.6705 23 54.1165 23 56.8307 24.5044C58.071 25.1919 59.1797 26.0938 60.1052 27.1683C62.1306 29.5195 62.8319 32.8934 64.2345 39.6412L65.4088 45.2908C67.3505 54.6317 68.3213 59.3022 67.4861 63.0497C66.2654 68.5269 62.5372 73.1098 57.423 75.4196C53.9239 77 49.1536 77 39.613 77C30.3023 77 25.647 77 22.2347 75.4571C17.2476 73.2021 13.6149 68.7287 12.4313 63.3849C11.6216 59.7287 12.577 55.1724 14.4877 46.0599L15.7793 39.9003Z"
          fill="#5E5E5E"
        />
        <path
          className="path"
          d="M18.4518 48.111C19.0873 46.3545 19.4051 45.4762 19.8579 44.7534C21.0039 42.9238 22.8406 41.635 24.9509 41.1798C25.7847 41 26.7187 41 28.5867 41L52.744 41C54.7941 41 55.8191 41 56.7235 41.213C58.8445 41.7124 60.67 43.0554 61.7781 44.9316C62.2507 45.7316 62.5558 46.7102 63.166 48.6673C65.287 55.4697 66.3474 58.8709 66.2393 61.6273C65.9828 68.1638 61.7732 73.8858 55.6094 76.0763C53.0101 77 49.4474 77 42.322 77H38.7215C31.6505 77 28.115 77 25.574 76.1212C19.049 73.8645 14.7074 67.6769 14.8053 60.7733C14.8434 58.0849 16.0462 54.7603 18.4518 48.111Z"
          fill="#AFAFAF"
        />
        <path className="path" d="M40 45L40 49" stroke="white" strokeLinecap="round" strokeWidth="2" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_57_1450">
          <rect className="rect" fill="white" height="80" width="80" />
        </clipPath>
      </defs>
    </svg>
  );
};
