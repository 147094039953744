/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ThirtyTwoGeneralPencil30 = ({ color = "white", className }) => {
  return (
    <svg
      className={`thirty-two-general-pencil-30 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 15 14"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M9.80904 6.04841L9.03641 5.27578L4.31171 10.0005L3.9403 11.1445L5.08433 10.7731L9.80904 6.04841ZM5.67137 11.7313L2.23926 12.8456L3.35349 9.41345L9.03641 3.73053L11.3543 6.04841L5.67137 11.7313Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M12.1269 2.18527C11.7002 1.75856 11.0084 1.75856 10.5817 2.18527L9.80906 2.9579L12.1269 5.27578L12.8996 4.50315C13.3263 4.07644 13.3263 3.38461 12.8996 2.9579L12.1269 2.18527Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

ThirtyTwoGeneralPencil30.propTypes = {
  color: PropTypes.string,
};
